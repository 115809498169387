import { Box, Divider, Grid, Skeleton } from "@mui/material";
import React, { VFC } from "react";
import { CustomTable } from "../Table";

export const FallbackComponent: VFC = () => (
  <Grid container flex='column' height='100vh' width='100vw'>
    <Grid item>
      <Box width='250px' height='100%' padding='8px' bgcolor='white'>
        <Box>
          <Skeleton
            width='calc(100% - 40px)'
            height='38px'
            sx={{
              margin: "auto",
              marginTop: "12px",
              marginBottom: "12px",
            }}
            variant='rectangular'
          />
        </Box>
        <Divider
          variant='fullWidth'
          sx={{
            marginBottom: "8px",
            marginTop: "8px",
          }}
        />
        {new Array(8).fill(0).map((_, index) => (
          <Box key={index} display='flex'>
            <Skeleton width='40px' height='40px' sx={{ margin: "8px" }} variant='circular' />
            <Skeleton width='160px' height='40px' sx={{ marginTop: "8px", marginBottom: "8px" }} variant='text' />
          </Box>
        ))}
      </Box>
    </Grid>
    <Grid container flexDirection='column' item xs>
      <Grid item>
        <Box bgcolor='white' height='70px' padding='16px' display='flex'>
          <Skeleton width='100px' />
          <Box flex='1'></Box>
          <Box display='flex'>
            <Skeleton
              height='40px'
              width='40px'
              variant='circular'
              sx={{
                marginInline: "8px",
              }}
            />
            <Skeleton
              height='40px'
              width='40px'
              variant='circular'
              sx={{
                marginInline: "8px",
              }}
            />
            <Skeleton
              height='40px'
              width='40px'
              variant='circular'
              sx={{
                marginInline: "8px",
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs>
        <Box height='100%' width='100%' padding='40px'>
          <CustomTable
            columns={[
              { accessor: "", header: "", key: "a" },
              { accessor: "", header: "", key: "b" },
              { accessor: "", header: "", key: "c" },
              { accessor: "", header: "", key: "d" },
            ]}
            data={[]}
            isLoading={true}
            // eslint-disable-next-line react/jsx-handler-names
            onSortColumn={() => {
              void 0;
            }}
            pageSize={0}
            pageIndex={0}
            pagesCount={1}
            defaultColumns={[]}
          />
        </Box>
      </Grid>
    </Grid>
  </Grid>
);
