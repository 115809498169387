import { IntegrationGraphqlError, PharmacyCredentials } from "@health/queries/types";
import { combineErrors, formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, OptionalLabel, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export const WasfatyCredentialForm: FC<{ errors: IntegrationGraphqlError[]; defaultValues: PharmacyCredentials }> = ({
  errors,
  defaultValues,
}) => {
  const { t } = useTranslation("admin");
  const {
    register,
    formState: { errors: formErrors },
  } = useFormContext();
  const combinedErrors = combineErrors(formErrors, errors);
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          placeholder={t("User Name")}
          inputProps={{
            autoComplete: "off",
          }}
          label={t("User Name")}
          {...register("userName", {
            required: getRequiredValidation(t, true),
          })}
          defaultValue={defaultValues?.userName}
          error={Boolean(combinedErrors?.name?.message)}
          helperText={t(combinedErrors?.name?.message)}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          placeholder={t("Password")}
          label={t("Password")}
          type='password'
          inputProps={{
            autoComplete: "new-password",
          }}
          {...register("password", {
            required: getRequiredValidation(t, true),
          })}
          error={Boolean(combinedErrors?.password?.message)}
          helperText={t(combinedErrors?.password?.message)}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField fullWidth placeholder={t("Token")} label={<OptionalLabel label={t("Token")} />} {...register("token", {})} />
      </Grid>
    </Grid>
  );
};
