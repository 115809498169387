import { H_EntityError, InsurancePolicyUpdateInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  insurancePoliciesFieldBreadcrumb,
  insurancePoliciesListBreadcrumb,
  insurancePoliciesTitleBreadcrumb,
} from "pages/InsurancePolicies/constants/insurance-policies-breadcrumbs";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InsurancePolicyUpdateMutation, useInsurancePolicyQuery, useInsurancePolicyUpdateMutation } from "../gql";

export const useInsurancePoliciesEditPage = () => {
  const params = useParams();
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { failed, succeeded } = useAddToast();
  const { setBreadCrumb } = useBreadCrumbs();

  const [errors, setErrors] = React.useState<H_EntityError[]>([]);

  const [insurancePolicyUpdateMutation, { loading: isSubmitting }] = useInsurancePolicyUpdateMutation({
    onCompleted: (updatedInsurancePolicy: InsurancePolicyUpdateMutation) => {
      const mutationErrors = updatedInsurancePolicy?.insurancePolicyUpdate?.entityErrors;
      if (mutationErrors && mutationErrors.length > 0) {
        setErrors(mutationErrors);
        failed(t(formatMessageErrors(mutationErrors)));
      } else {
        navigate(-1);
        succeeded(t("Insurance Policy updated successfully"));
      }
    },
  });
  const { data, loading: isLoading } = useInsurancePolicyQuery({
    variables: {
      insurancePolicyId: params?.id as string,
    },
    onError: () => {
      failed(t("Insurance Policy not found!"));
    },
    fetchPolicy: "network-only",
  });
  const insurancePolicy = data?.insurancePolicy;
  const handleSubmit = (requestInput: InsurancePolicyUpdateInput) => {
    const outpatientPolicyValues = Object.values(requestInput?.insuranceOutpatientPolicyInput ?? {});
    const insuranceOutpatientPolicyInput = outpatientPolicyValues.every(value => !value)
      ? undefined
      : requestInput?.insuranceOutpatientPolicyInput;

    insurancePolicyUpdateMutation({
      variables: {
        insurancePolicyUpdateId: params?.id as string,
        input: {
          policyName: requestInput.policyName,
          insuranceNetworkId: requestInput.insuranceNetworkId,
          isActive: requestInput.isActive,
          insuranceOutpatientPolicyInput,
        },
      },
    });
  };

  useEffect(() => {
    if (data?.insurancePolicy?.name) {
      setBreadCrumb({
        title: insurancePoliciesTitleBreadcrumb(t),
        values: [insurancePoliciesListBreadcrumb(t), insurancePoliciesFieldBreadcrumb(data.insurancePolicy.name)],
      });
    }
  }, [data?.insurancePolicy?.name]);

  return {
    errors,
    handleSubmit,
    isLoading,
    insurancePolicy,
    isSubmitting,
  };
};
