import { CustomDialog, Grid, ShowButton, TextField } from "@toolkit/ui";
import React, { FC } from "react";
import { OwnerInfoProps } from "./VendorList.types";
import { useVendorsListHook } from "./VendorsComponentsList.hook";

export const OwnerInfo: FC<OwnerInfoProps> = props => {
  const { t, isOpen, currentDir, handleOpen, handleClose } = useVendorsListHook();
  const { nationalId, ownerName } = props;

  return (
    <React.Fragment>
      <ShowButton onClick={handleOpen} />
      {isOpen && (
        <CustomDialog open={isOpen} onCloseModal={handleClose} title={t("Owner Info")}>
          <Grid container spacing={2}>
            {ownerName && (
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  dir={currentDir}
                  placeholder={t("Owner Name")}
                  value={t(ownerName) + " "}
                  label={t("Owner Name")}
                />
              </Grid>
            )}
            {nationalId && (
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  dir={currentDir}
                  placeholder='nationalId'
                  value={t(nationalId) + " "}
                  label={t("National Id")}
                />
              </Grid>
            )}
          </Grid>
        </CustomDialog>
      )}
    </React.Fragment>
  );
};
