export var Enum_Contentreleasesreleaseaction_Type;
(function (Enum_Contentreleasesreleaseaction_Type) {
    Enum_Contentreleasesreleaseaction_Type["Publish"] = "publish";
    Enum_Contentreleasesreleaseaction_Type["Unpublish"] = "unpublish";
})(Enum_Contentreleasesreleaseaction_Type || (Enum_Contentreleasesreleaseaction_Type = {}));
export var Enum_Contentreleasesrelease_Status;
(function (Enum_Contentreleasesrelease_Status) {
    Enum_Contentreleasesrelease_Status["Blocked"] = "blocked";
    Enum_Contentreleasesrelease_Status["Done"] = "done";
    Enum_Contentreleasesrelease_Status["Empty"] = "empty";
    Enum_Contentreleasesrelease_Status["Failed"] = "failed";
    Enum_Contentreleasesrelease_Status["Ready"] = "ready";
})(Enum_Contentreleasesrelease_Status || (Enum_Contentreleasesrelease_Status = {}));
export var PublicationState;
(function (PublicationState) {
    PublicationState["Live"] = "LIVE";
    PublicationState["Preview"] = "PREVIEW";
})(PublicationState || (PublicationState = {}));
