import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const ArrowButtonRight: FC<SvgIconProps> = props => {
  const { palette } = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='20' height='5.47' viewBox='0 3 21 5.47' {...props}>
      <path
        id='icons8-right'
        d='M20.683,13.092a.578.578,0,0,0-.454.409.584.584,0,0,0,.164.591l5.343,5.343H8.507A.582.582,0,1,0,8.561,20.6H25.735l-5.343,5.343a.582.582,0,1,0,.818.818l6.342-6.324.4-.418-.4-.418L21.21,13.274a.584.584,0,0,0-.472-.182Z'
        transform='translate(-7.952 -13.091)'
        fill={palette.common.white}
      />
    </SvgIcon>
  );
};
export default ArrowButtonRight;
