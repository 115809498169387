import { Create as CreateIcon, DeleteForever as DeleteForeverIcon } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { DeleteConfirmationDialog } from "../../Dialogs/DeleteConfirmationDialog";
import { useTableBodyLayoutStyles } from "../styles/useTableBodyLayoutStyles";
import { CustomTableColumnProps } from "../types";
import { TableBodyLoader } from "./TableLoader";
import { TableBodyLayoutProps } from "./types";

export const TableBodyLayout = <RowType extends object>({
  isLoading,
  data,
  isEditVisible,
  isRowDeletable = () => true,
  isRowEditable = () => true,
  pageSize = 10,
  columnsToRender,
  onRowClick,
  extraActionsRenderer,
  onEditRow,
  onDeleteRow,
  isDeleteVisible,
  isCardView,
}: TableBodyLayoutProps<RowType>) => {
  const { classes, theme } = useTableBodyLayoutStyles();
  const [rowToBeDeleted, setRowToBeDeleted] = useState<null | RowType>(null);
  const [rowIndexToBeDeleted, setRowIndexToBeDeleted] = useState<null | number>(null);

  const renderCell = (row: RowType, column: CustomTableColumnProps<RowType>, index: number) => {
    return (
      <Typography
        component='span'
        fontFamily={theme.mixins.fonts.fontFamily.regular}
        fontSize={theme.mixins.fonts.fontSize.sm}
        width={"max-content"}
      >
        {typeof column.accessor === "function" ? (column.accessor?.(row, index) as any) : row[column.accessor as keyof RowType]}
      </Typography>
    );
  };

  const handleOpenDeleteDialog = (row?: RowType | null, rowIndex?: number) => () => {
    setRowToBeDeleted(row ? row : null);
    setRowIndexToBeDeleted(rowIndex!);
  };

  const handleClose = () => {
    setRowToBeDeleted(null);
  };
  const handleDeleteConfirmation = () => {
    onDeleteRow(rowToBeDeleted!, rowIndexToBeDeleted!);
    setRowToBeDeleted(null);
  };
  const handleRowClick = (row: RowType) => {
    onRowClick?.(row);
  };

  const getBooleanValueFn = (predicate: boolean | ((row: RowType) => boolean)) => {
    return typeof predicate === "function" ? predicate : () => Boolean(predicate);
  };

  return (
    <>
      {data && !isLoading ? (
        data?.map((row, rowIndex) => {
          const _isRowDeletable = getBooleanValueFn(isRowDeletable)(row);
          const _isRowEditable = getBooleanValueFn(isRowEditable)(row);
          return (
            <TableRow
              key={`${rowIndex}`}
              hover
              classes={{
                hover: classes.tableRowHover,
              }}
              onClick={handleRowClick.bind(null, row)}
              className={isCardView ? `${classes.tableRow} ${rowIndex % 2 === 0 && classes.row}` : classes.tableRow}
              sx={{
                position: "relative",
              }}
            >
              {columnsToRender?.map((column, index) => (
                <TableCell className={classes.cell} key={index} sx={column.sx}>
                  {renderCell(row, column, rowIndex)}
                </TableCell>
              ))}
              {columnsToRender.length !== 0 && (isDeleteVisible || isEditVisible || extraActionsRenderer) && (
                <TableCell className={`${classes.cell} ${classes.actionsCell}`}>
                  {isEditVisible && (
                    <IconButton
                      // eslint-disable-next-line react/jsx-handler-names
                      onClick={event => onEditRow(event, row)}
                      disabled={!_isRowEditable}
                      sx={{
                        fontFamily: theme.mixins.fonts.fontFamily.regular,
                        fontSize: theme.mixins.fonts.fontSize.lg,
                        height: 30,
                        width: 30,
                      }}
                    >
                      <CreateIcon fontSize='inherit' />
                    </IconButton>
                  )}
                  {isDeleteVisible && (
                    <IconButton
                      color='error'
                      onClick={handleOpenDeleteDialog(row, rowIndex)}
                      disabled={!_isRowDeletable}
                      sx={{
                        fontFamily: theme.mixins.fonts.fontFamily.regular,
                        fontSize: theme.mixins.fonts.fontSize.lg,
                        height: 30,
                        width: 30,
                      }}
                    >
                      <DeleteForeverIcon fontSize='inherit' className={classes.delete} />
                    </IconButton>
                  )}
                  {extraActionsRenderer && extraActionsRenderer(row)}
                </TableCell>
              )}
            </TableRow>
          );
        })
      ) : (
        <TableBodyLoader numberOfColumns={columnsToRender.length} numberOfRows={pageSize!} />
      )}

      {rowToBeDeleted && (
        <DeleteConfirmationDialog rowToBeDeleted={rowToBeDeleted} onClose={handleClose} onDeleteRow={handleDeleteConfirmation} />
      )}
    </>
  );
};
