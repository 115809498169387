import { QuestionType } from "@health/queries/types";
import {
  AlignLeftIcon,
  CheckboxIcon,
  CheckBoxOutlineBlankRounded,
  ExpandCircleDownOutlined,
  ExpandCircleDownRounded,
  ThumbsUpDown,
} from "@toolkit/ui";
import React, { FC } from "react";

export const OptionItemIcon: FC<{ type: QuestionType; isChecked: boolean; color?: string }> = ({ type, isChecked, color }) => {
  switch (type) {
    case QuestionType.Boolean:
      return <ThumbsUpDown sx={{ color: color }} />;
    case QuestionType.MultiChoice:
      return isChecked ? <CheckboxIcon fill={color} /> : <CheckBoxOutlineBlankRounded sx={{ color: color }} />;
    case QuestionType.SingleChoice:
      return isChecked ? <ExpandCircleDownRounded sx={{ color: color }} /> : <ExpandCircleDownOutlined sx={{ color: color }} />;
    case QuestionType.String:
      return <AlignLeftIcon fill={color} />;
    default:
      return null;
  }
};
