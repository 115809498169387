import styled from "@emotion/styled";
import { Theme, Typography } from "@mui/material";

export const TypographyRegular = styled(Typography)((theme: Theme) => ({
  fontFamily: theme.mixins.fonts.fontFamily.regular,
  fontSize: theme.mixins.fonts.fontSize.md,
}));

export const TypographyMedium = styled(Typography)((theme: Theme) => ({
  fontFamily: theme.mixins.fonts.fontFamily.medium,
  fontSize: theme.mixins.fonts.fontSize.md,
}));

export const TypographyBold = styled(Typography)((theme: Theme) => ({
  fontFamily: theme.mixins.fonts.fontFamily.bold,
  fontSize: theme.mixins.fonts.fontSize.md,
}));
