import { DiscountValueTypeEnum } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const discountValueTypeOptionsMap = {
    [DiscountValueTypeEnum.Fixed]: {
        key: DiscountValueTypeEnum.Fixed,
        get label() {
            return i18n.t("Fixed", { ns: "domains" });
        },
        value: DiscountValueTypeEnum.Fixed,
    },
    [DiscountValueTypeEnum.Percentage]: {
        key: DiscountValueTypeEnum.Percentage,
        get label() {
            return i18n.t("Percentage", { ns: "domains" });
        },
        value: DiscountValueTypeEnum.Percentage,
    },
};
export const discountValueTypeOptions = Object.values(discountValueTypeOptionsMap);
