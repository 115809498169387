import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Type } from "@health/queries/types";
import { getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Autocomplete, CustomDatePicker, ExpandMoreIcon, TextField, Typography } from "@toolkit/ui";
import { useFormContext } from "react-hook-form";
import { getFieldType } from "../AdminManagedListDetails/utils";
import { FieldUIComponent } from "../types";
const FieldInput = ({ fieldDefenition }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { register, getValues, formState, control } = useFormContext();
    const { t } = useTranslation("domains");
    const rendererType = getFieldType(fieldDefenition);
    const { code, display, isMandatory, fieldType } = fieldDefenition;
    const defaultValue = getValues(code) || undefined;
    const isBooleanField = fieldType === Type.Boolean;
    const isFloatField = fieldType === Type.Float;
    switch (rendererType) {
        case FieldUIComponent.autocomplete:
            return (_jsx(Autocomplete, { defaultValue: defaultValue, popupIcon: _jsx(ExpandMoreIcon, {}), disableClearable: !isBooleanField, freeSolo: !isBooleanField, options: isBooleanField ? ["Yes", "No"] : [], renderInput: params => {
                    var _a, _b, _c, _d;
                    return (_jsx(TextField, Object.assign({ variant: 'outlined', defaultValue: defaultValue, hiddenLabel: true, placeholder: display || "" }, params, register(code, {
                        required: getRequiredValidation(t, !!isMandatory),
                    }), { error: !!((_b = (_a = formState.errors) === null || _a === void 0 ? void 0 : _a[code]) === null || _b === void 0 ? void 0 : _b.message), helperText: ((_d = (_c = formState.errors) === null || _c === void 0 ? void 0 : _c[code]) === null || _d === void 0 ? void 0 : _d.message) || " " })));
                } }));
        case FieldUIComponent.date:
            return (_jsx(CustomDatePicker, Object.assign({ defaultValue: defaultValue, required: !!isMandatory, label: display || "", placeholder: display || "", control: control }, register(code, {
                required: getRequiredValidation(t, !!isMandatory),
                valueAsDate: true,
            }), { error: !!((_b = (_a = formState.errors) === null || _a === void 0 ? void 0 : _a[code]) === null || _b === void 0 ? void 0 : _b.message), helperText: ((_d = (_c = formState.errors) === null || _c === void 0 ? void 0 : _c[code]) === null || _d === void 0 ? void 0 : _d.message) || " " })));
        case FieldUIComponent.input:
            return (_jsx(TextField, Object.assign({ defaultValue: defaultValue, required: !!isMandatory, label: display || "", placeholder: display || "", inputProps: {
                    step: isFloatField ? "0.01" : undefined,
                }, fullWidth: true }, register(code, {
                required: getRequiredValidation(t, !!isMandatory),
            }), { error: !!((_f = (_e = formState.errors) === null || _e === void 0 ? void 0 : _e[code]) === null || _f === void 0 ? void 0 : _f.message), helperText: ((_h = (_g = formState.errors) === null || _g === void 0 ? void 0 : _g[code]) === null || _h === void 0 ? void 0 : _h.message) || " " })));
        default:
            return _jsxs(Typography, { children: [" ", t("Component type not defined")] });
    }
};
export default FieldInput;
