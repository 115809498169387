import { makeStyles } from "tss-react/mui";

export const styles = makeStyles()(theme => ({
  root: {
    display: "flex",
  },
  container: {
    borderRadius: 10,
    border: "1px solid",
    borderColor: theme.palette.stale.main,
    width: 348,
    height: 210,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    opacity: 1,
    borderStyle: "dashed",
  },
  containerImg: {
    borderRadius: 10,
    width: 348,
    height: 210,
    margin: 10,
    marginTop: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxShadow: "inset 365px -40px 11px -15px rgba(0,0,0,0.5)",
  },
  img: {
    borderRadius: 10,
    width: "100%",
    height: "100%",
    opacity: "50%",
  },
  errorsContainer: {
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    width: 348,
    height: 210,
    borderRadius: 10,
    color: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    opacity: 1,
    borderStyle: "dashed",
  },
  error: {
    border: "1px solid",
    borderColor: theme.palette.error.main,
  },
  btn: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    marginTop: 5,
    "&:hover": {
      backgroundColor: theme.palette.success.light,
    },
    height: 30,
  },
  imageIcon: {
    color: theme.palette.gray[500],
    fontSize: theme.mixins.fonts.fontSize.xs,
    margin: "auto",
    marginTop: "12px",
    marginBottom: "15px",
  },
  span: {
    fontWeight: "bold",
    color: theme.palette.common.black,
  },
  imageIconError: {
    margin: 10,
    color: theme.palette.error.main,
    position: "absolute",
  },
  addIcon: {
    color: theme.palette.common.white,
    fontSize: theme.mixins.fonts.fontSize.xxl,
  },
  icons: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    color: theme.palette.common.white,
    "& .MuiSvgIcon-root": {
      width: "100%",
      height: "100%",
      justifyContent: "center",
    },
  },
  label: {
    display: "flex",
    flexDirection: "column",
  },
  button: {},
  zoomIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    "&.MuiIconButton-root": {
      width: 35,
      height: 35,
    },
  },
  content: {
    padding: 0,
    position: "relative",
    height: "400px",
    overflowY: "hidden",
  },
  input: {
    display: "none",
  },
  images: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
}));
