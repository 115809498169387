import React, { FC, PropsWithChildren } from "react";
import { AppThemeProvider } from "../customization";
import { AppToastProvider } from "./AppToastProvider";

export const ToolkitUiProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <AppThemeProvider>
      <AppToastProvider>{children}</AppToastProvider>
    </AppThemeProvider>
  );
};
