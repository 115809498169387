import { CustomDialog, Grid, ShowButton, TextField } from "@toolkit/ui";
import React, { FC } from "react";
import { BankingInfoProps } from "./VendorList.types";
import { useVendorsListHook } from "./VendorsComponentsList.hook";

export const BankingInfo: FC<BankingInfoProps> = props => {
  const { iban, accountNumber, bankName, accountName } = props;
  const { t, isOpen, currentDir, handleOpen, handleClose } = useVendorsListHook();

  return (
    <React.Fragment>
      <ShowButton onClick={handleOpen} />
      {isOpen && (
        <CustomDialog open={isOpen} onCloseModal={handleClose} title={t("Banking Information")}>
          <Grid container spacing={2}>
            {bankName && (
              <Grid item xs={6}>
                <TextField disabled dir={currentDir} fullWidth placeholder={t("Bank Name")} value={t(bankName)} label={t("Bank Name")} />
              </Grid>
            )}
            {accountName && (
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  dir={currentDir}
                  placeholder={t("Account Name")}
                  value={t(accountName)}
                  label={t("Account Name")}
                />
              </Grid>
            )}
            {accountNumber && (
              <Grid item xs={6}>
                <TextField
                  disabled
                  fullWidth
                  dir={currentDir}
                  placeholder={t("Account Number")}
                  value={t(accountNumber)}
                  label={t("Account Number")}
                />
              </Grid>
            )}
            {iban && (
              <Grid item xs={6}>
                <TextField disabled fullWidth dir={currentDir} placeholder={t("IBAN")} value={t(iban)} label={t("IBAN")} />
              </Grid>
            )}
          </Grid>
        </CustomDialog>
      )}
    </React.Fragment>
  );
};
