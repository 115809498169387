import { makeStyles } from "tss-react/mui";

export const useTabsStyles = makeStyles<{ isRoot: boolean }>()((theme, { isRoot }) => ({
  root: {
    width: "auto",
    display: "flex",
    color: theme.palette.primary.main,
  },
  selected: {
    borderRadius: 0,
    backgroundColor: theme.palette.gray.light,
    fontWeight: "bold",
  },
  tabs: {
    borderBottom: isRoot ? `1px solid ${theme.palette.gray.light}` : "none",
    borderTop: !isRoot ? `1px solid ${theme.palette.gray.light}` : "none",
  },
  panelRoot: { padding: 0 },
  defaultPanelRoot: { padding: 0, backgroundColor: theme.palette.gray.light },
  display: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.gray.light,
    alignItems: "center",
  },
  container: {
    ["@media (min-width:1200px)"]: {
      maxWidth: "inherit",
    },
  },
  defaultRoot: {
    minWidth: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));
