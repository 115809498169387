import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { getBranchesAutocompleteFilter } from "@health/autocompletes";
import { orderCheckoutTypeOptions, orderDeliveryStatusOptions, orderStatusActiveOptions } from "@health/enum-options";
import { OrderDirection, PrescriptionSortField } from "@health/queries/types";
import { capitalize } from "@toolkit/core";
import { i18n, useTranslation } from "@toolkit/i18n";
import { OrderStatusTypography, TruncateTypography } from "@toolkit/ui";
import moment from "moment-timezone";
import "moment/locale/ar";
import { getOrderStatusColor } from "../../../OrdersTracking/components/utils";
import { useMemo } from "react";
import { ProductsModal } from "../components/ProductsModal";
import { LifeCycleModel } from "../../../OrdersTracking/components/LifeCycle.components";
export const useMarketplaceOrdersTrackingColumns = ({ isAdmin }) => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "number",
                header: t("Order Number"),
                accessor: ({ number }) => number,
                filter: {
                    type: "string",
                    name: "numbers",
                },
            },
            {
                key: "branch",
                header: t("Branch"),
                accessor: ({ branch }) => _jsx(TruncateTypography, { text: (branch === null || branch === void 0 ? void 0 : branch.name) || "-" }),
            },
            {
                key: "vendor",
                header: t("Vendor"),
                accessor: ({ branch }) => { var _a; return _jsx(TruncateTypography, { text: ((_a = branch === null || branch === void 0 ? void 0 : branch.vendor) === null || _a === void 0 ? void 0 : _a.name) || "-" }); },
                hideFromPreferencesAndTable: !isAdmin,
            },
            {
                key: "type",
                header: t("Order Type"),
                accessor: ({ type }) => ((type === null || type === void 0 ? void 0 : type.includes("_")) ? capitalize(type.toLowerCase().split("_")) : capitalize([String(type)])),
            },
            {
                key: "patient-id",
                header: t("Customer ID"),
                accessor: ({ user }) => user === null || user === void 0 ? void 0 : user.nationalId,
            },
            {
                key: "customer-name",
                header: t("Customer Name"),
                accessor: ({ user }) => user === null || user === void 0 ? void 0 : user.fullName,
            },
            {
                key: "contactEmail",
                header: t("Customer Email"),
                accessor: ({ user }) => { var _a; return _jsx("span", Object.assign({ className: 'mobile-phone-rtl-fix' }, { children: String((_a = user === null || user === void 0 ? void 0 : user.email) !== null && _a !== void 0 ? _a : "") })); },
            },
            {
                key: "contactNumber",
                header: t("Customer Mobile"),
                accessor: ({ user }) => { var _a; return _jsx("span", Object.assign({ className: 'mobile-phone-rtl-fix' }, { children: String((_a = user === null || user === void 0 ? void 0 : user.mobile) !== null && _a !== void 0 ? _a : "") })); },
            },
            {
                header: t("Payment Price"),
                key: "totalAmount",
                accessor: ({ total }) => (total === null || total === void 0 ? void 0 : total.gross.amount) + " " + (total === null || total === void 0 ? void 0 : total.gross.currency),
            },
            {
                key: "Order Status",
                header: t("Order Status"),
                accessor: ({ status }) => _jsx(OrderStatusTypography, { status: status, color: getOrderStatusColor(status) }),
                filter: {
                    name: "status",
                    type: "autocomplete",
                    multiple: true,
                    getOptionLabel: (option) => option === null || option === void 0 ? void 0 : option.label,
                    getValueForBackend: options => options === null || options === void 0 ? void 0 : options.map(item => item === null || item === void 0 ? void 0 : item.value),
                    options: orderStatusActiveOptions,
                },
            },
            {
                key: "CheckoutType",
                header: t("Checkout Type"),
                showOnlyForFilterField: true,
                filter: {
                    name: "checkoutType",
                    type: "autocomplete",
                    getOptionLabel: (option) => option === null || option === void 0 ? void 0 : option.label,
                    getValueForBackend: option => option === null || option === void 0 ? void 0 : option.value,
                    options: orderCheckoutTypeOptions,
                },
            },
            {
                key: "products",
                header: t("Products"),
                accessor: ({ lines }) => _jsx(ProductsModal, { lines: lines, title: t("Products") }),
            },
            {
                key: "deliveryStatus",
                header: t("Delivery Status"),
                accessor: ({ deliveryStatus }) => deliveryStatus,
                filter: {
                    name: "deliveryStatus",
                    type: "autocomplete",
                    multiple: true,
                    getOptionLabel: (option) => option === null || option === void 0 ? void 0 : option.label,
                    getValueForBackend: options => options === null || options === void 0 ? void 0 : options.map(item => item === null || item === void 0 ? void 0 : item.value),
                    options: orderDeliveryStatusOptions,
                },
            },
            {
                key: "Created",
                header: t("Created"),
                accessor: ({ created }) => {
                    moment.localeData("ar");
                    const createdTz = moment.tz.guess();
                    moment.locale(i18n.language);
                    return moment(created).tz(createdTz).format("DD MMM YYYY hh:mm a");
                },
                isSortable: true,
                sortDirection: OrderDirection === null || OrderDirection === void 0 ? void 0 : OrderDirection.Asc,
                sortColumnEnum: PrescriptionSortField.Created,
                filter: {
                    type: "date",
                    name: "created.gte",
                },
            },
            {
                key: "auditHistory",
                header: t("Audit History"),
                accessor: ({ number, prescription }) => (_jsx(LifeCycleModel, { number: number, prescriptionID: prescription === null || prescription === void 0 ? void 0 : prescription.id, referenceNumber: prescription === null || prescription === void 0 ? void 0 : prescription.referenceNumber, dispenseStatus: prescription === null || prescription === void 0 ? void 0 : prescription.dispenseStatus })),
            },
            {
                key: "createdTo",
                header: t("Created Date To"),
                showOnlyForFilterField: true,
                filter: {
                    type: "date",
                    name: "created.lte",
                },
            },
            {
                key: "helpDiskTicketId_Icontains",
                header: t("Help Disk Ticket Id"),
                showOnlyForFilterField: true,
                filter: {
                    type: "string",
                    name: "helpDiskTicketId_Icontains",
                },
            },
            {
                key: "hasHelpDiskTicket",
                header: t("Has help disk ticket Id"),
                showOnlyForFilterField: true,
                filter: {
                    type: "autocomplete",
                    name: "hasHelpDiskTicket",
                    options: [
                        { name: "Has help disk ticket Id", value: true },
                        { name: "No Help Disk ticket Id", value: false },
                    ],
                    getOptionLabel: (option) => option === null || option === void 0 ? void 0 : option.name,
                    getValueForBackend: option => option === null || option === void 0 ? void 0 : option.value,
                },
            },
            {
                key: "Branches",
                header: t("Branches"),
                showOnlyForFilterField: true,
                filter: getBranchesAutocompleteFilter({ name: "branches", multiple: true }),
            },
            {
                key: "patient-id-filter",
                header: t("Patient ID"),
                showOnlyForFilterField: true,
                filter: {
                    type: "string",
                    name: "nationalId",
                },
            },
        ];
    }, []);
};
