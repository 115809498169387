import { getAutocompleteFilter } from "@toolkit/ui";
import { useBranchesAutocompleteQuery } from "./gql";
export const getBranchesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "branches",
        query: useBranchesAutocompleteQuery,
        labelBy: "name",
        arabicLabelBy: "nameAr",
        backendAccessor: "id",
    });
};
