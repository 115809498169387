import { makeStyles } from "tss-react/mui";

export const useTableLayoutStyles = makeStyles({ name: "ui-table-body" })(theme => ({
  tableHead: {
    backgroundColor: theme.palette.stale[50],
  },
  table: {},
  tableBody: {
    minHeight: 250,
    position: "relative",
  },
  tableRow: {
    height: 56,
    backgroundColor: theme.palette.stale[50],
  },
  tableHeaderCell: {
    fontFamily: theme.mixins.fonts.fontFamily.medium,
    backgroundColor: theme.palette.stale[50],
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  tableHeadCell: {
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.primary.main,
    backgroundColor: theme.palette.stale[50],
    textAlign: "start",
    width: "fit-content",
    maxWidth: "250px",
    "&:hover": {
      "&::before": {
        background: theme.palette.stale[400],
        content: '""',
        position: "absolute",
        bottom: "50%",
        transform: "translate(0%, 50%)",
        display: "block",
        height: 20,
        left: 0,
        width: 1,
      },
      "&::after": {
        background: theme.palette.stale[400],
        content: '""',
        position: "absolute",
        bottom: "50%",
        transform: "translate(0%, 50%)",
        display: "block",
        height: 20,
        right: 0,
        width: 1,
      },
    },
  },
  tableSortLabel: {
    height: 15,
    "&:hover": {
      color: "inherit !important",
    },
  },
}));
