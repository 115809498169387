/* eslint-disable max-lines */
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid, TextField } from "@toolkit/ui";
import React from "react";
import { useFormContext } from "react-hook-form";
import { ISiteSetting } from "../components/types";
import { requiredPositiveIntegerPattern } from "../utils";
import { formGirdBreakPoints } from "./constants";

export const MarketplaceSettings: React.FC<ISiteSetting> = ({ loading, errors: formErrors, siteSettings }) => {
  const { register } = useFormContext();
  const { t } = useTranslation("admin");

  return (
    <Grid item xs={12}>
      <FormCard title={t("Marketplace Settings")} loading={loading} doYouHaveData>
        <Grid container spacing={formGirdSpacing}>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.marketplaceConsumedTimeoutAlert}
              label={t("Marketplace Consumed Timeout Alert")}
              {...register("marketplaceConsumedTimeoutAlert", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.marketplaceConsumedTimeoutAlert?.message)}
              helperText={formErrors?.marketplaceConsumedTimeoutAlert?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.marketplaceConsumedTimeoutSeconds}
              label={t("Marketplace Consumed Timeout Seconds")}
              {...register("marketplaceConsumedTimeoutSeconds", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.marketplaceConsumedTimeoutSeconds?.message)}
              helperText={formErrors?.marketplaceConsumedTimeoutSeconds?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.marketplaceDeliveredTimeoutAlert}
              label={t("Marketplace Delivered Timeout Alert")}
              {...register("marketplaceDeliveredTimeoutAlert", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.marketplaceDeliveredTimeoutAlert?.message)}
              helperText={formErrors?.marketplaceDeliveredTimeoutAlert?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.marketplaceDeliveredTimeoutSeconds}
              label={t("Marketplace Delivered Timeout Seconds")}
              {...register("marketplaceDeliveredTimeoutSeconds", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.marketplaceDeliveredTimeoutSeconds?.message)}
              helperText={formErrors?.marketplaceDeliveredTimeoutSeconds?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.marketplaceOutForDeliveryTimeoutAlert}
              label={t("Marketplace Out For Delivery Timeout Alert")}
              {...register("marketplaceOutForDeliveryTimeoutAlert", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.marketplaceOutForDeliveryTimeoutAlert?.message)}
              helperText={formErrors?.marketplaceOutForDeliveryTimeoutAlert?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.marketplaceOutForDeliveryTimeoutSeconds}
              label={t("Marketplace Out For Delivery Timeout Seconds")}
              {...register("marketplaceOutForDeliveryTimeoutSeconds", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.marketplaceOutForDeliveryTimeoutSeconds?.message)}
              helperText={formErrors?.marketplaceOutForDeliveryTimeoutSeconds?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.marketplaceReturnedTimeoutAlert}
              label={t("Marketplace Returned Timeout Alert")}
              {...register("marketplaceReturnedTimeoutAlert", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.marketplaceReturnedTimeoutAlert?.message)}
              helperText={formErrors?.marketplaceReturnedTimeoutAlert?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.marketplaceReturnedTimeoutSeconds}
              label={t("Marketplace Returned Timeout Seconds")}
              {...register("marketplaceReturnedTimeoutSeconds", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.marketplaceReturnedTimeoutSeconds?.message)}
              helperText={formErrors?.marketplaceReturnedTimeoutSeconds?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.marketplaceVendorResponseTimeoutAlert}
              label={t("Marketplace Vendor Response Timeout Alert")}
              {...register("marketplaceVendorResponseTimeoutAlert", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.marketplaceVendorResponseTimeoutAlert?.message)}
              helperText={formErrors?.marketplaceVendorResponseTimeoutAlert?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.marketplaceVendorResponseTimeoutSeconds}
              label={t("Marketplace Vendor Response Timeout Seconds")}
              {...register("marketplaceVendorResponseTimeoutSeconds", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.marketplaceVendorResponseTimeoutSeconds?.message)}
              helperText={formErrors?.marketplaceVendorResponseTimeoutSeconds?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              fullWidth
              defaultValue={siteSettings?.marketplaceOrderPaymentTimeoutSeconds}
              label={t("Marketplace Order Payment Timeout Seconds")}
              {...register("marketplaceOrderPaymentTimeoutSeconds", {
                ...requiredPositiveIntegerPattern(t),
              })}
              error={Boolean(formErrors?.marketplaceOrderPaymentTimeoutSeconds?.message)}
              helperText={formErrors?.marketplaceOrderPaymentTimeoutSeconds?.message}
            />
          </Grid>
        </Grid>
      </FormCard>
    </Grid>
  );
};
