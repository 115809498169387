import { Draggable } from "@hello-pangea/dnd";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Checkbox } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import React, { FC, useEffect, useState } from "react";
import { SortableItemProps } from "./SortableItem.types";

export const SortableItem: FC<SortableItemProps> = props => {
  const { id, isHidden, header, itemIndex: index, onChangeVisibility } = props;
  const [hidden, setHidden] = useState<boolean>(isHidden);
  const handleIsHiddenChanged = () => {
    setHidden(state => !state);
    onChangeVisibility?.(id);
  };

  useEffect(() => {
    setHidden(isHidden);
  }, [isHidden]);

  return (
    <>
      <Draggable key={id} draggableId={id} index={index}>
        {provided => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <ListItem>
              <Checkbox defaultChecked={!isHidden} onChange={handleIsHiddenChanged} checked={!hidden} />
              <ListItemText primary={header} />
              <ListItemSecondaryAction sx={{ cursor: "pointer" }}>
                <ListItemIcon className='drag-handle' {...provided.dragHandleProps}>
                  <DragHandleIcon />
                </ListItemIcon>
              </ListItemSecondaryAction>
            </ListItem>
          </div>
        )}
      </Draggable>
    </>
  );
};
