import { PayerError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { usePayerCreateMutation } from "pages/Payers/gql";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { payersPaths } from "../../Constants/payersPaths";
import { IFormData } from "../types";

export const usePayerAdd = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<PayerError[] | undefined>([]);

  const [payerCreate, { loading: isSubmitting }] = usePayerCreateMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.payerCreate?.payerErrors || [];
      if (mutationErrors?.length === 0) {
        succeeded(t("Payer Created Successfully"));
        navigate(payersPaths.listPath.fullPath);
      } else {
        setErrors(mutationErrors as PayerError[]);
        formatMessageErrors(mutationErrors);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const handleSubmit = (input: IFormData) => {
    payerCreate({
      variables: {
        input: {
          ...input,
          tpo: input.tpo.value,
        },
      },
    });
  };

  return {
    errors,
    handleSubmit,
    isSubmitting,
  };
};
