import { makeStyles } from "tss-react/mui";

type CustomTableStylesParams = {
  minHeight?: number;
  maxHeight?: number;
};

export const useScrollableTableStyle = makeStyles<CustomTableStylesParams>()((theme, params) => ({
  root: {
    boxShadow: theme.mixins.shadows.md,
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.gray.light,
    padding: 0,
    overflow: "hidden",
    position: "relative",
    width: "100%",
  },
  tableContainer: {
    position: "relative",
    overflow: "auto",
    minHeight: params.minHeight || 238,
    maxHeight: params.maxHeight || 478,

    "&:active::-webkit-scrollbar-thumb, &:focus::-webkit-scrollbar-thumb, &:hover::-webkit-scrollbar-thumb": {
      visibility: "visible",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.stale.main,
      visibility: "hidden",
    },

    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
    },
  },
}));
