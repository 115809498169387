import { DateTimePicker, DateTimePickerProps } from "@mui/lab";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField, TextFieldProps } from "@mui/material";
import moment from "moment";
import React, { FC, useState } from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { MomentUtils } from "../dependencies";

type CustomDateTimePickerProps = TextFieldProps & {
  label: string;
  control: Control<any>;
  name: string;
  placeholder: string;
  format?: string;
  helperText?: string;
  error?: string | boolean;
  controllerProps?: Omit<ControllerProps, "render" | "name">;
  dateTimePickerProps?: Omit<DateTimePickerProps, "onChange" | "value" | "renderInput">;
};

export const CustomDateTimePickerField: FC<CustomDateTimePickerProps> = ({
  format,
  label,
  control,
  name,
  placeholder,
  controllerProps,
  error,
  helperText,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <Controller
        control={controllerProps?.control || control}
        name={name}
        rules={controllerProps?.rules}
        render={({ field: { onChange: handleOnChange, value } }) => {
          const handleSelectedDateTime = (dateTime: unknown) => {
            handleOnChange(moment(dateTime as string)?.isValid() ? moment(dateTime as string).format(format) : null);
          };
          return (
            <DateTimePicker
              onChange={handleSelectedDateTime}
              value={value ? moment(value, format) : null}
              InputProps={{ hiddenLabel: true }}
              open={open}
              onOpen={handleOpen}
              inputFormat={format}
              onClose={handleClose}
              label={label}
              renderInput={params => (
                <TextField
                  hiddenLabel
                  placeholder={placeholder}
                  fullWidth
                  {...params}
                  onClick={handleOpen}
                  label={label}
                  error={error}
                  helperText={helperText}
                  sx={{
                    width: "100%",
                  }}
                />
              )}
              {...props.dateTimePickerProps}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

CustomDateTimePickerField.defaultProps = {
  format: "MM/DD/yyyy HH:mm",
  dateTimePickerProps: {},
  helperText: undefined,
  error: undefined,
  controllerProps: {},
};
