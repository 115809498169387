import { getMediaLink } from "@health/common";
import { HealthPackage } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, Grid, ShowButton, TextField } from "@toolkit/ui";
import { FC } from "react";
import useToggle from "shared/hooks/useToggle";
import { useHealthPackageStyles } from "./PreviewHealthPackages.styles";

export const PreviewHealthPackages: FC<{ row: HealthPackage }> = ({ row }) => {
  const { t } = useTranslation("admin");
  const { handleToggle, open } = useToggle();
  const { classes } = useHealthPackageStyles();

  return (
    <>
      <ShowButton onClick={handleToggle} />
      {open && (
        <CustomDialog open={open} onCloseModal={handleToggle} title={t("Health package preview")}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField disabled fullWidth value={row?.description} label={t("Description")} />
            </Grid>
            <Grid item xs={6}>
              <TextField disabled fullWidth value={row?.descriptionAr} label={t("Arabic Description")} />
            </Grid>
            <Grid item>
              <img
                className={classes.imageListItem}
                height={150}
                width={"300px"}
                loading='lazy'
                src={getMediaLink(decodeURI(row?.mainImageUrl!))}
                alt={"preview"}
              />
            </Grid>
          </Grid>
        </CustomDialog>
      )}
    </>
  );
};
