import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const strapiSessionsAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    title: z.string(),
}));
export const StrapiSessionAutocompleteSchemas = {
    strapiSessionsAutocomplete,
    strapiSessionsAutocompleteOptional: strapiSessionsAutocomplete.nullable().optional(),
};
