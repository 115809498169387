/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type ProductBrandFragmentFragment = { __typename?: 'ProductBrand', name: string, id: string };

export const ProductBrandFragmentFragmentDoc = gql`
    fragment ProductBrandFragment on ProductBrand {
  name
  id
}
    `;