import { Product } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useToasts } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import VariantForm from "../components/variants/components/Form/VariantForm.component";
import { ProductVariantDocument, useProductQuery, useProductVariantQuery, useProductVariantUpdateMutation } from "../gql";

const VariantsFormEditPage = () => {
  const { t } = useTranslation("admin");
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const params = useParams();
  const idVariant = params?.vid as string;
  const ProductVariantUpdatedSuccessfully = t("Product Variant Updated successfully");
  const [productVariantUpdate] = useProductVariantUpdateMutation({
    onCompleted: data => {
      const errors = data?.productVariantUpdate?.productErrors;

      if (errors?.length! > 0) {
        addToast(t(formatMessageErrors(errors)), {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(ProductVariantUpdatedSuccessfully, {
          appearance: "success",
          autoDismiss: true,
        });
        navigate(-1);
      }
    },
    onError: () => {
      addToast(
        t("Product Variant Updated Failed", {
          appearance: "error",
          autoDismiss: true,
        })
      );
    },
    refetchQueries: [
      {
        query: ProductVariantDocument,
        variables: {
          id: idVariant,
        },
      },
    ],
  });

  const { data: productVariant, loading: LoadingVariant } = useProductVariantQuery({
    variables: {
      id: idVariant,
    },
  });
  const handleSubmit = (data: any) => {
    productVariantUpdate({
      variables: {
        ...data,
      },
    });
  };
  const idProduct = productVariant?.productVariant?.product?.id;
  const { data } = useProductQuery({
    variables: {
      id: idProduct as string,
    },
    skip: !idProduct,
  });
  return (
    <VariantForm
      productVariant={productVariant}
      defaultProductData={productVariant?.productVariant?.product}
      loading={LoadingVariant}
      product={data?.product as Product}
      id={idVariant}
      isEdit={true}
      onSubmitData={handleSubmit}
    />
  );
};
export default VariantsFormEditPage;
