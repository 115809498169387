import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const categoryAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
    nameAr: z.string().nullable().optional(),
}));
export const CategoryAutocompleteSchemas = {
    categoryAutocomplete,
    categoryAutocompleteOptional: categoryAutocomplete.nullable().optional(),
};
