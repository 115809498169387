/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductVariantQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ProductVariantQuery = { __typename?: 'Query', productVariant?: { __typename?: 'ProductVariant', id: string, trackInventory: boolean, sku: string, nameAr?: string | null, name?: string | null, attributes: Array<{ __typename?: 'SelectedAttribute', attribute: { __typename?: 'Attribute', id: string, name?: string | null, values?: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null } | null> | null }, values: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null } | null> }>, product?: { __typename?: 'Product', id: string, mainImageUrl?: string | null, images?: Array<{ __typename?: 'ProductImage', alt: string, url: string, id: string } | null> | null } | null, images?: Array<{ __typename?: 'ProductImage', alt: string, url: string, id: string } | null> | null } | null };


export const ProductVariantDocument = gql`
    query productVariant($id: ID!) {
  productVariant(id: $id) {
    id
    trackInventory
    attributes {
      attribute {
        id
        name
        values {
          id
          name
        }
      }
      values {
        id
        name
      }
    }
    sku
    id
    product {
      id
      mainImageUrl
      images {
        alt
        url
        id
      }
    }
    images {
      alt
      url
      id
    }
    nameAr
    name
  }
}
    `;

/**
 * __useProductVariantQuery__
 *
 * To run a query within a React component, call `useProductVariantQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductVariantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductVariantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductVariantQuery(baseOptions: Apollo.QueryHookOptions<ProductVariantQuery, ProductVariantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductVariantQuery, ProductVariantQueryVariables>(ProductVariantDocument, options);
      }
export function useProductVariantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductVariantQuery, ProductVariantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductVariantQuery, ProductVariantQueryVariables>(ProductVariantDocument, options);
        }
export type ProductVariantQueryHookResult = ReturnType<typeof useProductVariantQuery>;
export type ProductVariantLazyQueryHookResult = ReturnType<typeof useProductVariantLazyQuery>;
export type ProductVariantQueryResult = Apollo.QueryResult<ProductVariantQuery, ProductVariantQueryVariables>;