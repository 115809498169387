var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { refetchAutocompleteQuery } from "../../others";
import { useDecisionActionFieldTaskDefinitionsQuery } from "./gql";
export const DecisionActionFieldTaskDefinitionsAutocomplete = props => {
    const { name, filter, skip, label, placeholder } = props, rest = __rest(props, ["name", "filter", "skip", "label", "placeholder"]);
    const { t } = useTranslation("domains");
    const { data, isLoading, hasMoreData, refetch, fetchMoreData } = useRelayStyleConnection({
        dataAccessor: "taskDefinitions",
        useQuery: useDecisionActionFieldTaskDefinitionsQuery,
        variables: { filter },
        skip: props.disabled || skip,
    });
    const options = mapToAutocompleteOptions(data, "id", item => `${item === null || item === void 0 ? void 0 : item.id} ${item === null || item === void 0 ? void 0 : item.name}`, item => `${item === null || item === void 0 ? void 0 : item.id} ${item === null || item === void 0 ? void 0 : item.nameAr}`);
    const onSearchInputChange = (value) => {
        refetchAutocompleteQuery(filter, "code", value, refetch);
    };
    return (React.createElement(FormAutocomplete, Object.assign({}, rest, { name: name, label: label || t("Task Definition"), placeholder: placeholder || label || t("Task Definition"), options: options, hasMore: hasMoreData, loading: isLoading, fetchMoreData: fetchMoreData, onSearchInput: onSearchInputChange })));
};
