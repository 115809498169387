import { SimplePaletteColorOptions } from "@mui/material/styles";

export const red: SimplePaletteColorOptions = {
  // Persian Red
  main: "#cb3433",
  50: "#fcf5f5",
  100: "#faebeb",
  200: "#f7e0e0",
  300: "#f0c2c2",
  400: "#ebadad",
  500: "#e69999",
  600: "#e08585",
  700: "#db7070",
  800: "#d65c5c",
  900: "#d14747",
  1100: "#b82e2e",
  1200: "#a32929",
  1300: "#8f2424",
  1400: "#7a1f1f",
  1500: "#661919",
  1600: "#521414",
  1700: "#3d0f0f",
  1800: "#290a0a",
  1900: "#140505",
};
