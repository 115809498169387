import { WeightUnitsEnum } from "@health/queries/types";
import { formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, FormCard, Grid, TextField } from "@toolkit/ui";
import React from "react";
import { useFormContext } from "react-hook-form";
import { ISiteSetting } from "../components/types";
import { fieldPattern } from "../utils";
import { formGirdBreakPoints } from "./constants";
import { GeneralToggleSettingsGroups } from "./GeneralToggleSettingsGroups.comonent";

export const GeneralSiteSetting: React.FC<ISiteSetting> = ({ loading, errors, siteSettings }) => {
  const { control, register } = useFormContext();
  const { t } = useTranslation("admin");
  const WeightUnits = Object.keys(WeightUnitsEnum).map(key => {
    return { value: WeightUnitsEnum[key], name: key };
  });
  const defaultWeightUnit = WeightUnits.find(option => option.value === siteSettings?.defaultWeightUnit);

  return (
    <Grid item xs={12}>
      <FormCard title={t("General Settings")} loading={loading} doYouHaveData={Boolean(siteSettings)}>
        <Grid container spacing={formGirdSpacing}>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              label={t("Vat Percentage")}
              defaultValue={siteSettings?.vatPercentage}
              fullWidth
              InputProps={{
                endAdornment: "%",
              }}
              {...register("vatPercentage", {
                ...fieldPattern(t),
              })}
              error={Boolean(errors?.vatPercentage?.message)}
              helperText={errors?.vatPercentage?.message}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <AutocompleteController
              ControllerProps={{
                name: "defaultWeightUnit",
                control: control,
                rules: { required: getRequiredValidation(t, true) },
                defaultValue: defaultWeightUnit,
              }}
              TextFieldProps={{
                error: Boolean(errors?.defaultWeightUnit?.message),
                helperText: errors?.defaultWeightUnit?.message,
                label: t("Weight Unit"),
              }}
              getOptionLabel={o => o?.name}
              options={WeightUnits || []}
              isOptionEqualToValue={(option, value) => value?.value === option.value}
            />
          </Grid>
        </Grid>
        <GeneralToggleSettingsGroups siteSettings={siteSettings} />
      </FormCard>
    </Grid>
  );
};
