import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const CompleteIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' {...props}>
      <path
        id='icons8-complete'
        data-name='icons8-schedule (2)'
        transform='translate(-3 -3)'
        fill={props.fill ? props.fill : palette.primary.main}
        d='M15,3A12,12,0,1,0,27,15,12,12,0,0,0,15,3Zm6.707,9.707-7.56,7.56a1,1,0,0,1-1.414,0L9.28,16.814A1,1,0,0,1,10.694,15.4l2.746,2.746,6.853-6.853a1,1,0,1,1,1.414,1.414Z'
      />
    </SvgIcon>
  );
};
