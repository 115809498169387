var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useSessionListQuery, useStrapiPaginatedData } from "@health/strapi";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
export const StrapiSessionsAutocomplete = props => {
    const { name, skip, label, placeholder } = props, rest = __rest(props, ["name", "skip", "label", "placeholder"]);
    const { t } = useTranslation("domains");
    const { data, isLoading, hasMoreData, fetchMoreData } = useStrapiPaginatedData({
        dataAccessor: "sessions",
        useQuery: useSessionListQuery,
        skip: props.disabled || skip,
    });
    const _data = data === null || data === void 0 ? void 0 : data.map(item => {
        var _a;
        return ({
            id: item === null || item === void 0 ? void 0 : item.id,
            title: (_a = item === null || item === void 0 ? void 0 : item.attributes) === null || _a === void 0 ? void 0 : _a.title,
        });
    });
    const options = mapToAutocompleteOptions(_data, "id", "title");
    return (React.createElement(FormAutocomplete, Object.assign({}, rest, { name: name, label: label || t("Session"), placeholder: placeholder || label || t("Session"), options: options, hasMore: hasMoreData, loading: isLoading, fetchMoreData: fetchMoreData })));
};
