import { Autocomplete } from "@mui/lab";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "@toolkit/i18n";
import React, { FC, KeyboardEvent, useState } from "react";
import { TextField } from "../../mui";
import { CustomIcon } from "../CustomIcon";
import { OrdersAutocompleteSearchProps } from "./types";
import { useOrderAutocompleteSearchStyle } from "./useOrderAutocompleteSearchStyle";

export const OrdersAutocompleteSearch: FC<OrdersAutocompleteSearchProps> = ({
  value,
  ordersData,
  onChange: handleChange,
  onSearch: handleSearch,
}) => {
  const { t } = useTranslation();
  const { classes } = useOrderAutocompleteSearchStyle();
  const [isSearchActive, setIsSearchActive] = useState(false);

  const orderDataNumber = ordersData?.map(order => order?.number);
  const handleToggleSearch = () => {
    setIsSearchActive(!isSearchActive);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    event.code === "Enter" && handleSearch();
  };

  const handleSearchInput = () => {
    isSearchActive ? handleSearch() : handleToggleSearch();
  };

  return (
    <Box height='40px'>
      {!isSearchActive ? (
        <IconButton color='error' onClick={handleToggleSearch}>
          <CustomIcon icon='searchInput' />
        </IconButton>
      ) : (
        <Autocomplete
          size='small'
          options={orderDataNumber || []}
          multiple
          value={value}
          onChange={(e, v) => handleChange(e, v as string[])}
          getOptionLabel={option => String(option)}
          classes={{
            root: classes.rootAuto,
          }}
          renderInput={params => (
            <TextField
              {...params}
              size='small'
              fullWidth
              hiddenLabel
              className={classes.textField}
              placeholder={!value?.length ? t("Order Number") : ""}
              onKeyUp={handleKeyPress}
              InputProps={{
                ...params.InputProps,
                endAdornment: <IconButton onClick={handleSearchInput}>{<CustomIcon icon='searchInput' />}</IconButton>,
              }}
            />
          )}
        />
      )}
    </Box>
  );
};
