import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "@toolkit/i18n";
import React from "react";
import { IFilterProps } from "./Filter.types";
import RenderFieldUiComponent from "./FilterItems.component";
import { useFiltersStyles } from "./Filters.styles";

const Filter = <X extends object>(props: IFilterProps<X>) => {
  const { t } = useTranslation();
  const { children, fields } = props;
  const { classes } = useFiltersStyles();

  return (
    <div className={classes.root}>
      {children && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}

      {fields?.length ? (
        <Grid container spacing={2}>
          {fields?.map((field, index) => {
            return (
              <Grid
                container
                alignItems='center'
                key={`${field?.key}_${index}`}
                item
                xs={field?.filter?.grid || 12}
                sm={field?.filter?.gridSM || 6}
                spacing={1}
              >
                <Grid flex={1} item>
                  <RenderFieldUiComponent field={field} />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        // to do fix this message -> There are no filters
        <Typography>{t("There are no filters supported")}</Typography>
      )}
    </div>
  );
};

export default Filter;
