import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useToasts } from "@toolkit/ui";
import React from "react";
import { useProductVariantImageAssignMutation, useProductVariantImageUnassignMutation, useProductVariantQuery } from "../../../../../gql";
import { ProductVariantImagesInterface } from "../ProductVariantImages/ProductVariantImages.type";

const useProductVariantImages = ({ product, productVariantCreate, idVariant }: ProductVariantImagesInterface) => {
  const { t } = useTranslation("admin");
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClickOpen = () => {
    setIsOpen(!isOpen);
  };
  const { addToast } = useToasts();
  const variantImageAssignSuccessfully = t("Variant Image Assign Successfully");
  const [variantImageAssign] = useProductVariantImageAssignMutation({
    onCompleted: data => {
      const errors = data?.productVariantImageAssign?.productErrors;
      if (errors) {
        if (errors?.length === 0) {
          refetch();
          setIsOpen(false);
          addToast(variantImageAssignSuccessfully, {
            appearance: "success",
            autoDismiss: true,
          });
        } else if (errors?.length > 0) {
          addToast(t(formatMessageErrors(errors)), {
            appearance: "error",
            autoDismiss: true,
          });
          console.error("<< Variant Image Assign >>", formatMessageErrors(errors));
        }
      } else {
        addToast(variantImageAssignSuccessfully, {
          appearance: "success",
          autoDismiss: true,
        });
      }
    },
    onError: () => {
      addToast(t("Variant Image Assign Failed"), {
        appearance: "error",
        autoDismiss: true,
      });
    },
  });
  const variantImageDeleteSuccessfully = t("Variant Image Delete Successfully");
  const [variantImageUnAssign] = useProductVariantImageUnassignMutation({
    onCompleted: data => {
      const errors = data?.productVariantImageUnassign?.productErrors;
      if (errors) {
        if (errors?.length === 0) {
          refetch();
          setIsOpen(false);
          addToast(variantImageDeleteSuccessfully, {
            appearance: "success",
            autoDismiss: true,
          });
        } else if (errors?.length > 0) {
          addToast(t(formatMessageErrors(errors)), {
            appearance: "error",
            autoDismiss: true,
          });
          console.error("<< Variant Image Delete >>", formatMessageErrors(errors));
        }
      } else {
        addToast(variantImageDeleteSuccessfully, {
          appearance: "success",
          autoDismiss: true,
        });
      }
    },

    onError: () => {
      addToast(t("Variant Image Delete Failed"), {
        appearance: "error",
        autoDismiss: true,
      });
    },
  });

  const { data, refetch } = useProductVariantQuery({
    variables: {
      id: `${productVariantCreate?.id || idVariant}`,
    },
  }) as any;
  const result = product?.images;
  const handleVariantImageAssign = (imageId: string) => {
    variantImageAssign({
      variables: {
        imageId,
        variantId: productVariantCreate?.id ? String(productVariantCreate?.id) : String(idVariant),
      },
    });
  };

  const handleVariantImageUnAssign = (imageId: string) => {
    variantImageUnAssign({
      variables: {
        imageId,
        variantId: productVariantCreate?.id ? String(productVariantCreate?.id) : String(idVariant),
      },
    });
  };

  const imageCanAssign = result?.filter(img => data?.productVariant?.images?.every(vimg => vimg.id !== img?.id));
  return { imageCanAssign, handleVariantImageUnAssign, handleVariantImageAssign, handleClickOpen, data, isOpen, result };
};
export default useProductVariantImages;
