import { Components, Theme } from "@mui/material/styles";

const fontFamiliesTypes: Array<keyof Theme["mixins"]["fonts"]["fontFamily"]> = ["medium", "regular", "bold"];
const fontSizes: Array<keyof Theme["mixins"]["fonts"]["fontSize"]> = ["xxs", "xs", "sm", "md", "lg", "xl", "xxl"];

export const MuiTypographyOverride: Components<Theme>["MuiTypography"] = {
  defaultProps: {
    fontWeight: "normal",
    fontFamily: ({ mixins }) => mixins.fonts.fontFamily.regular,
    fontSize: ({ mixins }) => mixins.fonts.fontSize.sm,
  },
  styleOverrides: {},
  variants: fontFamiliesTypes
    .map(type =>
      fontSizes.map(size => {
        return ["", "secondary", "white"].map(color => {
          return {
            props: { variant: `${type}-${size}${color ? "-" + color : ""}` as any },
            style: ({ theme }) => ({
              fontFamily: theme.mixins.fonts.fontFamily[type as unknown as keyof typeof theme.mixins.fonts.fontFamily],
              fontSize: theme.mixins.fonts.fontSize[size as keyof typeof theme.mixins.fonts.fontSize],
              color: {
                "": theme.palette.text.primary,
                secondary: theme.palette.text.secondary,
                white: theme.palette.common.white,
              }[color],
            }),
          } as NonNullable<NonNullable<Components<Theme>["MuiTypography"]>["variants"]>[number];
        });
      })
    )
    .flat(2),
};
