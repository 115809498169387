import { getAutocompleteFilter } from "@toolkit/ui";
import { useCategoriesAutocompleteQuery } from "./gql";
export const getCategoriesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "categories",
        query: useCategoriesAutocompleteQuery,
        labelBy: "name",
        arabicLabelBy: "nameAr",
        backendAccessor: "id",
        filterSearchKey: "search",
    });
};
