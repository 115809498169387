import { Divider, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "@toolkit/i18n";
import React, { Fragment, useState } from "react";
import { CardContent } from "../AuditHistory/CardContent";
import { CustomDialog } from "../CustomDialog";
import { ShowButton } from "../ShowButton";

type medicationProps = {
  id: string | null | undefined;
  code: string | null | undefined;
  display?: string | null | undefined;
  instructions: string | null | undefined;
  arabicInstructions: string | null | undefined;
  refills: string | number | null | undefined;
  quantity: string | number | null | undefined;
};
type MedicationsModelProps<T extends medicationProps | null> = {
  // eslint-disable-next-line react/require-default-props
  medications?: T[] | null;
};

export const MedicationsModel = <T extends medicationProps | null>(props: MedicationsModelProps<T>) => {
  const { medications } = props;
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(!open);
  };
  const theme = useTheme();
  return (
    <CustomDialog
      title={t("Medications")}
      open={open}
      onCloseModal={handleClickOpen}
      maxWidth='sm'
      button={<ShowButton onClick={handleClickOpen} />}
    >
      {!medications?.length ? (
        <Typography fontSize={theme.mixins.fonts.fontSize.xl} fontFamily={theme.mixins.fonts.fontFamily.medium}>
          {t("There are no medications")}
        </Typography>
      ) : (
        medications?.map((medication, index) => {
          return (
            <Fragment key={medication?.id}>
              <Box color={theme.palette.primary.main} display='flex' justifyContent='space-between'>
                <Box width={300}>
                  <CardContent title={t("Generic Name")} subTitle={medication?.display} />
                  {medication?.code && <CardContent title={t("Code")} subTitle={medication?.code} />}
                </Box>
                <Box width={300}>
                  {medication?.code && <CardContent title={t("Quantity")} subTitle={medication?.quantity} />}

                  <CardContent title={t("Refills")} subTitle={medication?.refills} />
                </Box>
              </Box>
              {medication?.instructions && (
                <Box m={"0px 8px 8px"} color={theme.palette.primary.main}>
                  <Typography fontFamily={theme.mixins.fonts.fontFamily.medium}>{t("Instructions")}</Typography>
                  <Typography fontSize={13} color={theme.palette.stale.main}>
                    {i18n?.language === "ar" ? medication?.arabicInstructions : medication?.instructions}
                  </Typography>
                </Box>
              )}
              {medications.length - index !== 1 && <Divider />}
            </Fragment>
          );
        })
      )}
    </CustomDialog>
  );
};

MedicationsModel.defaultProps = {
  medications: [],
};
