import { useTranslation } from "@toolkit/i18n";
import { WarningConfirmationDialog } from "@toolkit/ui";
import React from "react";

export interface UnPublishDialogProps {
  isOpen: boolean;
  onUnPublish: () => void;
  onClose: () => void;
  isUnPublishLoading: boolean;
}

export const UnPublishDialog: React.FC<UnPublishDialogProps> = ({ isOpen, onUnPublish, onClose: handleClose, isUnPublishLoading }) => {
  const { t } = useTranslation("admin");

  const handleUnPublishConfirmation = () => {
    onUnPublish();
    handleClose();
  };

  return (
    <WarningConfirmationDialog
      isOpen={isOpen}
      titleText={t("UnPublish Confirmation")}
      bodyText={t("Are you sure you want to unpublish this survey?")}
      confirmText={t("UnPublish")}
      cancelText={t("Cancel")}
      onClose={handleClose}
      onConfirm={handleUnPublishConfirmation}
      disableConfirmationButton={isUnPublishLoading}
    />
  );
};

export default UnPublishDialog;
