import { CallbackRequest } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomMuiDialog, ShowButton, TextField } from "@toolkit/ui";
import React, { FC } from "react";

export const PatientNotesModal: FC<{ patientNotes?: CallbackRequest["patientNotes"] }> = ({ patientNotes }) => {
  const { t } = useTranslation("admin");

  return (
    <CustomMuiDialog
      DialogTitleProps={{
        title: t("Patient Notes"),
      }}
      button={<ShowButton />}
      DialogActionsProps={{
        hasClose: true,
        closeTitle: t("Done"),
      }}
    >
      <TextField
        multiline
        fullWidth
        minRows={10}
        InputProps={{
          readOnly: true,
        }}
        value={patientNotes || t("Patient didn't provide notes")}
      />
    </CustomMuiDialog>
  );
};
