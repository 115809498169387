/* eslint-disable react/jsx-handler-names */

import { Chip, CircularProgress, useTheme } from "@mui/material";
import Autocomplete, { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useTranslation } from "@toolkit/i18n";
import React, { FC, SyntheticEvent, useEffect, useMemo, useRef } from "react";

import InfiniteScrollComponent from "../InfiniteScroll/InfiniteScrollComponent";
import { TruncateTypography } from "../TruncateTypography";
import { useAutocompleteStyles } from "./Autocomplete.styles";
import { AutocompleteComponentProps } from "./types";
import { get } from "lodash";
import { useDebounce } from "@toolkit/core";

const defaultRenderTags =
  <T,>(getOptionLabel: (option: T) => string) =>
  (value: T[], getTagProps: AutocompleteRenderGetTagProps) =>
    value.map((option, index) => (
      <Chip
        label={<TruncateTypography text={String(getOptionLabel?.(option) ?? get(option, "label", get(option, "name")))} />}
        {...getTagProps({ index })}
        key={"" + get(option, "id", option)!}
      />
    ));

const defaultRenderOption =
  <T,>(getOptionLabel: (option: T) => string) =>
  (props: React.HTMLAttributes<HTMLLIElement>, option: T) => {
    const theme = useTheme();
    return (
      <li {...props}>
        <TruncateTypography fontFamily={theme.mixins.fonts.fontFamily.medium} text={String(getOptionLabel?.(option))} />
      </li>
    );
  };

// eslint-disable-next-line max-statements
export const AutocompleteComponent: FC<AutocompleteComponentProps> = ({
  fetchMoreData,
  hasMore,
  customFilter,
  field,
  TextFieldProps,
  onSearchInput,
  ...props
}) => {
  const { classes } = useAutocompleteStyles();
  const [inputValue, setInputValue] = React.useState<string>();

  const debouncedSearchInput = useDebounce(inputValue, 500);
  const isThereFirstUserAction = useRef(false);

  useEffect(() => {
    if (isThereFirstUserAction.current) {
      onSearchInput?.(debouncedSearchInput ?? "");
    }
  }, [debouncedSearchInput]);

  const { t } = useTranslation();

  const handleInputChange = (event: SyntheticEvent, _value: string, _reason: string) => {
    setInputValue(_value);
    if (_reason === "input" || _reason === "clear") {
      isThereFirstUserAction.current = true;
    }
  };

  const handleChange: AutocompleteComponentProps["onChange"] = (_, data, r) => {
    if (field) {
      field?.onChange?.(data);
    }

    props?.onChange?.(_, data, r);
  };
  const value = useMemo(() => {
    if (field) {
      return props?.multiple ? field?.value || [] : field?.value || null;
    }
    return props?.value;
  }, [field?.value, props?.multiple, props?.value]);

  return (
    <Autocomplete
      {...props}
      noOptionsText={t("No options")}
      loadingText={t("Loading...")}
      className={classes.autocomplete}
      classes={{
        paper: props?.options?.length! > 5 ? classes.paper : `${classes.paper} ${classes.height}`,
        // popper: onChange ? "" : classes.popper,
        tag: classes.tag,
      }}
      value={value}
      renderOption={props.renderOption || defaultRenderOption(props?.getOptionLabel!)}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      blurOnSelect
      options={props?.options || []}
      onChange={handleChange}
      ListboxComponent={listBoxProps => {
        return (
          <InfiniteScrollComponent
            {...listBoxProps}
            options={props?.options}
            fetchMoreData={fetchMoreData!}
            hasMore={hasMore}
            customFilter={customFilter}
          />
        );
      }}
      renderTags={props?.renderTags || defaultRenderTags(props?.getOptionLabel!)}
      renderInput={params => (
        <TextField
          {...TextFieldProps}
          variant={"filled"}
          className={classes.textField}
          error={TextFieldProps?.error}
          hiddenLabel
          {...params}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {props?.loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
