/* eslint-disable react/forbid-component-props */
/* eslint-disable react/jsx-handler-names */
import React, { useEffect, useRef, useState } from "react";
import { CountryIso2, FlagImage as Flag, defaultCountries, parseCountry, usePhoneInput } from "react-international-phone";
import { useModal } from "../../../hooks";
import { Box, InputAdornment, MenuItem, Select, TextField, TextFieldProps, Typography } from "../../../mui";
import { usePhoneNumberStyles } from "./PhoneNumber.styles";

export type PhoneInputProps = Partial<TextFieldProps> & {
  name: string;
  onChange: (value: string) => void;
};

export const PhoneInput: React.FC<PhoneInputProps> = props => {
  const { value, variant = "filled", onChange, ...rest } = props;

  const BoxRef = useRef<HTMLDivElement>();
  const [width, setWidth] = useState(0);
  const { open, handleClose, handleOpen } = useModal();

  const { handlePhoneValueChange, inputValue, setCountry, country } = usePhoneInput({
    defaultCountry: "ae",
    preferredCountries: ["ae", "sa", "ps"],
    countries: defaultCountries,
    value: value ? value.toString() : "",
    defaultMask: ".........",
    onChange: ({ phone }) => {
      onChange(phone || "");
    },
  });
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      setWidth(entries[0].contentRect.width);
    });
    observer.observe(BoxRef.current!);
    return () => BoxRef.current && observer.unobserve(BoxRef.current);
  }, []);

  const { classes, cx } = usePhoneNumberStyles({ inputWidth: width });
  return (
    <Box ref={BoxRef} sx={{ width: "100%" }}>
      <TextField
        {...rest}
        className={cx(open && classes.focusedTextField)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handlePhoneValueChange(e);
        }}
        value={inputValue}
        variant={variant}
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{ backgroundColor: "red", width: "fit-content", height: "fit-content" }} position='start'>
              <Select
                sx={{
                  fieldset: {
                    display: "none",
                  },
                  svg: {
                    right: 0,
                    top: 14,
                  },
                  "& .MuiSelect-select": {
                    position: "relative",
                    bottom: 4,
                  },
                }}
                MenuProps={{
                  classes: {
                    paper: classes.paper,
                    list: classes.list,
                  },
                  PopoverClasses: { paper: classes.paper },
                  anchorEl: BoxRef.current,
                  anchorOrigin: { vertical: "bottom", horizontal: "left" },
                  transformOrigin: { vertical: "top", horizontal: "left" },
                  onClose: handleClose,
                }}
                value={country}
                onChange={e => {
                  setCountry(e.target.value as CountryIso2);
                  handleClose();
                }}
                renderValue={value => <Flag iso2={value.iso2} style={{ display: "flex", height: 30, margin: 0 }} />}
                open={open}
                onOpen={handleOpen}
              >
                {defaultCountries.map(c => {
                  const currentCountry = parseCountry(c);
                  return (
                    <MenuItem key={currentCountry.iso2} value={currentCountry.iso2}>
                      <Flag iso2={currentCountry.iso2} className={classes.marginStart} style={{ width: 30, marginTop: 0 }} />
                      <Typography className={classes.marginStart}>{currentCountry.name}</Typography>
                      <Typography className={classes.marginStart}>+{currentCountry.dialCode}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
