/* eslint-disable max-lines */
import { CoverageByType } from "@health/queries/types";
import { formGirdSpacing, getMediaLink, toPascalCase } from "@toolkit/core";
import {
  AutocompleteComponent,
  Box,
  Button,
  CheckMarkIcon,
  CustomMuiDialog,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@toolkit/ui";
import moment from "moment";
import React, { FC } from "react";
import { MembershipDate } from "./MembershipDate";
import { ApproveInsuranceCardDialogProps } from "./types";
import { useApproveInsuranceCardDialog } from "./useApproveInsuranceCardDialog";

export const ApproveInsuranceCardDialog: FC<ApproveInsuranceCardDialogProps> = ({ healthProgramMembershipRequest }) => {
  const {
    isOpen,
    membershipStart,
    membershipEnd,
    coverageBy,
    isDataInvalid,
    tooltipTitle,
    isAlreadyApproved,
    isAlreadyRejected,
    handleChangeMembershipStart,
    handleSubmit,
    handleChangeMembershipEnd,
    handleChangeInsuranceClass,
    handleChangeInsuranceCategory,
    handleChangeCoverageBy,
    handleToggle,
    t,
    isSubmitting,
  } = useApproveInsuranceCardDialog({ healthProgramMembershipRequest });
  const theme = useTheme();
  return (
    <CustomMuiDialog
      open={isOpen}
      DialogTitleProps={{
        title: t("Approve Insurance Request"),
        onClose: handleToggle,
      }}
      button={
        <Tooltip title={tooltipTitle}>
          <span>
            <IconButton disabled={isAlreadyApproved || isAlreadyRejected} onClick={handleToggle}>
              <CheckMarkIcon color='success' />
            </IconButton>
          </span>
        </Tooltip>
      }
      DialogActionsProps={{
        children: (
          <Tooltip title={tooltipTitle}>
            <span>
              <Button
                color='success'
                onClick={handleSubmit}
                disabled={isAlreadyApproved || isAlreadyRejected || isDataInvalid || isSubmitting}
              >
                {t("Approve")}
              </Button>
            </span>
          </Tooltip>
        ),
      }}
    >
      <Box>
        {isAlreadyApproved && (
          <Typography color={theme.palette.error.main} paragraph>
            {t("The request is already been approved")}
          </Typography>
        )}
        <Grid container spacing={formGirdSpacing}>
          <Grid item xs={12}>
            <AutocompleteComponent
              fullWidth
              value={coverageBy}
              disableClearable
              TextFieldProps={{
                label: t("Coverage By"),
              }}
              onChange={handleChangeCoverageBy}
              getOptionLabel={o => t(toPascalCase(o?.toLowerCase()))}
              options={Object.values(CoverageByType)}
            />
          </Grid>

          <Grid item xs={6}>
            <MembershipDate
              label={t("Membership Start Date")}
              date={membershipStart}
              onChangeDate={handleChangeMembershipStart}
              min={moment().add(1, "day")}
              isRequired={!membershipStart}
              errorMessage={t("Required")}
            />
          </Grid>
          <Grid item xs={6}>
            <MembershipDate
              label={t("Membership End Date")}
              date={membershipEnd}
              onChangeDate={handleChangeMembershipEnd}
              min={membershipStart}
              isRequired={!membershipEnd}
              errorMessage={t("Required")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              placeholder={t("Insurance Class")}
              label={t("Insurance Class")}
              onChange={handleChangeInsuranceClass}
              defaultValue={healthProgramMembershipRequest?.healthProgramMember?.insuranceClass}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              placeholder={t("Insurance Category")}
              label={t("Insurance Category")}
              onChange={handleChangeInsuranceCategory}
              defaultValue={healthProgramMembershipRequest?.healthProgramMember?.insuranceCategory}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={formGirdSpacing}>
              <Grid item xs={6}>
                {healthProgramMembershipRequest?.frontInsuranceCardPhoto && (
                  <Box>
                    <Typography paragraph>{t("Front Insurance Card Photo")}</Typography>
                    <img
                      height={300}
                      width={300}
                      loading='lazy'
                      src={getMediaLink(healthProgramMembershipRequest.frontInsuranceCardPhoto)}
                      alt='front-insurance-card'
                    />
                  </Box>
                )}
              </Grid>
              <Grid item xs={6}>
                {healthProgramMembershipRequest?.backInsuranceCardPhoto && (
                  <Box>
                    <Typography paragraph>{t("Back Insurance Card Photo")}</Typography>
                    <img
                      height={300}
                      width={300}
                      loading='lazy'
                      src={getMediaLink(healthProgramMembershipRequest.backInsuranceCardPhoto)}
                      alt='back-insurance-card'
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CustomMuiDialog>
  );
};
