import { makeStyles } from "tss-react/mui";

export const useInfiniteScrollStyles = makeStyles()({
  scroll: {
    overflow: "hidden",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    height: 50,
  },
});
