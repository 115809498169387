import { useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { useModal } from "../../hooks";
import { CustomDialog } from "../CustomDialog";
import { ShowButton } from "../ShowButton";
import { Button } from "@mui/material";

type ModalProps = {
  title: string;
  children: React.ReactNode;
};

export const GridColumnInfoModal: FC<ModalProps> = ({ title, children }) => {
  const { t } = useTranslation();
  const { handleClose, handleOpen, open } = useModal();

  return (
    <CustomDialog
      hasClose
      title={title}
      open={open}
      onCloseModal={handleClose}
      button={<ShowButton onClick={handleOpen} />}
      footerComponent={<Button onClick={handleClose}>{t("Done")}</Button>}
      DialogContentProps={{
        sx: { minHeight: "200px" },
      }}
    >
      {children}
    </CustomDialog>
  );
};
