/* eslint-disable react/jsx-handler-names */
import { i18n } from "@toolkit/i18n";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  CustomDialog,
  CustomIcon,
  formatTime,
  getDayString,
  Grid,
  ShowButton,
  TextField,
  Tooltip,
  Typography,
} from "@toolkit/ui";
import React, { FC } from "react";
import { getWorkingHoursWithTimeZone } from "shared/components";
import { useWorkingHoursStyles } from "./VendorList.styles";
import { WorkingHoursProps } from "./VendorList.types";
import { useVendorsListHook } from "./VendorsComponentsList.hook";
import { useVendorBranchesHooks } from "./WorkingHours.hook";

export const WorkingHours: FC<WorkingHoursProps> = props => {
  const { id } = props;
  const { classes } = useWorkingHoursStyles();
  const { t, isOpen, currentDir, handleOpen, handleClose } = useVendorsListHook();

  const { loading, pageInfo, branchesVendor, fetchMoreData, timeZone, isFetchingMoreData } = useVendorBranchesHooks(id, isOpen);

  return (
    <React.Fragment>
      <ShowButton onClick={handleOpen} />
      {isOpen && (
        <CustomDialog
          open={isOpen}
          onCloseModal={handleClose}
          title={t("Branch Working Hours Info")}
          DialogContentProps={{ classes: { root: classes.dialogContent } }}
        >
          {loading ? (
            <CircularProgress size={50} />
          ) : (
            <div className={classes.div} id='scrollableDiv'>
              {branchesVendor && branchesVendor?.length !== 0 ? (
                <>
                  {branchesVendor?.map(dayWorkingHours => {
                    const workingHours = getWorkingHoursWithTimeZone(dayWorkingHours?.workingHours, timeZone);
                    return (
                      <Accordion square elevation={0} key={dayWorkingHours?.id}>
                        <AccordionSummary
                          expandIcon={<CustomIcon icon={"expandArrow"} />}
                          aria-controls='WorkingHours'
                          id='WorkingHours'
                          classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}
                        >
                          <Tooltip
                            title={t("Working Hours for {{ branch }} Branch", { branch: String(dayWorkingHours?.name) })}
                            sx={{
                              whiteSpace: "nowrap",
                              maxWidth: "100%",
                            }}
                          >
                            <Typography dir={currentDir} component='div' overflow='hidden' textOverflow='ellipsis'>
                              {t("Working Hours for {{ branch }} Branch", { branch: String(dayWorkingHours?.name) })}
                            </Typography>
                          </Tooltip>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            {workingHours?.length != 0 ? (
                              workingHours?.map(workingHours =>
                                workingHours?.openTimeRanges?.map((timeRange, index: number) => (
                                  <React.Fragment key={String(workingHours?.day) + index}>
                                    <Grid item xs={4}>
                                      <TextField
                                        disabled
                                        fullWidth
                                        dir={currentDir}
                                        label={t("Day")}
                                        value={t(getDayString(String(workingHours?.day)))}
                                        className={index !== 0 ? classes.hiddenInput : classes.input}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField
                                        disabled
                                        fullWidth
                                        dir={currentDir}
                                        placeholder={t(timeRange?.openTime)}
                                        label={t("Open Time")}
                                        value={formatTime(timeRange?.openTime, i18n.language)}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField
                                        disabled
                                        fullWidth
                                        dir={currentDir}
                                        placeholder={t(timeRange?.closeTime)}
                                        label={t("Close Time")}
                                        value={formatTime(timeRange?.closeTime, i18n.language)}
                                      />
                                    </Grid>
                                  </React.Fragment>
                                ))
                              )
                            ) : (
                              <Grid item xs={12}>
                                <Typography dir={currentDir} variant={"body1"}>
                                  {t("No Working Hours for this branch")}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                  {pageInfo?.hasNextPage && (
                    <Box display='flex' justifyContent='center' marginTop={2}>
                      {!isFetchingMoreData ? (
                        <Button onClick={fetchMoreData} variant='contained'>
                          {t("Load More")}
                        </Button>
                      ) : (
                        <CircularProgress size={50} />
                      )}
                    </Box>
                  )}
                </>
              ) : (
                <Typography dir={currentDir} variant='h6'>
                  {t("No Working Hours for this branch")}
                </Typography>
              )}
            </div>
          )}
        </CustomDialog>
      )}
    </React.Fragment>
  );
};
