import { useCustomFormContext } from "@toolkit/core";
import React, { FC } from "react";
import { TextFieldProps } from "../../../mui";
import { Controller } from "react-hook-form";
import { get } from "lodash";
import { PhoneInput } from "./PhoneInput";

export type FormPhoneInputProps = Partial<TextFieldProps> & {
  name: string;
};

export const FormPhoneInput: FC<FormPhoneInputProps> = props => {
  const { name, error: comingError, helperText: comingHelperText, disabled, ...rest } = props;

  const {
    control,
    isFormDisabled,
    formState: { errors },
  } = useCustomFormContext();

  const error: string = get(errors, name + ".message", "");

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <PhoneInput
          {...rest}
          {...field}
          error={!!error || comingError}
          helperText={error || comingHelperText}
          disabled={isFormDisabled || disabled}
        />
      )}
    />
  );
};
