import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomTable, FormCard, Grid, useDataGridState, useToasts } from "@toolkit/ui";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { variantSubRoute } from "shared/constants/routes/products";
import { useProductVariantDeleteMutation, useProductVariantsQuery } from "../../../../../gql";
import { VariantColumns } from "../../Columns/VariantColumns";
import { VariantInterface } from "../type";

const VariantAttributes: FC<VariantInterface> = ({ defaultProductData, isLoading }) => {
  const { t } = useTranslation("admin");
  const { addToast } = useToasts();
  const [productVariantDelete, { loading: loadingDelete }] = useProductVariantDeleteMutation({
    onCompleted: data => {
      const errors = data?.productVariantDelete?.productErrors;
      if (errors) {
        if (errors?.length === 0) {
          refetch();
          addToast(t("Product Variant Deleted Successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } else if (errors?.length > 0) {
          addToast(t(formatMessageErrors(errors)), {
            appearance: "error",
            autoDismiss: true,
          });
          console.error("<< Product Variant Image Assign >>", formatMessageErrors(errors));
        }
      } else {
        addToast(t("Product Variant Deleted Successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
        refetch();
      }
    },
    onError: () => {
      addToast(t("Error Product Variant Deleted"), {
        appearance: "error",
        autoDismiss: true,
      });
    },
  });

  const {
    data,
    isLoading: isLoadingVariants,
    pageSize,
    pageInfo,
    totalCount,
    refetch,
    handleGoToNext: handleGotoNext,
    handleSortData: handleSortData,
    doChangePageSize: handlePageSizeChange,
    handleGoToPrevious: handleGoToPrevious,
  } = useDataGridState({
    useCustomQuery: useProductVariantsQuery,
    input: {
      first: 10,
      filter: {
        product: defaultProductData?.id,
      },
    },
    columns: VariantColumns(t) || [],
    dataAccessor: "productVariants",
  });

  const variantAttributes = data?.productVariants?.edges?.map(variant => variant.node) || ([] as any[]);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(!open);
  };
  const handleDeleteRow = row => {
    productVariantDelete({
      variables: {
        id: row?.id,
      },
    });
  };
  const navigate = useNavigate();

  const handleEdit = row => {
    navigate(`${variantSubRoute}/${row.id}`);
  };

  return (
    <React.Fragment>
      {defaultProductData?.variants.length !== 0 && (
        <Grid container>
          <CustomDialog title={t("Delete Variant Product")} onCloseModal={handleClickOpen} onConfirm={handleDeleteRow} open={open} />
          <Grid item xs={12}>
            <FormCard title={t("Variant")} doYouHaveData={true} loading={isLoading || loadingDelete}>
              <CustomTable
                data={variantAttributes}
                totalCount={totalCount!}
                columns={VariantColumns(t) || []}
                isLoading={isLoadingVariants}
                isEditVisible
                isDeleteVisible
                pageSize={pageSize}
                hasAddNew
                pageIndex={1}
                pagesCount={1}
                hasNextPage={pageInfo?.hasNextPage}
                hasPreviousPage={pageInfo?.hasPreviousPage}
                onSortColumn={handleSortData}
                onGoToNext={handleGotoNext}
                onGoToPrevious={handleGoToPrevious}
                onPageSizeChange={handlePageSizeChange}
                onEditRow={handleEdit}
                onDeleteRow={handleDeleteRow}
              />
            </FormCard>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};
export default VariantAttributes;
