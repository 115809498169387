import { Autocomplete, Button, Dialog, TextField } from "@mui/material";
import { useTranslation } from "@toolkit/i18n";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FilterIcon } from "../../icons";
import { ExpandMoreIcon } from "../../mui";
import { CustomMuiDialogActions, CustomMuiDialogContent, CustomMuiDialogTitle } from "../CustomMuiDialog";
import { Filter } from "../Filter";
import { useStaticFilterDialogHooks } from "./StaticFilterDialog.hooks";
import { StyledIconButton } from "./StaticFilterDialog.style";
import { StaticFilterDialogProps } from "./StaticFilterDialog.types";

export const FilterDialog = <X extends object>(props: StaticFilterDialogProps<X>) => {
  const { t } = useTranslation();
  const { handleSubmit, reset } = useFormContext();
  const {
    visibleFilters,
    isDynamicFilters,
    filteringItems,
    filterFields,
    isOpen,
    handleToggle,
    handleSubmitClicked,
    handleDynamicFiltersSelectChanged,
    handleFormReset,
    handleClickClosed,
  } = useStaticFilterDialogHooks(props);

  useEffect(() => {
    reset({ ...props.filters });
  }, [JSON.stringify(props.filters)]);

  return (
    <>
      <StyledIconButton color='primary' size='medium' onClick={handleToggle} id='filterDialogButton' disableFocusRipple>
        <FilterIcon />
      </StyledIconButton>
      <Dialog
        fullWidth
        maxWidth='md'
        open={isOpen}
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <form onSubmit={handleSubmit(handleSubmitClicked)}>
          <CustomMuiDialogTitle title={t("Filter")} onClose={handleClickClosed} />
          <CustomMuiDialogContent>
            {isDynamicFilters && (
              <Autocomplete
                defaultValue={visibleFilters}
                popupIcon={<ExpandMoreIcon />}
                onChange={handleDynamicFiltersSelectChanged}
                options={filterFields}
                multiple
                getOptionLabel={o => o.key}
                sx={{ marginBottom: "15px" }}
                noOptionsText={t("No Options")}
                renderInput={params => <TextField hiddenLabel {...params} placeholder={t("Chose a filter field")} />}
              />
            )}
            <Filter fields={filteringItems} />
          </CustomMuiDialogContent>

          <CustomMuiDialogActions>
            <Button type='submit'>{t("Submit")}</Button>
            <Button variant='outlined' onClick={handleFormReset}>
              {t("Reset")}
            </Button>
          </CustomMuiDialogActions>
        </form>
      </Dialog>
    </>
  );
};
