import { startCase, toLower } from "lodash";

export * from "./Permissions";
export * from "./translation";

export function uuidv4() {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

export function ellipseName(name, maxLength = 20) {
  if (name?.length > maxLength) {
    return name?.slice(0, maxLength - 3) + "...";
  } else {
    return name;
  }
}

export const convertToTitleCase = (inputString: string) => {
  const lowerCaseString = toLower(inputString);
  return startCase(lowerCaseString);
};

export const replacePathParams = (path: string, params: { [key: string]: string }, prefix = ":") => {
  let newPath = path;

  Object.entries(params).forEach(([key, value]) => {
    newPath = newPath.replace(prefix + key, value || "");
  });

  return newPath;
};
