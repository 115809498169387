import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { DecisionActionFieldChronicTemplateCodesAutocomplete, DecisionActionFieldRiskTemplateCodesAutocomplete, DecisionActionFieldTaskDefinitionsAutocomplete, } from "@health/autocompletes";
import { DecisionActionFieldListCode } from "../../../Decisions/types/types";
export const DecisionActionFieldListForm = props => {
    const { name, display, code } = props;
    const { t } = useTranslation("domains");
    if (code === DecisionActionFieldListCode.RiskTemplateCodes) {
        return _jsx(DecisionActionFieldRiskTemplateCodesAutocomplete, { name: name, label: t(display), placeholder: t(display) });
    }
    else if (code === DecisionActionFieldListCode.ChronicTemplateCodes) {
        return _jsx(DecisionActionFieldChronicTemplateCodesAutocomplete, { name: name, label: t(display), placeholder: t(display) });
    }
    else if (code === DecisionActionFieldListCode.TaskDefinitionCode) {
        return _jsx(DecisionActionFieldTaskDefinitionsAutocomplete, { name: name, label: t(display), placeholder: t(display) });
    }
    else {
        return _jsx(_Fragment, { children: t("Not Supported Field") });
    }
};
