/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type ProductTypeFragmentFragment = { __typename?: 'ProductType', id: string, name: string, slug: string, productAttributes?: Array<{ __typename?: 'Attribute', name?: string | null, id: string } | null> | null, variantAttributes?: Array<{ __typename?: 'Attribute', name?: string | null, id: string } | null> | null };

export const ProductTypeFragmentFragmentDoc = gql`
    fragment ProductTypeFragment on ProductType {
  id
  name
  slug
  productAttributes {
    name
    id
  }
  variantAttributes {
    name
    id
  }
}
    `;