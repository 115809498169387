import { i18n, useTranslation } from "@toolkit/i18n";
import { CustomDialog, Grid, ShowButton, TextField, Typography } from "@toolkit/ui";
import React, { FC, useState } from "react";

export const AddressInformation: FC<{ address }> = ({ address }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("admin");
  const currentDir = i18n.dir(i18n.language);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <React.Fragment>
      <ShowButton onClick={handleOpen} />
      {isOpen && (
        <CustomDialog open={isOpen} onCloseModal={handleClose} title={t("Address Information")}>
          <>
            {!address ? (
              <Typography> {t("There is No Address Information")}</Typography>
            ) : (
              <Grid container spacing={2}>
                {address?.city && (
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      dir={currentDir}
                      fullWidth
                      placeholder={t("City")}
                      value={t(address?.city?.name)}
                      label={t("City")}
                    />
                  </Grid>
                )}
                {address?.area && (
                  <Grid item xs={6}>
                    <TextField disabled dir={currentDir} fullWidth placeholder={t("Area")} value={t(address?.area)} label={t("Area")} />
                  </Grid>
                )}
                {address?.streetAddress1 && (
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      fullWidth
                      dir={currentDir}
                      placeholder={t("Street Address 1")}
                      value={t(address?.streetAddress1)}
                      label={t("Street Address 1")}
                    />
                  </Grid>
                )}
                {address?.streetAddress2 && (
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      fullWidth
                      dir={currentDir}
                      placeholder={t("Street Address 2")}
                      value={t(address?.streetAddress2)}
                      label={t("Street Address 2")}
                    />
                  </Grid>
                )}
                {address?.buildingNumber && (
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      fullWidth
                      dir={currentDir}
                      placeholder={t("Building Number")}
                      value={t(address?.buildingNumber)}
                      label={t("Building Number")}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </>
        </CustomDialog>
      )}
    </React.Fragment>
  );
};
