import { Toolbar } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useTranslation } from "@toolkit/i18n";
import { FC, default as React, memo } from "react";
import Breadcrumbs from "../Breadcrumb/Breadcrumbs.component";
import { LanguageButton } from "../LanguageButton";
import { NetworkStatus } from "../NetworkStatus";
import { useAdminHeaderStyles } from "./AdminHeader.styles";
import { AdminHeaderProps } from "./AdminHeader.types";
import { leftIcons, rightIcons } from "./routes";

const AdminHeader: FC<AdminHeaderProps> = props => {
  const { position = "fixed", leftItems = leftIcons, rightItems = rightIcons, title, isOpen, onToggle, children } = props;
  const { i18n } = useTranslation();
  const currentDir = i18n.dir(i18n.language);

  const { classes, theme } = useAdminHeaderStyles({ isOpen: !!isOpen });

  const handleToggleDrawer = () => {
    onToggle && onToggle(!isOpen);
  };
  return (
    <>
      <NetworkStatus isOpen={!isOpen} />
      <MuiAppBar elevation={0} className={classes.appMenu} position={position}>
        <Toolbar
          sx={{
            height: "100%",
          }}
          disableGutters={false}
          variant='regular'
        >
          {!!leftItems?.length &&
            leftItems?.map(item => {
              const { id, icon, onClick: onPressItem, buttonColor } = item || {};

              const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
                onPressItem ? onPressItem(event, id) : handleToggleDrawer();
              };

              return (
                <>
                  <IconButton
                    key={id}
                    color={buttonColor || "primary"}
                    onClick={e => handleClick(e, id)}
                    edge='start'
                    className={classes.leftIcon}
                    sx={{
                      transform: currentDir === "rtl" ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  >
                    {icon}
                  </IconButton>
                </>
              );
            })}
          <Typography
            variant='h6'
            component='div'
            sx={{
              flexGrow: 1,
              display: "none",
              margin: "0 10px",
              [theme.breakpoints.up("sm")]: {
                display: "block",
              },
            }}
          >
            <Typography className={classes.title}>{title}</Typography>
            <Breadcrumbs />
          </Typography>
          {children}
          {!!rightItems?.length &&
            rightItems?.map(item => {
              const { id, icon, count, onClick: onPressItem, renderer, buttonColor } = item || {};

              const handleClickItem = (event: React.MouseEvent<HTMLElement>, id: string) => {
                onPressItem && onPressItem(event, id);
              };

              return renderer ? (
                renderer
              ) : (
                <IconButton
                  color={buttonColor || "primary"}
                  className={classes.iconButton}
                  key={id}
                  onClick={e => handleClickItem(e, id)}
                  edge='start'
                >
                  {count ? (
                    <Badge badgeContent={count} color='error'>
                      {icon}
                    </Badge>
                  ) : (
                    icon
                  )}
                </IconButton>
              );
            })}
          <LanguageButton />
        </Toolbar>
      </MuiAppBar>
    </>
  );
};

export default memo(AdminHeader);
