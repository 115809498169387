import { z } from "zod";
export const MedicalFormClassificationUpsertFormSchema = z.object({
    name: z.string().min(1).max(255),
    rangeFrom: z.coerce.number().positive(),
    rangeTo: z.coerce.number().positive(),
});
export const medicalFormClassificationUpsertFormDefaultValues = {
    name: "",
    rangeFrom: undefined,
    rangeTo: undefined,
};
