import { makeStyles } from "@toolkit/ui";

export const useGeneralInfoStyles = makeStyles()(theme => ({
  generalInfo: {
    height: 81,
  },
  IconButtonStyle: {
    width: "20px",
    color: theme.palette.warning.main,
  },
  approveStatusStyle: {
    display: "flex",
    overflow: "auto",
  },
  imgStyle: {
    width: "348px",
    height: "175px",
    borderRadius: "dash",
    margin: 10,
    cursor: "pointer",
  },
  imageApproveStatus: {
    borderRadius: 8,
    margin: 10,
    width: "50%",
    boxShadow: theme.mixins.shadows.sm,
  },
  all: {
    width: "100%",
    height: "100%",
    display: "grid",
  },
  images: { display: "flex", overflow: "auto" },
  imgMargin: { margin: 30 },
  chooseImage: { fontSize: theme.mixins.fonts.fontSize.md },
}));
