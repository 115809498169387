import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const vendorProductVariantAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
    nameAr: z.string().nullable().optional(),
}));
export const VendorProductVariantAutocompleteSchemas = {
    vendorProductVariantAutocomplete,
    vendorProductVariantAutocompleteOptional: vendorProductVariantAutocomplete.nullable().optional(),
};
