import { Box, Button, Typography } from "@mui/material";
import { toPascalCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { set } from "lodash";
import React, { FC } from "react";
import { TruncateTypography } from "../TruncateTypography";
import { useActiveFilterStyles } from "./ActiveFilters.styles";
import { ActiveFiltersProps } from "./ActiveFilters.types";

export const ActiveFilters: FC<ActiveFiltersProps> = ({ ignoreTranslations = false, ...props }) => {
  const filters = {};
  const handleOpenFilter = () => {
    props.onOpenFilters?.();
  };

  Object.keys(props?.activeFilters).forEach(k => {
    if (props?.activeFilters[k]) {
      set(filters, k, props?.activeFilters[k]);
    }
  });
  const { t } = useTranslation();
  const counter = Object.keys(filters)?.length || 0;
  const { classes, theme } = useActiveFilterStyles();
  if (!filters) return null;

  const maybeTranslate = (value: string) => {
    if (ignoreTranslations) {
      return value;
    }
    return t(value);
  };
  return (
    <Box>
      {filters && (
        <Box className={classes.filters} component='div'>
          {Object.entries(filters)
            .filter(([, value]) => Boolean(value))
            ?.slice(0, 3)
            ?.map(([filter, value]) => {
              return value ? (
                <Typography key={`active-filter-${filter}`} className={classes.filterItem} component='div'>
                  <Typography fontSize={theme.mixins.fonts.fontSize.xxs} className={classes.itemStyle}>
                    {maybeTranslate(toPascalCase(filter?.replace("Icontains", " ")).trim())}
                  </Typography>
                  <Typography
                    fontSize={theme.mixins.fonts.fontSize.sm}
                    fontFamily={theme.mixins.fonts.fontFamily.medium}
                    className={classes.itemStyle}
                  >
                    <TruncateTypography text={maybeTranslate(value?.toString())} />
                  </Typography>
                </Typography>
              ) : null;
            })}
          <>
            {counter > 3 && (
              <Button sx={{ fontFamily: theme.mixins.fonts.fontFamily.medium }} variant='text' onClick={handleOpenFilter}>
                +{counter - 3} {t("Items")}
              </Button>
            )}
          </>
        </Box>
      )}
    </Box>
  );
};
