import { InsurancePolicy } from "@health/queries/types";
import { i18n, useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomToggleButton, FormControlLabel, Grid, ShowButton, TextField, Typography } from "@toolkit/ui";
import React, { FC } from "react";
import useToggle from "shared/hooks/useToggle";

export const OutpatientPolicyInformation: FC<{ outpatientPolicy: InsurancePolicy["outpatientPolicy"] }> = ({ outpatientPolicy }) => {
  const { open, handleToggle } = useToggle();
  const { t } = useTranslation("admin");

  const currentDir = i18n.dir(i18n.language);

  return (
    <React.Fragment>
      <ShowButton onClick={handleToggle} />
      {open && (
        <CustomDialog open={open} onCloseModal={handleToggle} title={t("Outpatient Policy")}>
          <>
            {!outpatientPolicy ? (
              <Typography> {t("There is No Outpatient Policy Information")}</Typography>
            ) : (
              <Grid container spacing={2}>
                {outpatientPolicy?.cons && (
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      dir={currentDir}
                      fullWidth
                      placeholder={t("Considerations")}
                      value={t(outpatientPolicy?.cons)}
                      label={t("Considerations")}
                    />
                  </Grid>
                )}
                {outpatientPolicy?.dental && (
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      dir={currentDir}
                      fullWidth
                      placeholder={t("Dental Services")}
                      value={t(outpatientPolicy?.dental)}
                      label={t("Dental Services")}
                    />
                  </Grid>
                )}
                {outpatientPolicy?.pharm && (
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      fullWidth
                      dir={currentDir}
                      placeholder={t("Pharmaceutical Coverage")}
                      value={outpatientPolicy?.pharm}
                      label={t("Pharmaceutical Coverage")}
                    />
                  </Grid>
                )}
                {outpatientPolicy?.optical && (
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={<CustomToggleButton disabled checked={outpatientPolicy?.optical} />}
                      label={t("Optical Services")}
                    />
                  </Grid>
                )}
                {outpatientPolicy?.outpatient && (
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={<CustomToggleButton disabled checked={outpatientPolicy?.outpatient} />}
                      label={t("Outpatient Services")}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </>
        </CustomDialog>
      )}
    </React.Fragment>
  );
};
