import { CircularProgress, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "@toolkit/i18n";
import React, { FC, Fragment } from "react";
import { makeStyles } from "tss-react/mui";
import { EmptyCard } from "../Cards/EmptyCard";
import { InfiniteScrollContainer } from "../InfiniteScroll";
import { OrdersEventsContainerProps } from "./types";

export const useOrdersEventsContainerStyle = makeStyles()(() => ({
  infinite: {
    overflow: "hidden !important",
  },
}));

export const OrdersEventsContainer: FC<OrdersEventsContainerProps> = ({
  loading,
  pageInfo,
  ordersData,
  fetchMoreData: next,
  renderItem,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {!ordersData?.length ? (
            <Box height='100%' display='flex' alignItems='center'>
              <EmptyCard title={t("There are no orders")} message={t("There are no orders related to this e-prescription yet")} />
            </Box>
          ) : (
            <InfiniteScrollContainer
              dataLength={Number(ordersData?.length) || 0}
              next={next}
              hasMore={Boolean(pageInfo?.hasNextPage)}
              loader={
                <Box display='flex' justifyContent='center' color={theme.palette.primary.main} m={1}>
                  <CircularProgress size={50} color='inherit' />
                </Box>
              }
              // TODO Fix
              // classes={{
              //   infinite: classes.infinite,
              // }}
              scrollableTarget='scrollableDiv'
            >
              {ordersData?.map((item, index) => {
                return (
                  <Fragment key={String(item?.id) + index}>
                    <Typography fontSize={theme.mixins.fonts.fontSize.sm}>
                      {t("Order No") + ": - "}
                      {item?.number}
                    </Typography>

                    {renderItem({ item })}
                  </Fragment>
                );
              })}
            </InfiniteScrollContainer>
          )}
        </>
      )}
    </>
  );
};
