import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const decisionConditionAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
    nameAr: z.string().nullable().optional(),
}));
export const DecisionConditionAutocompleteSchemas = {
    decisionConditionAutocomplete,
    decisionConditionAutocompleteOptional: decisionConditionAutocomplete.nullable().optional(),
};
