/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vendor } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Autocomplete, CircularProgress, InfiniteScrollComponent, TextField } from "@toolkit/ui";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const VendorForm: FC<any> = ({ vendorsData, loading, fetchMoreVendors, defaultValue }) => {
  const { t, i18n } = useTranslation("admin");
  const { control, setValue, register, clearErrors, watch } = useFormContext();
  const currentDir = i18n.dir();
  const facilities = vendorsData?.vendors?.edges.map((vendor: any) => vendor?.node);
  const pageInfo = vendorsData?.vendors?.pageInfo;
  const facilitiesDefaultValues = defaultValue?.facilities?.edges?.map(vendor => vendor?.node);
  const fetchMoreData = () => {
    if (pageInfo?.hasNextPage) {
      fetchMoreVendors({
        variables: {
          first: 10,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            ...fetchMoreResult,
            vendors: {
              ...fetchMoreResult.vendors,
              edges: [...prev.vendors.edges, ...fetchMoreResult.vendors.edges],
            },
          });
        },
      });
    }
  };
  const onChangeFacility = (event: any, value: Array<Vendor>) => {
    setValue(
      "facilities",
      value.map((item: Vendor) => item)
    );
  };

  const filterFacility = watch("facilities");
  const defaultValuFacility = facilities?.filter((item, index) => filterFacility?.[index]?.id != item?.id);
  return (
    <>
      <Controller
        defaultValue={defaultValue ? facilitiesDefaultValues : []}
        name='facilities'
        control={control}
        render={({ ...props }) => (
          <Autocomplete
            {...props}
            dir={currentDir}
            size={"small"}
            multiple
            filterSelectedOptions={true}
            selectOnFocus={true}
            // eslint-disable-next-line react/jsx-handler-names
            onChange={onChangeFacility}
            getOptionLabel={(option: any) => {
              if (currentDir === "rtl") {
                return option?.arabicName;
              } else {
                return option?.englishName;
              }
            }}
            options={(defaultValue ? defaultValuFacility : facilities) || []}
            defaultValue={defaultValue ? facilitiesDefaultValues : []}
            ListboxComponent={listBoxProps => {
              return (
                <InfiniteScrollComponent
                  {...listBoxProps}
                  options={defaultValue ? defaultValuFacility || [] : facilities || []}
                  fetchMoreData={fetchMoreData}
                  hasMore={Boolean(pageInfo?.hasNextPage)}
                />
              );
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label={t("facilities", "Facilities")}
                placeholder={t("SuperAdminForm:selectFacilities", "Select Facilities")}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color='inherit' size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        )}
      />
    </>
  );
};
