/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductImageCreateMutationVariables = Types.Exact<{
  input: Types.ProductImageCreateInput;
}>;


export type ProductImageCreateMutation = { __typename?: 'Mutation', productImageCreate?: { __typename?: 'ProductImageCreate', image?: { __typename?: 'ProductImage', id: string, url: string } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const ProductImageCreateDocument = gql`
    mutation productImageCreate($input: ProductImageCreateInput!) {
  productImageCreate(input: $input) {
    image {
      id
      url
    }
    productErrors {
      field
      message
    }
  }
}
    `;
export type ProductImageCreateMutationFn = Apollo.MutationFunction<ProductImageCreateMutation, ProductImageCreateMutationVariables>;

/**
 * __useProductImageCreateMutation__
 *
 * To run a mutation, you first call `useProductImageCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductImageCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productImageCreateMutation, { data, loading, error }] = useProductImageCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductImageCreateMutation(baseOptions?: Apollo.MutationHookOptions<ProductImageCreateMutation, ProductImageCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductImageCreateMutation, ProductImageCreateMutationVariables>(ProductImageCreateDocument, options);
      }
export type ProductImageCreateMutationHookResult = ReturnType<typeof useProductImageCreateMutation>;
export type ProductImageCreateMutationResult = Apollo.MutationResult<ProductImageCreateMutation>;
export type ProductImageCreateMutationOptions = Apollo.BaseMutationOptions<ProductImageCreateMutation, ProductImageCreateMutationVariables>;