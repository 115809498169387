import { i18n, useTranslation } from "@toolkit/i18n";
import { useState } from "react";

export const useVendorsListHook = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("admin");
  const currentDir = i18n.dir(i18n.language);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return {
    t,
    isOpen,
    currentDir,
    handleOpen,
    handleClose,
  };
};
