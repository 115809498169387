/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type CategoryFragmentFragment = { __typename?: 'Category', id: string, name: string, level: number, description: string, slug: string, parent?: { __typename?: 'Category', id: string } | null, children?: { __typename?: 'CategoryCountableConnection', totalCount?: number | null } | null, backgroundImage?: { __typename?: 'Image', alt?: string | null, url?: string | null } | null };

export const CategoryFragmentFragmentDoc = gql`
    fragment CategoryFragment on Category {
  id
  name
  level
  description
  slug
  parent {
    id
  }
  children {
    totalCount
  }
  backgroundImage {
    alt
    url
  }
}
    `;