import Box from "@mui/material/Box";
import React from "react";
import { ToastContainerProps } from "react-toast-notifications";

const placements = {
  "top-left": { top: 70, left: 0 },
  "top-center": { top: 70, left: "50%", transform: "translateX(-50%)" },
  "top-right": { top: 70, right: 0 },
  "bottom-left": { bottom: 0, left: 0 },
  "bottom-center": { bottom: 0, left: "50%", transform: "translateX(-50%)" },
  "bottom-right": { bottom: 0, right: 0 },
};

export const CustomToastContainer = ({ hasToasts, placement, ...props }: ToastContainerProps) => (
  <Box
    component='div'
    className='react-toast-notifications__container'
    sx={{
      boxSizing: "border-box",
      maxHeight: "100%",
      maxWidth: "100%",
      overflow: "hidden",
      padding: "10px",
      pointerEvents: hasToasts ? null : "none",
      position: "fixed",
      zIndex: 1301,
      ...placements[placement],
    }}
    {...props}
  />
);
