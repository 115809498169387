import { jsx as _jsx } from "react/jsx-runtime";
import { CustomTable, GridColumnInfoModal } from "@toolkit/ui";
import { useProductsColumns } from "./useGetProductsTableColumns";
export const ProductsModal = ({ title, lines }) => {
    return (_jsx(GridColumnInfoModal, Object.assign({ title: title }, { children: _jsx(CustomTable, { data: lines, columns: useProductsColumns(), pageSize: 4, pageIndex: 0, pagesCount: 4, TableContainerProps: {
                sx: {
                    height: 350,
                },
            }, hasFooter: false }) })));
};
