/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { AttributeMutationFragmentFragmentDoc } from './attributeFragment';
import { AttributeErrorsFragmentFragmentDoc } from './attributeErrorsFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AttributeUpdateMutationVariables = Types.Exact<{
  input: Types.AttributeUpdateInput;
  id: Types.Scalars['ID'];
}>;


export type AttributeUpdateMutation = { __typename?: 'Mutation', attributeUpdate?: { __typename?: 'AttributeUpdate', attribute?: { __typename?: 'Attribute', name?: string | null, id: string, values?: Array<{ __typename?: 'AttributeValue', name?: string | null, id: string, slug?: string | null } | null> | null } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const AttributeUpdateDocument = gql`
    mutation attributeUpdate($input: AttributeUpdateInput!, $id: ID!) {
  attributeUpdate(input: $input, id: $id) {
    attribute {
      name
      id
      ...AttributeMutationFragment
    }
    productErrors {
      ...AttributeErrorsFragment
    }
  }
}
    ${AttributeMutationFragmentFragmentDoc}
${AttributeErrorsFragmentFragmentDoc}`;
export type AttributeUpdateMutationFn = Apollo.MutationFunction<AttributeUpdateMutation, AttributeUpdateMutationVariables>;

/**
 * __useAttributeUpdateMutation__
 *
 * To run a mutation, you first call `useAttributeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttributeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attributeUpdateMutation, { data, loading, error }] = useAttributeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttributeUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AttributeUpdateMutation, AttributeUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttributeUpdateMutation, AttributeUpdateMutationVariables>(AttributeUpdateDocument, options);
      }
export type AttributeUpdateMutationHookResult = ReturnType<typeof useAttributeUpdateMutation>;
export type AttributeUpdateMutationResult = Apollo.MutationResult<AttributeUpdateMutation>;
export type AttributeUpdateMutationOptions = Apollo.BaseMutationOptions<AttributeUpdateMutation, AttributeUpdateMutationVariables>;