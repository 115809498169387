import { combineErrors, formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Grid, SmartAutocompleteController, TextField, ToggleButtonController } from "@toolkit/ui";
import { useInsuranceNetworksQuery } from "pages/HealthInsurance/gql";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { InsurancePolicyInformationProps } from "../types";

export const InsurancePolicyInformation: FC<InsurancePolicyInformationProps> = ({ defaultValues, errors }) => {
  const { t } = useTranslation("admin");
  const {
    register,
    control,
    setValue,
    formState: { errors: formErrors },
  } = useFormContext();
  const combinedErrors = combineErrors(formErrors, errors);

  const handleChangeInsuranceNetwork = option => {
    setValue("insuranceNetworkId", option.id);
    return option.id;
  };

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          label={t("Name")}
          fullWidth
          placeholder={t("Name")}
          defaultValue={defaultValues?.name}
          helperText={t(combinedErrors?.policyName?.message)}
          error={Boolean(combinedErrors?.policyName?.message)}
          {...register("policyName")}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          label={t("Number")}
          fullWidth
          placeholder={t("Number")}
          disabled={!!defaultValues?.id}
          defaultValue={defaultValues?.number}
          helperText={t(combinedErrors?.policyNumber?.message)}
          error={Boolean(combinedErrors?.policyNumber?.message)}
          {...register("policyNumber")}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <SmartAutocompleteController
          onChange={handleChangeInsuranceNetwork}
          getOptionLabel={option => option?.name}
          filters={{ isActive: true }}
          useAutocompleteControllerQuery={useInsuranceNetworksQuery}
          dataAccessor='insuranceNetworks'
          ControllerProps={{
            control,
            name: "insuranceNetwork",
            defaultValue: defaultValues?.network,
          }}
          TextFieldProps={{
            label: t("Insurance Network"),
            helperText: t(combinedErrors?.insuranceNetworkId?.message),
            error: Boolean(combinedErrors?.insuranceNetworkId?.message),
          }}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          label={t("Pharmaceutical Coverage")}
          fullWidth
          placeholder={t("Pharmaceutical Coverage")}
          defaultValue={defaultValues?.outpatientPolicy?.pharm}
          helperText={t(combinedErrors?.insuranceOutpatientPolicyInput?.pharm?.message)}
          error={Boolean(combinedErrors?.insuranceOutpatientPolicyInput?.pharm?.message)}
          {...register("insuranceOutpatientPolicyInput.pharm")}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          label={t("Dental Services")}
          fullWidth
          placeholder={t("Dental Services")}
          defaultValue={defaultValues?.outpatientPolicy?.dental}
          helperText={t(combinedErrors?.insuranceOutpatientPolicyInput?.dental?.message)}
          error={Boolean(combinedErrors?.insuranceOutpatientPolicyInput?.dental?.message)}
          {...register("insuranceOutpatientPolicyInput.dental")}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints} xs={12} md={12} lg={12}>
        <TextField
          label={t("Considerations")}
          fullWidth
          multiline
          rows={2}
          placeholder={t("Considerations")}
          defaultValue={defaultValues?.outpatientPolicy?.cons}
          helperText={t(combinedErrors?.insuranceOutpatientPolicyInput?.cons?.message)}
          error={Boolean(combinedErrors?.insuranceOutpatientPolicyInput?.cons?.message)}
          {...register("insuranceOutpatientPolicyInput.cons")}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <Box display='flex' alignItems='center' height='100%'>
          <ToggleButtonController
            {...register("insuranceOutpatientPolicyInput.outpatient")}
            control={control}
            defaultValue={defaultValues?.outpatientPolicy?.outpatient || false}
            label={t("Outpatient Services")}
          />
        </Box>
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <Box display='flex' alignItems='center' height='100%'>
          <ToggleButtonController
            {...register("insuranceOutpatientPolicyInput.optical")}
            control={control}
            defaultValue={defaultValues?.outpatientPolicy?.optical || false}
            label={t("Optical Services")}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
