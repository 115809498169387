import React, { PropsWithChildren, FC } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteScrollStyles } from "./styles";

type InfiniteScrollContainerProps = {
  dataLength: number;
  next: () => void;
  hasMore: boolean;
  loader: JSX.Element;
  height?: number;
  scrollableTarget?: string;
};

const InfiniteScrollContainer: FC<PropsWithChildren<InfiniteScrollContainerProps>> = props => {
  const { children, scrollableTarget } = props;
  const { classes } = useInfiniteScrollStyles();
  return (
    <InfiniteScroll scrollableTarget={scrollableTarget} className={classes.infinite} {...props}>
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollContainer;

InfiniteScrollContainer.defaultProps = {
  height: undefined,
  dataLength: 0,
  scrollableTarget: undefined,
};
