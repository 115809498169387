/* eslint-disable max-lines */
import { SvgIconProps } from "@mui/material";
import React, { FC } from "react";

export const EmptyDataIcon: FC<SvgIconProps> = props => {
  const { width, height, ...rest } = props;

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width || "140.68"} height={height || "139.204"} viewBox='0 0 140.68 139.204' {...rest}>
      <g id='empty_table' data-name='empty table' transform='translate(-74.716 -70.916)'>
        <g id='Group_15049' data-name='Group 15049' transform='translate(74.716 70.916)'>
          <g id='Group_15047' data-name='Group 15047' transform='translate(0 0)'>
            <g id='Template'>
              <path
                id='Path_22329'
                data-name='Path 22329'
                d='M236.9,168.1v95.623a6.222,6.222,0,0,1-6.2,6.234H132.334a6.229,6.229,0,0,1-6.234-6.234h0V168.1Z'
                transform='translate(-113.566 -138.249)'
                fill='#fff'
              />
              <path
                id='Path_22330'
                data-name='Path 22330'
                d='M415.837,133.2v16.822A21.56,21.56,0,0,1,393.176,127H409.6A6.2,6.2,0,0,1,415.837,133.2Z'
                transform='translate(-292.507 -110.706)'
                fill='#dfe6f7'
              />
              <path
                id='Path_22331'
                data-name='Path 22331'
                d='M230.695,127H132.334a6.25,6.25,0,0,0-6.234,6.234v7.323H236.9v-7.323a6.2,6.2,0,0,0-6.2-6.234Z'
                transform='translate(-113.566 -110.706)'
                fill='#2b3078'
              />
              <circle
                id='Ellipse_3320'
                data-name='Ellipse 3320'
                cx='2.375'
                cy='2.375'
                r='2.375'
                transform='translate(26.388 20.681)'
                fill='#f5f5f5'
              />
              <circle
                id='Ellipse_3321'
                data-name='Ellipse 3321'
                cx='2.375'
                cy='2.375'
                r='2.375'
                transform='translate(18.109 20.681)'
                fill='#f5f5f5'
              />
              <g id='Group_15045' data-name='Group 15045' transform='translate(25.398 39.054)'>
                <rect
                  id='Rectangle_9801'
                  data-name='Rectangle 9801'
                  width='20.747'
                  height='7.092'
                  transform='translate(0.957 0.957)'
                  fill='#afbad8'
                />
                <rect
                  id='Rectangle_9802'
                  data-name='Rectangle 9802'
                  width='20.747'
                  height='7.092'
                  transform='translate(21.704 0.957)'
                  fill='#afbad8'
                />
                <rect
                  id='Rectangle_9803'
                  data-name='Rectangle 9803'
                  width='20.747'
                  height='7.092'
                  transform='translate(42.451 0.957)'
                  fill='#afbad8'
                />
                <rect
                  id='Rectangle_9804'
                  data-name='Rectangle 9804'
                  width='20.747'
                  height='7.092'
                  transform='translate(63.199 0.957)'
                  fill='#afbad8'
                />
                <g id='Group_15034' data-name='Group 15034'>
                  <path
                    id='Path_22332'
                    data-name='Path 22332'
                    d='M187.761,205H165.1v-9h22.661Zm-20.747-1.913h18.834v-5.179H167.013Z'
                    transform='translate(-165.1 -196)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22333'
                    data-name='Path 22333'
                    d='M250.661,205H228v-9h22.661Zm-20.747-1.913h18.834v-5.179H229.913Z'
                    transform='translate(-207.253 -196)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22334'
                    data-name='Path 22334'
                    d='M313.628,205H291v-9h22.628Zm-20.747-1.913h18.834v-5.179H292.88Z'
                    transform='translate(-249.472 -196)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22335'
                    data-name='Path 22335'
                    d='M376.461,205H353.8v-9h22.661Zm-20.747-1.913h18.834v-5.179H355.68Z'
                    transform='translate(-291.558 -196)'
                    fill='#525682'
                  />
                </g>
                <g id='Group_15035' data-name='Group 15035' transform='translate(0 7.059)'>
                  <path
                    id='Path_22336'
                    data-name='Path 22336'
                    d='M187.761,226.4H165.1v-9h22.661Zm-20.747-1.88h18.834v-5.179H167.013Z'
                    transform='translate(-165.1 -217.4)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22337'
                    data-name='Path 22337'
                    d='M250.661,226.4H228v-9h22.661Zm-20.747-1.88h18.834v-5.179H229.913Z'
                    transform='translate(-207.253 -217.4)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22338'
                    data-name='Path 22338'
                    d='M313.628,226.4H291v-9h22.628Zm-20.747-1.88h18.834v-5.179H292.88Z'
                    transform='translate(-249.472 -217.4)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22339'
                    data-name='Path 22339'
                    d='M376.461,226.4H353.8v-9h22.661Zm-20.747-1.88h18.834v-5.179H355.68Z'
                    transform='translate(-291.558 -217.4)'
                    fill='#525682'
                  />
                </g>
                <g id='Group_15036' data-name='Group 15036' transform='translate(0 14.183)'>
                  <path
                    id='Path_22340'
                    data-name='Path 22340'
                    d='M187.761,247.972H165.1V239h22.661Zm-20.747-1.913h18.834V240.88H167.013Z'
                    transform='translate(-165.1 -239)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22341'
                    data-name='Path 22341'
                    d='M250.661,247.972H228V239h22.661Zm-20.747-1.913h18.834V240.88H229.913Z'
                    transform='translate(-207.253 -239)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22342'
                    data-name='Path 22342'
                    d='M313.628,247.972H291V239h22.628Zm-20.747-1.913h18.834V240.88H292.88Z'
                    transform='translate(-249.472 -239)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22343'
                    data-name='Path 22343'
                    d='M376.461,247.972H353.8V239h22.661Zm-20.747-1.913h18.834V240.88H355.68Z'
                    transform='translate(-291.558 -239)'
                    fill='#525682'
                  />
                </g>
                <g id='Group_15037' data-name='Group 15037' transform='translate(0 21.242)'>
                  <path
                    id='Path_22344'
                    data-name='Path 22344'
                    d='M187.761,269.4H165.1v-9h22.661Zm-20.747-1.913h18.834v-5.179H167.013Z'
                    transform='translate(-165.1 -260.4)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22345'
                    data-name='Path 22345'
                    d='M250.661,269.4H228v-9h22.661Zm-20.747-1.913h18.834v-5.179H229.913Z'
                    transform='translate(-207.253 -260.4)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22346'
                    data-name='Path 22346'
                    d='M313.628,269.4H291v-9h22.628Zm-20.747-1.913h18.834v-5.179H292.88Z'
                    transform='translate(-249.472 -260.4)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22347'
                    data-name='Path 22347'
                    d='M376.461,269.4H353.8v-9h22.661Zm-20.747-1.913h18.834v-5.179H355.68Z'
                    transform='translate(-291.558 -260.4)'
                    fill='#525682'
                  />
                </g>
                <g id='Group_15038' data-name='Group 15038' transform='translate(0 28.334)'>
                  <path
                    id='Path_22348'
                    data-name='Path 22348'
                    d='M187.761,290.9H165.1v-9h22.661Zm-20.747-1.913h18.834v-5.179H167.013Z'
                    transform='translate(-165.1 -281.9)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22349'
                    data-name='Path 22349'
                    d='M250.661,290.9H228v-9h22.661Zm-20.747-1.913h18.834v-5.179H229.913Z'
                    transform='translate(-207.253 -281.9)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22350'
                    data-name='Path 22350'
                    d='M313.628,290.9H291v-9h22.628Zm-20.747-1.913h18.834v-5.179H292.88Z'
                    transform='translate(-249.472 -281.9)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22351'
                    data-name='Path 22351'
                    d='M376.461,290.9H353.8v-9h22.661Zm-20.747-1.913h18.834v-5.179H355.68Z'
                    transform='translate(-291.558 -281.9)'
                    fill='#525682'
                  />
                </g>
                <g id='Group_15039' data-name='Group 15039' transform='translate(0 35.426)'>
                  <path
                    id='Path_22352'
                    data-name='Path 22352'
                    d='M187.761,312.4H165.1v-9h22.661Zm-20.747-1.913h18.834v-5.179H167.013Z'
                    transform='translate(-165.1 -303.4)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22353'
                    data-name='Path 22353'
                    d='M250.661,312.4H228v-9h22.661Zm-20.747-1.913h18.834v-5.179H229.913Z'
                    transform='translate(-207.253 -303.4)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22354'
                    data-name='Path 22354'
                    d='M313.628,312.4H291v-9h22.628Zm-20.747-1.913h18.834v-5.179H292.88Z'
                    transform='translate(-249.472 -303.4)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22355'
                    data-name='Path 22355'
                    d='M376.461,312.4H353.8v-9h22.661Zm-20.747-1.913h18.834v-5.179H355.68Z'
                    transform='translate(-291.558 -303.4)'
                    fill='#525682'
                  />
                </g>
                <g id='Group_15040' data-name='Group 15040' transform='translate(0 42.484)'>
                  <path
                    id='Path_22356'
                    data-name='Path 22356'
                    d='M187.761,333.8H165.1v-9h22.661Zm-20.747-1.88h18.834v-5.179H167.013Z'
                    transform='translate(-165.1 -324.8)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22357'
                    data-name='Path 22357'
                    d='M250.661,333.8H228v-9h22.661Zm-20.747-1.88h18.834v-5.179H229.913Z'
                    transform='translate(-207.253 -324.8)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22358'
                    data-name='Path 22358'
                    d='M313.628,333.8H291v-9h22.628Zm-20.747-1.88h18.834v-5.179H292.88Z'
                    transform='translate(-249.472 -324.8)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22359'
                    data-name='Path 22359'
                    d='M376.461,333.8H353.8v-9h22.661Zm-20.747-1.88h18.834v-5.179H355.68Z'
                    transform='translate(-291.558 -324.8)'
                    fill='#525682'
                  />
                </g>
                <g id='Group_15041' data-name='Group 15041' transform='translate(0 49.576)'>
                  <path
                    id='Path_22360'
                    data-name='Path 22360'
                    d='M187.761,355.3H165.1v-9h22.661Zm-20.747-1.913h18.834v-5.179H167.013Z'
                    transform='translate(-165.1 -346.3)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22361'
                    data-name='Path 22361'
                    d='M250.661,355.3H228v-9h22.661Zm-20.747-1.913h18.834v-5.179H229.913Z'
                    transform='translate(-207.253 -346.3)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22362'
                    data-name='Path 22362'
                    d='M313.628,355.3H291v-9h22.628Zm-20.747-1.913h18.834v-5.179H292.88Z'
                    transform='translate(-249.472 -346.3)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22363'
                    data-name='Path 22363'
                    d='M376.461,355.3H353.8v-9h22.661Zm-20.747-1.913h18.834v-5.179H355.68Z'
                    transform='translate(-291.558 -346.3)'
                    fill='#525682'
                  />
                </g>
                <g id='Group_15042' data-name='Group 15042' transform='translate(0 56.668)'>
                  <path
                    id='Path_22364'
                    data-name='Path 22364'
                    d='M187.761,376.8H165.1v-9h22.661Zm-20.747-1.913h18.834v-5.179H167.013Z'
                    transform='translate(-165.1 -367.8)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22365'
                    data-name='Path 22365'
                    d='M250.661,376.8H228v-9h22.661Zm-20.747-1.913h18.834v-5.179H229.913Z'
                    transform='translate(-207.253 -367.8)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22366'
                    data-name='Path 22366'
                    d='M313.628,376.8H291v-9h22.628Zm-20.747-1.913h18.834v-5.179H292.88Z'
                    transform='translate(-249.472 -367.8)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22367'
                    data-name='Path 22367'
                    d='M376.461,376.8H353.8v-9h22.661Zm-20.747-1.913h18.834v-5.179H355.68Z'
                    transform='translate(-291.558 -367.8)'
                    fill='#525682'
                  />
                </g>
                <g id='Group_15043' data-name='Group 15043' transform='translate(0 63.76)'>
                  <path
                    id='Path_22368'
                    data-name='Path 22368'
                    d='M187.761,398.3H165.1v-9h22.661Zm-20.747-1.913h18.834v-5.179H167.013Z'
                    transform='translate(-165.1 -389.3)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22369'
                    data-name='Path 22369'
                    d='M250.661,398.3H228v-9h22.661Zm-20.747-1.913h18.834v-5.179H229.913Z'
                    transform='translate(-207.253 -389.3)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22370'
                    data-name='Path 22370'
                    d='M313.628,398.3H291v-9h22.628Zm-20.747-1.913h18.834v-5.179H292.88Z'
                    transform='translate(-249.472 -389.3)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22371'
                    data-name='Path 22371'
                    d='M376.461,398.3H353.8v-9h22.661Zm-20.747-1.913h18.834v-5.179H355.68Z'
                    transform='translate(-291.558 -389.3)'
                    fill='#525682'
                  />
                </g>
                <g id='Group_15044' data-name='Group 15044' transform='translate(0 70.851)'>
                  <path
                    id='Path_22372'
                    data-name='Path 22372'
                    d='M187.761,419.8H165.1v-9h22.661Zm-20.747-1.913h18.834v-5.179H167.013Z'
                    transform='translate(-165.1 -410.8)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22373'
                    data-name='Path 22373'
                    d='M250.661,419.8H228v-9h22.661Zm-20.747-1.913h18.834v-5.179H229.913Z'
                    transform='translate(-207.253 -410.8)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22374'
                    data-name='Path 22374'
                    d='M313.628,419.8H291v-9h22.628Zm-20.747-1.913h18.834v-5.179H292.88Z'
                    transform='translate(-249.472 -410.8)'
                    fill='#525682'
                  />
                  <path
                    id='Path_22375'
                    data-name='Path 22375'
                    d='M376.461,419.8H353.8v-9h22.661Zm-20.747-1.913h18.834v-5.179H355.68Z'
                    transform='translate(-291.558 -410.8)'
                    fill='#525682'
                  />
                </g>
              </g>
              <path
                id='Path_22376'
                data-name='Path 22376'
                d='M228.751,268.937H130.391a7.2,7.2,0,0,1-7.191-7.158V165.2H235.909v96.579A7.151,7.151,0,0,1,228.751,268.937ZM125.113,167.08v94.666a5.293,5.293,0,0,0,5.278,5.278h98.361a5.293,5.293,0,0,0,5.278-5.278V167.08Z'
                transform='translate(-111.622 -136.305)'
                fill='#525682'
              />
              <path
                id='Path_22377'
                data-name='Path 22377'
                d='M235.909,139.47H123.2v-8.279A7.208,7.208,0,0,1,130.391,124h98.361a7.207,7.207,0,0,1,7.191,7.191v8.279Zm-110.8-1.88H234v-6.366a5.293,5.293,0,0,0-5.278-5.278H130.391a5.293,5.293,0,0,0-5.278,5.278Z'
                transform='translate(-111.622 -108.695)'
                fill='#2b3078'
              />
              <circle
                id='Ellipse_3322'
                data-name='Ellipse 3322'
                cx='16.855'
                cy='16.855'
                r='16.855'
                transform='translate(105.32 0.957)'
                fill='#fff'
              />
              <path
                id='Path_22378'
                data-name='Path 22378'
                d='M422.312,113.224a17.812,17.812,0,1,1,17.812-17.812h0A17.864,17.864,0,0,1,422.312,113.224Zm0-33.743a15.915,15.915,0,1,0,15.9,15.932h0a15.935,15.935,0,0,0-15.9-15.932Z'
                transform='translate(-300.136 -77.6)'
                fill='#525682'
              />
              <path
                id='Path_22379'
                data-name='Path 22379'
                d='M138.66,89.315a3.766,3.766,0,0,1-3.76-3.793,3.849,3.849,0,0,1,.4-1.715h0a3.776,3.776,0,1,1,3.364,5.508ZM137.011,84.7a1.866,1.866,0,1,0,2.507-.825A1.864,1.864,0,0,0,137.011,84.7Z'
                transform='translate(-119.463 -80.376)'
                fill='#525682'
              />
              <path
                id='Path_22380'
                data-name='Path 22380'
                d='M91,134.582A2.936,2.936,0,0,1,88.4,130.36h0a2.819,2.819,0,0,1,1.682-1.451,2.9,2.9,0,1,1,1.814,5.508,2.04,2.04,0,0,1-.891.165Zm0-3.925a.939.939,0,0,0-.891.561h0a1,1,0,1,0,1.352-.429A.825.825,0,0,0,91,130.656Z'
                transform='translate(-88.1 -111.888)'
                fill='#525682'
              />
              <g id='Group_15046' data-name='Group 15046' transform='translate(127.057 36.019)'>
                <path
                  id='Path_22381'
                  data-name='Path 22381'
                  d='M488.447,202.947h0a.976.976,0,0,1,1.352,0l2.573,2.573a.976.976,0,0,1,0,1.352h0a.976.976,0,0,1-1.352,0l-2.573-2.573A.976.976,0,0,1,488.447,202.947Z'
                  transform='translate(-483.268 -197.439)'
                  fill='#525682'
                />
                <path
                  id='Path_22382'
                  data-name='Path 22382'
                  d='M498.757,186.8h3.628a.957.957,0,0,1,.957.957h0a.957.957,0,0,1-.957.957h-3.628a.957.957,0,0,1-.957-.957h0A.957.957,0,0,1,498.757,186.8Z'
                  transform='translate(-489.719 -186.8)'
                  fill='#525682'
                />
                <path
                  id='Path_22383'
                  data-name='Path 22383'
                  d='M474.257,212.8h0a.957.957,0,0,1,.957.957v3.628a.957.957,0,0,1-.957.957h0a.957.957,0,0,1-.957-.957v-3.628A.957.957,0,0,1,474.257,212.8Z'
                  transform='translate(-473.3 -204.224)'
                  fill='#525682'
                />
              </g>
              <path
                id='Path_22384'
                data-name='Path 22384'
                d='M429.813,417.573V415.66c.792,0,1.187,0,1.814-.033l.165,1.913A19.482,19.482,0,0,1,429.813,417.573Zm-3.2,0H424.7V415.66h1.913Zm8.51-.726-.627-1.814a14.018,14.018,0,0,0,1.616-.693l.891,1.682a15.515,15.515,0,0,1-1.88.825Zm4.684-2.738-1.286-1.418a11.259,11.259,0,0,0,1.22-1.286l1.451,1.22A14.757,14.757,0,0,1,439.807,414.11Zm3.266-4.321-1.715-.858a10.125,10.125,0,0,0,.66-1.649l1.814.594a12.157,12.157,0,0,1-.759,1.913Zm1.385-5.245-1.913-.132c.033-.495.033-.759.033-1.847h1.913C444.491,403.786,444.491,404.017,444.458,404.544Zm.033-5.113h-1.88v-1.913h1.913v1.913Zm0-5.08h-1.88v-1.913h1.913v1.913Zm0-5.08h-1.88v-1.913h1.913v1.913Zm0-5.08h-1.88V382.28h1.913v1.913Zm0-5.08h-1.88V377.2h1.913v1.913Z'
                transform='translate(-313.673 -278.378)'
                fill='#a9b4d3'
              />
            </g>
          </g>
          <g id='Group_15048' data-name='Group 15048' transform='translate(111.778 6.891)'>
            <rect
              id='Rectangle_9805'
              data-name='Rectangle 9805'
              width='7.257'
              height='21.44'
              transform='translate(1.906 4.041) rotate(-33.841)'
              fill='#2b3078'
            />
            <rect
              id='Rectangle_9806'
              data-name='Rectangle 9806'
              width='21.44'
              height='7.257'
              transform='translate(0 13.846) rotate(-33.841)'
              fill='#2b3078'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
