import { Permission } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomDialog, ShowButton, Typography, useModal, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const PermissionsGroup: FC<{ permissions: Permission[] | any }> = ({ permissions }) => {
  const { t } = useTranslation("admin");
  const { open, handleToggle } = useModal();
  const theme = useTheme();
  return (
    <CustomDialog
      maxWidth='md'
      scrollType='body'
      title={t("Permissions")}
      open={open}
      onCloseModal={handleToggle}
      button={<ShowButton onClick={handleToggle}>{t("View")}</ShowButton>}
    >
      {permissions?.length ? (
        permissions?.map(item => (
          <Box key={item?.code}>
            <Typography color={theme.palette.primary.main}>{item?.name}</Typography>
          </Box>
        ))
      ) : (
        <Typography color={theme.palette.primary.main}> {t("No Permissions Group to show.")}</Typography>
      )}
    </CustomDialog>
  );
};
