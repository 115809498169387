import { IconButton, styled } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.primary.main,
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    transition: theme.transitions.create(["background-color", "color"], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
  },
  margin: "auto 5px",
}));

export const useTablePreferencesStyles = makeStyles()({
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
});
