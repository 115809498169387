/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsurancePolicyCreateMutationVariables = Types.Exact<{
  input: Types.InsurancePolicyCreateInput;
}>;


export type InsurancePolicyCreateMutation = { __typename?: 'Mutation', insurancePolicyCreate?: { __typename?: 'InsurancePolicyCRUD', insurancePolicy?: { __typename?: 'InsurancePolicy', id: string, created?: any | null, isActive?: boolean | null, modified?: any | null, name?: string | null, number?: string | null } | null, entityErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null, code: Types.H_EntityErrorCode }> | null } | null };


export const InsurancePolicyCreateDocument = gql`
    mutation InsurancePolicyCreate($input: InsurancePolicyCreateInput!) {
  insurancePolicyCreate(input: $input) {
    insurancePolicy {
      id
      created
      isActive
      modified
      name
      number
    }
    entityErrors {
      field
      message
      code
    }
  }
}
    `;
export type InsurancePolicyCreateMutationFn = Apollo.MutationFunction<InsurancePolicyCreateMutation, InsurancePolicyCreateMutationVariables>;

/**
 * __useInsurancePolicyCreateMutation__
 *
 * To run a mutation, you first call `useInsurancePolicyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsurancePolicyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insurancePolicyCreateMutation, { data, loading, error }] = useInsurancePolicyCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsurancePolicyCreateMutation(baseOptions?: Apollo.MutationHookOptions<InsurancePolicyCreateMutation, InsurancePolicyCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsurancePolicyCreateMutation, InsurancePolicyCreateMutationVariables>(InsurancePolicyCreateDocument, options);
      }
export type InsurancePolicyCreateMutationHookResult = ReturnType<typeof useInsurancePolicyCreateMutation>;
export type InsurancePolicyCreateMutationResult = Apollo.MutationResult<InsurancePolicyCreateMutation>;
export type InsurancePolicyCreateMutationOptions = Apollo.BaseMutationOptions<InsurancePolicyCreateMutation, InsurancePolicyCreateMutationVariables>;