/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type AttributeErrorsFragmentFragment = { __typename?: 'ProductError', field?: string | null, message?: string | null };

export const AttributeErrorsFragmentFragmentDoc = gql`
    fragment AttributeErrorsFragment on ProductError {
  field
  message
}
    `;