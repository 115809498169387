import { HealthPackage } from "@health/queries/types";
import { HealthPackageApprovalStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomMuiDialog, IconButton, RejectIcon, TextField, Tooltip, useAddToast, useTheme } from "@toolkit/ui";
import { HealthPackageApproveMutation, useHealthPackageApproveMutation } from "pages/HealthPackages/gql";
import React, { FC, useState } from "react";
import useToggle from "shared/hooks/useToggle";

const RejectHealthPackage: FC<{ row: HealthPackage }> = ({ row }) => {
  const [rejectionReason, setRejectionReason] = useState<string>("");
  const { t } = useTranslation("admin");

  const { handleToggle, open } = useToggle();
  const [error, setError] = useState<string>("");
  const { failed, succeeded } = useAddToast();
  const [healthPackageApproveMutation, { loading: isLoading }] = useHealthPackageApproveMutation({
    onCompleted: (data: HealthPackageApproveMutation) => {
      if (data?.healthPackageApprove?.healthPackageErrors?.length! > 0) {
        failed(t("Failed in rejecting health package"));
      } else {
        handleToggle();
        succeeded(t("Health package rejected!"));
      }
    },
  });

  const handleRejectHealthPackage = () => {
    rejectionReason
      ? healthPackageApproveMutation({
          variables: {
            healthPackageApproveId: row?.id,
            input: {
              isApproved: false,
              rejectionReason,
            },
          },

          update(cache) {
            const normalizedId = cache.identify({ id: row?.id, __typename: "HealthPackage" });
            cache.evict({ id: normalizedId } as any);
            cache.gc();
          },
        })
      : setError(t("Rejection reason is required"));
  };

  const handleSetRejectionReason = (input: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRejectionReason(input?.target?.value);
    setError("");
  };
  const isPendingApproval = row?.approvalStatus === HealthPackageApprovalStatus.PendingApproval;
  const theme = useTheme();
  return (
    <CustomMuiDialog
      open={open}
      DialogTitleProps={{
        title: t("Reject Health Package"),
        onClose: handleToggle,
      }}
      maxWidth='sm'
      button={
        <IconButton disabled={!isPendingApproval || isLoading} onClick={handleToggle}>
          <RejectIcon color='error' sx={{ fontSize: theme.mixins.fonts.fontSize.xl }} />
        </IconButton>
      }
      DialogActionsProps={{
        children: (
          <Tooltip title={t("Please Enter Rejection Reason")}>
            <span>
              <Button color='error' disabled={isLoading} onClick={handleRejectHealthPackage}>
                {t("Reject")}
              </Button>
            </span>
          </Tooltip>
        ),
      }}
    >
      <TextField
        fullWidth
        minRows={3}
        value={rejectionReason}
        onChange={input => handleSetRejectionReason(input)}
        label={t("Rejection reason")}
        error={!!error}
        helperText={error || " "}
      />
    </CustomMuiDialog>
  );
};
export default React.memo(RejectHealthPackage);
