export type FontFamilyEnum = "medium" | "regular" | "bold";

const arFonts: Record<FontFamilyEnum, string> = {
  medium: "Arab Kufi Medium",
  regular: "Arab Kufi Regular",
  bold: "Arab Kufi Bold",
};

const enFonts: Record<FontFamilyEnum, string> = {
  medium: "Airbnb Cereal App Medium", // 400
  regular: "Airbnb Cereal App Book", // 500
  bold: "Airbnb Cereal App Bold", // 600
};

export const getAppFonts = (type: FontFamilyEnum, overrides?: { ar: FontFamilyEnum; en: FontFamilyEnum }) => {
  return `${enFonts[overrides?.en || type]} , ${arFonts[overrides?.ar || type]}`;
};
