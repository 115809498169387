import { InsurancePolicy } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { insurancePoliciesTitleBreadcrumb } from "pages/InsurancePolicies/constants/insurance-policies-breadcrumbs";
import { insurancePoliciesPaths } from "pages/InsurancePolicies/constants/insurance-policies-paths";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "shared/components";
import { useInsurancePoliciesQuery } from "../gql";
import { useInsurancePoliciesColumns } from "./useInsurancePoliciesColumns";

export const InsurancePoliciesList: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const handleEdit = ({ id }: InsurancePolicy) => {
    navigate(insurancePoliciesPaths.editPath.fullPathWithParams(id));
  };

  const handleAddNew = () => {
    navigate(insurancePoliciesPaths.newPath.fullPath);
  };

  const insurancePoliciesColumns = useInsurancePoliciesColumns();

  useEffect(() => {
    setBreadCrumb({
      title: insurancePoliciesTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      hasTableSetting
      gridName='insurancePolicies'
      columns={insurancePoliciesColumns}
      query={useInsurancePoliciesQuery}
      tableAction={{
        isEditVisible: true,
        onEditRow: handleEdit,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={handleAddNew} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
