/* eslint-disable max-lines */
import { hasAnyPermission } from "@health/common";
import { FeatureEnum, IsFeatureEnabledType } from "@health/hooks";
import { PermissionEnum } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { ProductIcon } from "@toolkit/ui";
import { healthPackagesRoutes } from "pages/HealthPackages/route";
import { productRoutes } from "pages/ProductsManagement/constant/product-catalog-route.constant";
import { subscriptionsManagementRoutes } from "pages/SubscriptionsMangment/route";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { marketplaceRoute } from "./marketplace-paths.constants";
import { marketplaceOrdersTrackingRoutes } from "pages/MarketplaceOrderTracking";

export const marketplaceRoutes: (props: {
  navigate: NavigateFunction;
  t: TFunction;
  isFeatureEnabled: IsFeatureEnabledType;
}) => RouteItem = ({ navigate, t, isFeatureEnabled }) => {
  const canTrackOrders = hasAnyPermission([PermissionEnum.ViewOrders, PermissionEnum.ManageOrders]);
  const canViewMarketplaceMenu =
    hasAnyPermission([PermissionEnum.ManageProducts, PermissionEnum.ManageSubscriptions, PermissionEnum.ManageHealthPackages]) ||
    canTrackOrders;

  const isMarketplaceRootHidden = !(isFeatureEnabled([FeatureEnum.HealthPackage, FeatureEnum.Marketplace]) && canViewMarketplaceMenu);
  const isMarketplaceItemsEnabled = isFeatureEnabled([FeatureEnum.Marketplace]);
  const isHealthPackageItemEnabled = isFeatureEnabled([FeatureEnum.HealthPackage]);

  return {
    id: "marketplace",
    text: t("Marketplace", { ns: "admin" }),
    icon: <ProductIcon />,
    isProhibited: isMarketplaceRootHidden,
    hidden: isMarketplaceRootHidden,
    route: marketplaceRoute,
    subItems: [
      productRoutes({ navigate, t, enabled: isMarketplaceItemsEnabled }),
      healthPackagesRoutes({ navigate, t, enabled: isHealthPackageItemEnabled }),
      subscriptionsManagementRoutes({
        navigate,
        t,
        enabled: isMarketplaceItemsEnabled,
      }),
      marketplaceOrdersTrackingRoutes({ navigate, t }),
    ],
  };
};
