import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Typography, useTheme } from "@toolkit/ui";
export const HeaderCell = ({ icon, title }) => {
    const { palette, mixins } = useTheme();
    return (_jsx(Grid, Object.assign({ item: true, flex: 1, container: true, direction: "column", alignItems: "center" }, { children: title && (_jsxs(Grid, Object.assign({ item: true, marginRight: 1, display: 'flex', marginLeft: mixins.fonts.fontSize.md }, { children: [icon, _jsx(Typography, Object.assign({ color: palette.primary.main, fontWeight: "bold", fontSize: mixins.fonts.fontSize.sm }, { children: title }))] }))) })));
};
HeaderCell.defaultProps = {
    title: null,
    icon: null,
};
