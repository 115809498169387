import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  root: {
    width: 15,
    height: 17,
  },
}));

export const CheckboxIcon: FC<SvgIconProps> = ({ width, height, fill, viewBox, ...props }) => {
  const { classes, theme } = useStyles();
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "18px"}
      height={height ? height : "18px"}
      viewBox={viewBox ? viewBox : "0 0 18 18"}
      fill={fill ? fill : theme.palette.primary.main}
      classes={{ root: classes.root }}
      {...props}
    >
      <path
        id='Selection_Control_Checkbox_On_Enabled'
        data-name='Selection Control / Checkbox / On / Enabled'
        d='M22-681H8a2,2,0,0,0-2,2v14a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2v-14A2,2,0,0,0,22-681Zm-9,14-5-5,1.41-1.41L13-669.83l7.59-7.59L22-676Z'
        transform='translate(-6 681)'
        fill={fill ? fill : theme.palette.primary.main}
      />
    </SvgIcon>
  );
};
