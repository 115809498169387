/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsurancePolicyQueryVariables = Types.Exact<{
  insurancePolicyId: Types.Scalars['ID'];
}>;


export type InsurancePolicyQuery = { __typename?: 'Query', insurancePolicy?: { __typename?: 'InsurancePolicy', id: string, isActive?: boolean | null, modified?: any | null, name?: string | null, number?: string | null, created?: any | null, network?: { __typename?: 'InsuranceNetwork', id: string, isActive?: boolean | null, name?: string | null } | null, outpatientPolicy?: { __typename?: 'InsuranceOutpatientPolicy', cons?: string | null, created?: any | null, dental?: string | null, id: string, modified?: any | null, optical?: boolean | null, outpatient?: boolean | null, pharm?: string | null } | null } | null };


export const InsurancePolicyDocument = gql`
    query InsurancePolicy($insurancePolicyId: ID!) {
  insurancePolicy(id: $insurancePolicyId) {
    id
    isActive
    modified
    name
    number
    created
    network {
      id
      isActive
      name
    }
    outpatientPolicy {
      cons
      created
      dental
      id
      modified
      optical
      outpatient
      pharm
    }
  }
}
    `;

/**
 * __useInsurancePolicyQuery__
 *
 * To run a query within a React component, call `useInsurancePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsurancePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsurancePolicyQuery({
 *   variables: {
 *      insurancePolicyId: // value for 'insurancePolicyId'
 *   },
 * });
 */
export function useInsurancePolicyQuery(baseOptions: Apollo.QueryHookOptions<InsurancePolicyQuery, InsurancePolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsurancePolicyQuery, InsurancePolicyQueryVariables>(InsurancePolicyDocument, options);
      }
export function useInsurancePolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsurancePolicyQuery, InsurancePolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsurancePolicyQuery, InsurancePolicyQueryVariables>(InsurancePolicyDocument, options);
        }
export type InsurancePolicyQueryHookResult = ReturnType<typeof useInsurancePolicyQuery>;
export type InsurancePolicyLazyQueryHookResult = ReturnType<typeof useInsurancePolicyLazyQuery>;
export type InsurancePolicyQueryResult = Apollo.QueryResult<InsurancePolicyQuery, InsurancePolicyQueryVariables>;