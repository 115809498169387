import { payerTpoOptionsMap, payerTpoOptions } from "@health/enum-options";
import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IForm } from "../types";
import { AutocompleteController } from "@toolkit/ui";
export const Tpo: FC<IForm> = ({ errors, defaultValue }) => {
  const { t } = useTranslation("admin");
  const { formState, control } = useFormContext();
  const combinedErrors = combineErrors(formState.errors, errors);

  return (
    <AutocompleteController
      ControllerProps={{
        name: "tpo",
        control: control,
        defaultValue: payerTpoOptionsMap[defaultValue?.tpo || ""] || undefined,
      }}
      TextFieldProps={{
        placeholder: t("TPO"),
        error: Boolean(combinedErrors?.tpo?.message),
        helperText: t(combinedErrors?.tpo?.message),
        label: t("TPO"),
      }}
      getOptionLabel={option => t(option?.label)}
      options={payerTpoOptions}
    />
  );
};
