import { HealthProgramMember, HealthProgramMemberInput } from "@health/queries/types";
import { getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Button, CircularProgress, CustomDialog, ShowButton } from "@toolkit/ui";
import React, { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useUserMemberShipsHooks } from "./useUserMemberShipsHooks";

type IMemberListDialogProps = {
  member: HealthProgramMember;
};

export const UserMembershipsDialog: FC<IMemberListDialogProps> = ({ member }) => {
  const { id, phone, coverageBy, firstName, lastName, insuranceId, nationalId, membershipEnd, membershipStart } = member;
  const { t } = useTranslation("admin");

  const {
    open,
    loading,
    pageInfo,
    membersLists,
    loadingUpdate,
    healthProgramMemberLists,
    handleToggle,
    handleUpdate,
    fetchMoreData,
    handleSearchInput,
  } = useUserMemberShipsHooks(id);
  const methods = useForm<HealthProgramMemberInput>({
    mode: "onChange",
  });

  const { handleSubmit, control } = methods;

  const onSubmit = data => {
    const request = {
      phone,
      coverageBy,
      insuranceId,
      nationalId,
      firstName,
      lastName,
      membershipEnd,
      membershipStart,
    };
    handleUpdate(data, request);
  };

  return (
    <FormProvider {...methods}>
      <CustomDialog
        open={open}
        onClose={handleToggle}
        title={t("Member Lists")}
        button={<ShowButton onClick={handleToggle}>{t("Show")}</ShowButton>}
        footerComponent={
          <Button onClick={handleToggle} variant='outlined'>
            {t("Close")}
          </Button>
        }
        onConfirm={handleSubmit(onSubmit)}
        submitTitle={t("Update")}
        onCloseModal={handleToggle}
        isSubmitLoading={loadingUpdate}
        DialogContentProps={{
          sx: { minHeight: "unset" },
        }}
      >
        {loading ? (
          <CircularProgress size={60} />
        ) : (
          <AutocompleteController
            multiple
            fullWidth
            ControllerProps={{
              control: control,
              name: "healthProgramMemberLists",
              defaultValue: membersLists || [],
              rules: { required: getRequiredValidation(t, false) },
            }}
            TextFieldProps={{
              label: t("Member Lists"),
            }}
            hasMore={pageInfo?.hasNextPage}
            fetchMoreData={fetchMoreData}
            getOptionLabel={option => option?.name}
            options={healthProgramMemberLists || []}
            onSearchInput={handleSearchInput}
          />
        )}
      </CustomDialog>
    </FormProvider>
  );
};
