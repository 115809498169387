import { ProductVariant } from "@health/queries/types";
import { formGirdSpacing, inputCleanUp } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Checkbox, FormActions, FormCard, FormControlLabel, Grid, PageWrapper } from "@toolkit/ui";
import React, { FC } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InventoryCardComponent from "../../../Form/AttributeSection/InventoryCard.component";
import { ProductVariantImages } from "./ProductVariantImages/ProductVariantImages";
import { VariantFormType } from "./type";
import VariantInformation from "./VariantInformation/VariantInformation";

const VariantForm: FC<VariantFormType> = ({
  data,
  id,
  onSubmitData,
  isEdit = false,
  loading,
  errors,
  product,
  productVariant,
  defaultProductData,
  createdProductVariant,
  successCreateVariant,
}) => {
  const { t } = useTranslation("admin");
  const { ...methods } = useForm({
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const onSubmitVariant = (data: any) => {
    const output = Object.entries(data)
      .map(([k]) => {
        return k.includes("variant") && { id: data[k]?.attributeId, values: [data[k]?.name] };
      })
      .filter(el => el);
    const output111 = Object.entries(data)
      .filter(([k]) => {
        return !k.includes("variant") && data[k];
      })
      .reduce((accum: any, [k, v]) => {
        accum[k] = v;
        return accum;
      }, {});
    if (!isEdit) {
      const request = {
        ...output111,
        product: id,
        attributes: output,
        trackInventory: data?.trackInventory,
      };
      inputCleanUp(request);
      onSubmitData(request);
    } else {
      const input = {
        id,
        input: {
          ...output111,
          attributes: output,
          trackInventory: data?.trackInventory,
        },
      };
      inputCleanUp(input);
      onSubmitData(input);
    }
  };
  const nav = useNavigate();
  const handleNavigationCancel = () => {
    if (productVariant) {
      nav(-2);
    } else {
      nav(-1);
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitVariant)}>
          <PageWrapper
            actions={
              <FormActions
                hasEdit={isEdit}
                hasSave={!isEdit}
                hasCancel
                onEditItem={handleSubmit(onSubmitVariant)}
                onSave={handleSubmit(onSubmitVariant)}
                onNavigation={handleNavigationCancel}
              />
            }
          >
            <Grid container spacing={formGirdSpacing}>
              <Grid item xs={12} margin={1}>
                <VariantInformation
                  isEdit={isEdit}
                  defaultProductData={defaultProductData}
                  variant={productVariant?.productVariant as ProductVariant}
                  data={data}
                />
              </Grid>
            </Grid>
            <Grid container spacing={formGirdSpacing}>
              <Grid item xs={12} margin={1}>
                <FormCard loading={successCreateVariant || loading} doYouHaveData={true} title={t("Inventory")}>
                  <InventoryCardComponent errors={errors} defaultProductData={productVariant?.productVariant} />
                  <Controller
                    name='trackInventory'
                    render={({ field: { onChange: handleChange, value } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={productVariant?.productVariant?.trackInventory || false}
                            onChange={e => handleChange(e.target.checked)}
                            checked={value}
                          />
                        }
                        label={t("Track inventory")}
                      />
                    )}
                  />
                </FormCard>
              </Grid>
              {(createdProductVariant?.productVariantCreate?.productVariant || productVariant?.productVariant?.id) && (
                <ProductVariantImages
                  product={product}
                  productVariantCreate={createdProductVariant?.productVariantCreate?.productVariant as ProductVariant}
                  idVariant={productVariant?.productVariant?.id}
                />
              )}
            </Grid>
          </PageWrapper>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};

export default VariantForm;
