/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AttributeValueUpdateMutationVariables = Types.Exact<{
  input: Types.AttributeValueCreateInput;
  id: Types.Scalars['ID'];
}>;


export type AttributeValueUpdateMutation = { __typename?: 'Mutation', attributeValueUpdate?: { __typename?: 'AttributeValueUpdate', attributeValue?: { __typename?: 'AttributeValue', id: string, name?: string | null, inputType?: Types.AttributeInputTypeEnum | null } | null, attribute?: { __typename?: 'Attribute', id: string, name?: string | null, values?: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null, inputType?: Types.AttributeInputTypeEnum | null } | null> | null } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const AttributeValueUpdateDocument = gql`
    mutation attributeValueUpdate($input: AttributeValueCreateInput!, $id: ID!) {
  attributeValueUpdate(input: $input, id: $id) {
    attributeValue {
      id
      name
      inputType
    }
    attribute {
      id
      name
      values {
        id
        name
        inputType
      }
    }
    productErrors {
      field
      message
    }
  }
}
    `;
export type AttributeValueUpdateMutationFn = Apollo.MutationFunction<AttributeValueUpdateMutation, AttributeValueUpdateMutationVariables>;

/**
 * __useAttributeValueUpdateMutation__
 *
 * To run a mutation, you first call `useAttributeValueUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttributeValueUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attributeValueUpdateMutation, { data, loading, error }] = useAttributeValueUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttributeValueUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AttributeValueUpdateMutation, AttributeValueUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttributeValueUpdateMutation, AttributeValueUpdateMutationVariables>(AttributeValueUpdateDocument, options);
      }
export type AttributeValueUpdateMutationHookResult = ReturnType<typeof useAttributeValueUpdateMutation>;
export type AttributeValueUpdateMutationResult = Apollo.MutationResult<AttributeValueUpdateMutation>;
export type AttributeValueUpdateMutationOptions = Apollo.BaseMutationOptions<AttributeValueUpdateMutation, AttributeValueUpdateMutationVariables>;