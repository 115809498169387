/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsurancePolicyUpdateMutationVariables = Types.Exact<{
  insurancePolicyUpdateId: Types.Scalars['ID'];
  input: Types.InsurancePolicyUpdateInput;
}>;


export type InsurancePolicyUpdateMutation = { __typename?: 'Mutation', insurancePolicyUpdate?: { __typename?: 'InsurancePolicyCRUD', entityErrors?: Array<{ __typename?: 'H_EntityError', message?: string | null, field?: string | null, code: Types.H_EntityErrorCode }> | null } | null };


export const InsurancePolicyUpdateDocument = gql`
    mutation InsurancePolicyUpdate($insurancePolicyUpdateId: ID!, $input: InsurancePolicyUpdateInput!) {
  insurancePolicyUpdate(id: $insurancePolicyUpdateId, input: $input) {
    entityErrors {
      message
      field
      code
    }
  }
}
    `;
export type InsurancePolicyUpdateMutationFn = Apollo.MutationFunction<InsurancePolicyUpdateMutation, InsurancePolicyUpdateMutationVariables>;

/**
 * __useInsurancePolicyUpdateMutation__
 *
 * To run a mutation, you first call `useInsurancePolicyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsurancePolicyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insurancePolicyUpdateMutation, { data, loading, error }] = useInsurancePolicyUpdateMutation({
 *   variables: {
 *      insurancePolicyUpdateId: // value for 'insurancePolicyUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsurancePolicyUpdateMutation(baseOptions?: Apollo.MutationHookOptions<InsurancePolicyUpdateMutation, InsurancePolicyUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsurancePolicyUpdateMutation, InsurancePolicyUpdateMutationVariables>(InsurancePolicyUpdateDocument, options);
      }
export type InsurancePolicyUpdateMutationHookResult = ReturnType<typeof useInsurancePolicyUpdateMutation>;
export type InsurancePolicyUpdateMutationResult = Apollo.MutationResult<InsurancePolicyUpdateMutation>;
export type InsurancePolicyUpdateMutationOptions = Apollo.BaseMutationOptions<InsurancePolicyUpdateMutation, InsurancePolicyUpdateMutationVariables>;