import { InsurancePolicy } from "@health/queries/types";
import { FC } from "react";
import { InsurancePolicyForm } from "../Form/InsurancePolicyForm";
import { useInsurancePoliciesEditPage } from "./useInsurancePoliciesEditPage";

export const InsurancePoliciesEditPage: FC = () => {
  const { insurancePolicy, errors, handleSubmit, isLoading, isSubmitting } = useInsurancePoliciesEditPage();
  return (
    <InsurancePolicyForm
      errors={errors}
      onDone={handleSubmit}
      isLoading={isLoading}
      insurancePolicy={insurancePolicy as InsurancePolicy}
      isEditMode
      isSubmitting={isSubmitting}
    />
  );
};
