import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { FC, Fragment, ReactNode } from "react";
import { EmptyDataIcon } from "../../../icons/icons";
import { useEmptyCardStyles } from "./EmptyCard.styles";

interface EmptyCardProps extends Omit<BoxProps, "title"> {
  title?: ReactNode;
  icon?: ReactNode;
  message?: ReactNode;
}

export const EmptyCard: FC<EmptyCardProps> = props => {
  const { title, icon, message, ...BoxProps } = props;
  const { classes, theme } = useEmptyCardStyles();

  return (
    <Box className={classes.mainDiv} {...BoxProps}>
      {icon ? icon : <EmptyDataIcon />}
      <Fragment>
        {title && (
          <Typography fontSize={theme.mixins.fonts.fontSize.xl} color={theme.palette.stale.main} noWrap>
            {title}
          </Typography>
        )}
        {message && (
          <Typography fontSize={theme.mixins.fonts.fontSize.md} color={theme.palette.stale.main} gutterBottom noWrap>
            {message}
          </Typography>
        )}
      </Fragment>
    </Box>
  );
};
EmptyCard.defaultProps = {
  title: "No content has been added yet",
  message: "Start by adding data assets",
  icon: undefined,
};
