import { InsurancePolicy } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import moment from "moment";
import { useMemo } from "react";
import { getActiveInActiveFilter } from "shared/components";
import ActiveToggleSwitch from "./components/ActiveToggleSwitch";
import { OutpatientPolicyInformation } from "./components/OutpatientPolicyInformation";

export type InsurancePolicyColumnTypes = CustomTableColumnProps<InsurancePolicy>[];

export const formatDate = (updateDate?: null | string, i18n?: any) =>
  updateDate ? moment(updateDate).locale(i18n.language).format("DD MMM YYYY") : "";

export const useInsurancePoliciesColumns = (): InsurancePolicyColumnTypes => {
  const { t, i18n } = useTranslation("admin");
  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "number",
        header: t("Number"),
        accessor: "number",
      },
      {
        key: "network",
        header: t("Network"),
        accessor: row => row.network?.name,
      },
      {
        key: "created",
        header: t("Created"),
        accessor: row => formatDate(row?.created, i18n),
      },
      {
        key: "modified",
        header: t("Modified"),
        accessor: row => formatDate(row?.modified, i18n),
      },
      {
        key: "outpatientPolicy",
        header: t("Outpatient Policy"),
        accessor: row => {
          return <OutpatientPolicyInformation outpatientPolicy={row?.outpatientPolicy} />;
        },
      },
      {
        key: "isActive",
        header: t("Active"),
        accessor: row => <ActiveToggleSwitch row={row} />,
        hideFromSettings: true,
        filter: {
          ...getActiveInActiveFilter(),
        },
      },
    ];
  }, []);
};
