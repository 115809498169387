import { makeStyles } from "tss-react/mui";

export const useOrderEventStyles = makeStyles()(theme => ({
  container: {
    padding: 14,
    height: "100vh",
    overflow: "auto",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.xl,
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
  referenceNumber: {
    color: theme.palette.primary.main,
  },
  text: {
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  date: {
    fontSize: theme.mixins.fonts.fontSize.xs,
  },
  textBold: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
  dateBold: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
  dot: {
    margin: 0,
    width: 20,
    height: 20,
    position: "absolute",
    top: 35,
    left: -8,
    background: theme.palette.common.white,
    borderColor: theme.palette.primary.main,
  },
  separator: {
    width: 5,
    background: theme.palette.gray.light,
  },
  position: {
    position: "relative",
  },
  parentCard: {
    border: "1px solid " + theme.palette.primary.main + " !important",
    "&::after": {
      borderColor: theme.palette.primary.main + " transparent  !important",
    },
    "&::before": {
      borderColor: theme.palette.primary.main + ` transparent  !important`,
    },
  },
  card: {
    padding: 10,
    width: 400,
    height: 168,
    borderRadius: 10,
    marginLeft: 6,
    border: "1px solid",
    borderColor: theme.palette.gray.light,
    boxShadow: theme.mixins.shadows.md,
    "&::after": {
      content: "' '",
      position: "absolute",
      top: 40,
      left: 11,
      borderStyle: "solid",
      borderWidth: "8px 8px 0",
      borderColor: `${theme.palette.common.white} transparent`,
      display: "block",
      width: 0,
      zIndex: 1,
      transform: "rotate(90deg)",
    },
    "&::before": {
      content: "' '",
      position: "absolute",
      top: 40,
      left: 9,
      borderStyle: "solid",
      borderWidth: "9px 9px 0",
      borderColor: `${theme.palette.gray.light} transparent`,
      display: "block",
      width: 0,
      zIndex: 0,
      transform: "rotate(90deg)",
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  line: {
    margin: 10,
    marginInline: 0,
  },
  content: {
    marginTop: 20,
  },
}));
