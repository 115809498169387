import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const Prescription: FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='15.209' height='17.743' viewBox='0 0 15.209 17.743' {...props}>
      <path
        id='icons8-pharmacy'
        d='M7,4V14.984H9.535V12.027h2.409l3.081,4.96L12.07,21.743h2.984l2.085-3.357,2.085,3.357h2.984L19.19,16.885l3.018-4.858H19.224l-2.149,3.458-2.453-3.948A4.012,4.012,0,0,0,12.7,4H7Zm.845.845H12.7A3.168,3.168,0,0,1,14.217,10.8l-.784.428.472.759,2.453,3.948.461.743h.512l.461-.743,1.9-3.06h.994L18.473,16.44l-.276.446.276.446L20.689,20.9h-.994L17.857,17.94l-.262-.422h-.911l-.262.422L14.584,20.9h-.994l2.153-3.466.277-.446-.277-.446-3.081-4.96-.248-.4H8.69v2.957H7.845V4.845Zm.845.845v4.647H12.7a2.324,2.324,0,1,0,0-4.647H8.69Zm.845.845H12.7a1.479,1.479,0,1,1,0,2.957H9.535Z'
        transform='translate(-7 -4)'
        fill={props?.fill ? props?.fill : theme.palette.primary.main}
      />
    </SvgIcon>
  );
};
