import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { SubListsAutocomplete } from "@health/autocompletes";
import { userGenderOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormDatePickerField, FormNumberField, FormTextField } from "@toolkit/ui";
import { EntityType } from "@health/queries/types";
import { DecisionConditionAutocompletesForm } from "../../../Decisions/forms/DecisionConditionAutocompletes/DecisionConditionAutocompletesForm";
import { DecisionConditionFieldStringCode } from "../../../Decisions/types/types";
export const DecisionConditionFieldsForm = props => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { conditionPath, condition } = props;
    const { t } = useTranslation("domains");
    const type = (_b = (_a = condition === null || condition === void 0 ? void 0 : condition.field) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.type;
    const code = (_d = (_c = condition === null || condition === void 0 ? void 0 : condition.field) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.code;
    if (type === EntityType.Number) {
        return _jsx(FormNumberField, { name: `${conditionPath}.valueNumber`, placeholder: t("Number"), label: t("Number"), isFloatAllowed: true });
    }
    else if (type === EntityType.String) {
        if (code === DecisionConditionFieldStringCode.Gender) {
            return (_jsx(FormAutocomplete, { name: `${conditionPath}.valueString`, label: t("Gender"), placeholder: t("Gender"), options: userGenderOptions }));
        }
        else if ((_e = condition === null || condition === void 0 ? void 0 : condition.allowedValues) === null || _e === void 0 ? void 0 : _e.length) {
            return (_jsx(FormAutocomplete, { name: `${conditionPath}.valueString`, label: t("Text"), placeholder: t("Text"), options: condition.allowedValues }));
        }
        else {
            return _jsx(FormTextField, { name: `${conditionPath}.valueString`, placeholder: t("Text"), label: t("Text") });
        }
    }
    else if (type === EntityType.List) {
        return _jsx(SubListsAutocomplete, { name: `${conditionPath}.valueList`, filter: { field: (_g = (_f = condition === null || condition === void 0 ? void 0 : condition.field) === null || _f === void 0 ? void 0 : _f.value) === null || _g === void 0 ? void 0 : _g.id } });
    }
    else if (type === EntityType.Id) {
        return _jsx(DecisionConditionAutocompletesForm, { name: `${conditionPath}.valueID`, code: code });
    }
    else if (EntityType.Date === type) {
        return _jsx(FormDatePickerField, { variant: "filled", name: `${conditionPath}.valueDate`, label: t("Date"), placeholder: t("Date") });
    }
    else {
        return _jsx(_Fragment, {});
    }
};
