import { TFunction } from "@toolkit/i18n";
import { insurancePoliciesPaths } from "pages/InsurancePolicies/constants/insurance-policies-paths";

export const insurancePoliciesTitleBreadcrumb = (t: TFunction) => {
  return t("Insurance Policies", { ns: "admin" });
};

export const insurancePoliciesListBreadcrumb = (t: TFunction) => {
  return {
    id: "insurance-policies-list",
    name: t("Insurance Policies", { ns: "admin" }),
    route: insurancePoliciesPaths.listPath.fullPath,
  };
};

export const insurancePoliciesNewBreadcrumb = (t: TFunction) => {
  return {
    id: "insurance-policies-new",
    name: t("New", { ns: "admin" }),
  };
};

export const insurancePoliciesFieldBreadcrumb = (fieldName: string) => {
  return {
    id: "insurance-policies-field",
    name: fieldName,
  };
};
