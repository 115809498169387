/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthPackagesQueryVariables = Types.Exact<{
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.HealthPackageSortingInput>;
  filter?: Types.InputMaybe<Types.HealthPackageFilterInput>;
}>;


export type HealthPackagesQuery = { __typename?: 'Query', healthPackages?: { __typename?: 'HealthPackageCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'HealthPackageCountableEdge', node: { __typename?: 'HealthPackage', id: string, mainImageUrl?: string | null, name: string, nameAr?: string | null, rejectionReason?: string | null, targetFromAge?: number | null, targetToAge?: number | null, description?: string | null, descriptionAr?: string | null, categoryDisplay?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, approvalStatus: Types.HealthPackageApprovalStatus, vendor?: { __typename?: 'Vendor', name: string, nameAr?: string | null, id: string } | null, price?: { __typename?: 'Money', amount: number, currency: string } | null } }> } | null };


export const HealthPackagesDocument = gql`
    query HealthPackages($last: Int, $first: Int, $after: String, $before: String, $sortBy: HealthPackageSortingInput, $filter: HealthPackageFilterInput) {
  healthPackages(
    last: $last
    first: $first
    after: $after
    before: $before
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        mainImageUrl
        name
        nameAr
        rejectionReason
        vendor {
          name
          nameAr
          id
        }
        targetFromAge
        targetToAge
        description
        descriptionAr
        price {
          amount
          currency
        }
        categoryDisplay
        shortDescription
        shortDescriptionAr
        approvalStatus
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useHealthPackagesQuery__
 *
 * To run a query within a React component, call `useHealthPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthPackagesQuery({
 *   variables: {
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthPackagesQuery(baseOptions?: Apollo.QueryHookOptions<HealthPackagesQuery, HealthPackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthPackagesQuery, HealthPackagesQueryVariables>(HealthPackagesDocument, options);
      }
export function useHealthPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthPackagesQuery, HealthPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthPackagesQuery, HealthPackagesQueryVariables>(HealthPackagesDocument, options);
        }
export type HealthPackagesQueryHookResult = ReturnType<typeof useHealthPackagesQuery>;
export type HealthPackagesLazyQueryHookResult = ReturnType<typeof useHealthPackagesLazyQuery>;
export type HealthPackagesQueryResult = Apollo.QueryResult<HealthPackagesQuery, HealthPackagesQueryVariables>;