import { useCustomFormContext } from "@toolkit/core";
import { Box, FormHelperText } from "@mui/material";
import { get } from "lodash";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { MDTextEditor } from "../../MDTextEditor";

type FormMDTextEditorProps = {
  name: string;
  label?: string;
  placeholder?: string;
};

export const FormMDTextEditor: FC<FormMDTextEditorProps> = props => {
  const { name, label, placeholder } = props;

  const {
    control,
    isFormDisabled,
    formState: { errors },
  } = useCustomFormContext();

  const error: string = get(errors, name + ".message", "");

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => <MDTextEditor {...field} label={label} placeholder={placeholder} readonly={isFormDisabled} />}
      />

      {error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};
