import { replacePathParams } from "utils";

export const insurancePoliciesRoute = "/insurance-policies";
const insurancePoliciesNewRoute = "new";
const insurancePoliciesEditRoute = "edit/:id";

export const insurancePoliciesPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${insurancePoliciesRoute}${this.route}`;
    },
  },
  newPath: {
    route: insurancePoliciesNewRoute,
    get fullPath() {
      return `${insurancePoliciesRoute}/${this.route}`;
    },
  },
  editPath: {
    route: insurancePoliciesEditRoute,
    get fullPath() {
      return `${insurancePoliciesRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
};
