import { ProductVariant } from "@health/queries/types";
import { i18n, TFunction } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { ellipseName } from "utils";

export const VariantColumns = (t: TFunction): CustomTableColumnProps<ProductVariant>[] => [
  {
    key: "sku",
    header: t("Variant SKU", { ns: "admin" }),
    accessor: ({ sku }) => ellipseName(sku, 20),
  },
  {
    key: "trackInventory",
    header: t("Track inventory", { ns: "admin" }),
    accessor: ({ trackInventory }) => (trackInventory ? t("True", { ns: "admin" }) : t("False", { ns: "admin" })),
  },
  {
    key: "variant",
    header: t("Attribute value", { ns: "admin" }),
    accessor: ({ name, nameAr }) => (i18n.language === "en" ? name : nameAr || name),
  },
];
