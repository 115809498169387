import { Box, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { FC } from "react";
import { PageWrapperProps } from "./PageWrapper.types";

const PageWrapper: FC<PageWrapperProps> = props => {
  const { actions, children, end, center, start, filters, headerComponent, contentContainerSX } = props;
  const theme = useTheme();
  const toolBar = (
    <Grid
      container
      alignItems='center'
      sx={{
        boxShadow: theme.mixins.shadows.none,
        borderBottom: `1px solid ${theme.palette.gray.light}`,
        backgroundColor: theme.palette.common.white + " !important",
        position: "sticky",
        alignContent: "center",
        zIndex: 1100,
        margin: 0,
        top: 0,
        px: 2,
        // height: "60px",
      }}
    >
      <Grid item xs={12}>
        <Box display='flex' alignItems='center'>
          <Box display='flex' justifyContent='flex-start'>
            {start}
          </Box>
          <Box width='100%' display='flex' justifyContent='space-between' alignItems='center'>
            {filters}
          </Box>
          <Box>{center}</Box>
          <Box display='flex' justifyContent='flex-end' textAlign='end'>
            {end || actions}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
  return (
    <Grid
      container
      item
      sx={{
        position: "relative",
        justifyContent: "space-evenly",
        alignItems: "stretch",
        minHeight: "100%",
        flexDirection: "column",
        display: "flex",
        flex: "1 1 auto",
      }}
    >
      {toolBar}
      {headerComponent}
      <Grid item xs sx={{ padding: "40px", minHeight: 50, position: "relative", flex: "1 1 auto !important", ...contentContainerSX }}>
        {children}
      </Grid>
    </Grid>
  );
};

export default PageWrapper;
