import { Product, ProductError } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VariantForm from "../components/variants/components/Form/VariantForm.component";
import { ProductDocument, useProductQuery, useProductVariantCreateMutation } from "../gql";

const VariantsFormNewPage = () => {
  const [errors, setErrors] = useState<Partial<ProductError>[] | undefined>([]);
  const params = useParams();
  const id = params?.id as string;
  const { succeeded, failed } = useAddToast();
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const productVariantCreatedSuccessfully = t("Product Variant Created successfully");
  const [productVariantCreate, { data: createdProductVariant }] = useProductVariantCreateMutation({
    onCompleted: data => {
      const errors = data?.productVariantCreate?.productErrors;
      setErrors(errors);
      if (errors) {
        if (errors?.length === 0) {
          succeeded(productVariantCreatedSuccessfully);
          navigate(data?.productVariantCreate?.productVariant?.id!);
        } else if (errors?.length > 0) {
          failed(t(formatMessageErrors(errors)));
          console.error("<< Product Variant Creation >>", formatMessageErrors(errors));
        }
      } else {
        succeeded(productVariantCreatedSuccessfully);
      }
    },
    onError: () => {
      failed(t("Product Variant Created Failed"));
    },
    refetchQueries: [
      {
        query: ProductDocument,
        variables: {
          id,
        },
      },
    ],
  });
  const { data: productData, loading: LoadingProduct } = useProductQuery({
    variables: {
      id,
    },
  });
  const handleSubmit = (data: any) => {
    productVariantCreate({
      variables: {
        input: {
          ...data,
        },
      },
    });
  };
  return (
    <VariantForm
      defaultProductData={productData?.product}
      product={productData?.product as Product}
      id={id}
      errors={errors}
      isEdit={false}
      onSubmitData={handleSubmit}
      loading={LoadingProduct}
      createdProductVariant={createdProductVariant}
    />
  );
};

export default VariantsFormNewPage;
