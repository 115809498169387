import type { EmotionCache, StylisPlugin } from "@emotion/cache";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "@toolkit/i18n";
import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { CustomTheme } from "./theme";
import { getToolkitEnvVariable } from "@toolkit/core";

let muiCache: EmotionCache | undefined = undefined;

// Client-side cache, shared for the whole session of the user in the browser.
export const createMuiCache = (isRtl?: boolean) =>
  (muiCache = createCache({
    key: isRtl ? "mui-rtl" : "mui",
    prepend: true,
    stylisPlugins: isRtl ? ([prefixer, rtlPlugin] as StylisPlugin[]) : ([] as StylisPlugin[]),
  }));

export const AppThemeProvider: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const [appTheme, setAppTheme] = useState(CustomTheme);
  const { i18n } = useTranslation();
  const direction = i18n.dir();

  useEffect(() => {
    if (typeof window == "undefined") return;
    const i18nDefault = localStorage.getItem("locale-default");
    if (!i18nDefault) {
      i18n.changeLanguage("en", () => {
        localStorage.setItem("locale-default", "en");
        if (!getToolkitEnvVariable("FILE_SERVICE_API_URL", process.env.NEXT_PUBLIC_FILE_SERVICE_API_URL) + "/upload") {
          location.reload();
        }
      });
    }
  }, []);
  useEffect(() => {
    if (typeof window !== "undefined") {
      document.dir = direction;
      document?.getElementsByTagName("html")?.[0]?.setAttribute("dir", direction);
      document?.getElementsByTagName("html")?.[0]?.setAttribute("lang", i18n.language);
      document?.getElementsByTagName("body")?.[0]?.setAttribute("dir", direction);
      setAppTheme({ ...appTheme, direction });
    }
  }, [direction]);

  return (
    <CacheProvider value={muiCache ?? createMuiCache(direction === "rtl")}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
};
