/* eslint-disable max-lines */
import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { ROUTE_PATHS } from "shared/constants";
import { AttributesFormEditPage, AttributesFormNewPage, AttributesList } from "../attribute";
import { CategoriesFormEditPage, CategoriesFormNewPage, CategoriesList, SubCatagoriesList } from "../categories";
import { ProductBrandEditPage, ProductBrandList, ProductBrandNewPage } from "../productBrand";
import { ProductTypesFromEditPage, ProductTypesFromNewPage, ProductTypesList } from "../productTypes";
import { ProductsFormEditPage, ProductsFormNewPage, ProductsList } from "../products";
import VariantsFormEditPage from "../products/edit/VariantsFormEditPage";
import VariantsFormNewPage from "../products/new/VariantsFormNewPage";

export const productRoutes: (props: { navigate: NavigateFunction; t: TFunction; enabled: boolean }) => RouteItem = ({
  navigate,
  t,
  enabled,
}) => {
  return {
    id: "product-catalogue",
    text: t("Product Catalogue", { ns: "admin" }),
    route: ROUTE_PATHS.productsManagement.path,
    isProhibited: !(enabled && hasPermission(PermissionEnum.ManageProducts)),
    hidden: !enabled,
    subItems: [
      {
        id: "products",
        text: ROUTE_PATHS.productsManagement.products.name,
        route: ROUTE_PATHS.productsManagement.products.path,
        fullPath: ROUTE_PATHS.productsManagement.products.fullPath,
        element: <ProductsList />,
        hidden: !enabled,
        onClick: route => navigate(route),
      },
      {
        id: "new-Product",
        text: ROUTE_PATHS.productsManagement.products?.new?.name,
        route: ROUTE_PATHS.productsManagement.products?.new?.path,
        fullPath: ROUTE_PATHS.productsManagement.products?.new.fullPath,
        element: <ProductsFormNewPage />,
        onClick: route => navigate(route),
        hidden: true,
      },
      {
        id: "update-Product",
        text: ROUTE_PATHS.productsManagement.products?.edit?.name,
        route: ROUTE_PATHS.productsManagement.products?.edit?.path,
        fullPath: ROUTE_PATHS.productsManagement.products?.edit.fullPath,
        element: <ProductsFormEditPage />,
        onClick: route => navigate(route),
        hidden: true,
      },
      {
        id: "update-Variant",
        text: ROUTE_PATHS.productsManagement?.products?.variantEdit?.name,
        route: ROUTE_PATHS.productsManagement?.products?.variantEdit?.path,
        fullPath: ROUTE_PATHS.productsManagement?.products?.variantEdit?.fullPath,
        element: <VariantsFormEditPage />,
        onClick: route => navigate(route),
        hidden: true,
      },
      {
        id: "new-Variant",
        text: ROUTE_PATHS.productsManagement?.products?.variantAdd?.name,
        route: ROUTE_PATHS.productsManagement?.products?.variantAdd?.path,
        fullPath: ROUTE_PATHS.productsManagement?.products?.variantAdd?.fullPath,
        element: <VariantsFormNewPage />,
        onClick: route => navigate(route),
        hidden: true,
      },
      {
        id: "attributes",
        text: t(ROUTE_PATHS.productsManagement.attributes.name),
        route: ROUTE_PATHS.productsManagement.attributes.path,
        fullPath: ROUTE_PATHS.productsManagement.attributes.fullPath,
        element: <AttributesList />,
        onClick: route => navigate(route),
        hidden: !enabled,
      },
      {
        id: "new-attributes",
        text: ROUTE_PATHS.productsManagement?.attributes?.new?.name,
        route: ROUTE_PATHS.productsManagement?.attributes?.new?.path,
        fullPath: ROUTE_PATHS.productsManagement?.attributes?.new?.fullPath,
        element: <AttributesFormNewPage />,
        onClick: route => navigate(route),
        hidden: true,
      },
      {
        id: "update-attributes",
        text: ROUTE_PATHS.productsManagement?.attributes?.edit?.name,
        route: ROUTE_PATHS.productsManagement?.attributes?.edit?.path,
        fullPath: ROUTE_PATHS.productsManagement?.attributes?.edit?.fullPath,
        element: <AttributesFormEditPage />,
        onClick: route => navigate(route),
        hidden: true,
      },
      {
        id: "product-types",
        text: t(ROUTE_PATHS.productsManagement.productType.name),
        route: ROUTE_PATHS.productsManagement.productType.path,
        fullPath: ROUTE_PATHS.productsManagement.productType.fullPath,
        element: <ProductTypesList />,
        onClick: route => navigate(route),
        hidden: !enabled,
      },
      {
        id: "new-product-type",
        text: ROUTE_PATHS.productsManagement?.productType?.new?.name,
        route: ROUTE_PATHS.productsManagement?.productType?.new?.path,
        fullPath: ROUTE_PATHS.productsManagement.productType?.new.fullPath,
        element: <ProductTypesFromNewPage />,
        onClick: route => navigate(route),
        hidden: true,
      },
      {
        id: "update-product-type",
        text: ROUTE_PATHS.productsManagement?.productType?.edit?.name,
        route: ROUTE_PATHS.productsManagement?.productType?.edit?.path,
        fullPath: ROUTE_PATHS.productsManagement?.productType?.edit?.fullPath,
        element: <ProductTypesFromEditPage />,
        onClick: route => navigate(route),
        hidden: true,
      },
      {
        id: "categories",
        text: t(ROUTE_PATHS.productsManagement.categories.name),
        route: ROUTE_PATHS.productsManagement.categories.path,
        fullPath: ROUTE_PATHS.productsManagement.categories.fullPath,
        element: <CategoriesList />,
        onClick: route => navigate(route),
        hidden: !enabled,
      },
      {
        id: "new-category",
        text: ROUTE_PATHS.productsManagement.categories.new.name,
        route: ROUTE_PATHS.productsManagement.categories.new.path,
        fullPath: ROUTE_PATHS.productsManagement.categories.new.fullPath,
        element: <CategoriesFormNewPage />,
        onClick: route => navigate(route),
        hidden: true,
      },
      {
        id: "update-category",
        fullPath: ROUTE_PATHS.productsManagement.categories.edit.fullPath,
        text: ROUTE_PATHS.productsManagement.categories.edit.name,
        route: ROUTE_PATHS.productsManagement.categories.edit.path,
        element: <CategoriesFormEditPage />,
        hidden: true,
      },
      {
        id: "Subcategory",
        fullPath: ROUTE_PATHS.productsManagement.categories.subCategory.fullPath,
        text: ROUTE_PATHS.productsManagement.categories.subCategory.name,
        route: ROUTE_PATHS.productsManagement.categories.subCategory.path,
        element: <SubCatagoriesList />,
        onClick: route => navigate(route),
        hidden: true,
      },
      {
        id: "new-category",
        text: ROUTE_PATHS.productsManagement.categories.newSubCategory.name,
        route: ROUTE_PATHS.productsManagement.categories.newSubCategory.path,
        fullPath: ROUTE_PATHS.productsManagement.categories.newSubCategory.fullPath,
        element: <CategoriesFormNewPage hasParentCategory />,
        onClick: route => navigate(route),
        hidden: true,
      },
      {
        id: "product-Brand",
        text: t(ROUTE_PATHS.productsManagement?.productBrand?.name),
        route: ROUTE_PATHS.productsManagement.productBrand.path,
        fullPath: ROUTE_PATHS.productsManagement.productBrand.fullPath,
        element: <ProductBrandList />,
        onClick: route => navigate(route),
        hidden: !enabled,
      },
      {
        id: "new-product-Brand",
        text: t(ROUTE_PATHS.productsManagement?.productBrand?.name),
        route: ROUTE_PATHS.productsManagement?.productBrand?.new?.path,
        fullPath: ROUTE_PATHS.productsManagement.productBrand?.new.fullPath,
        element: <ProductBrandNewPage />,
        onClick: route => navigate(route),
        hidden: true,
      },
      {
        id: "update-product-Brand",
        text: t(ROUTE_PATHS.productsManagement?.productBrand?.name),
        route: ROUTE_PATHS.productsManagement?.productBrand?.edit?.path,
        fullPath: ROUTE_PATHS.productsManagement.productBrand?.edit.fullPath,
        element: <ProductBrandEditPage />,
        onClick: route => navigate(route),
        hidden: true,
      },
    ],
  };
};
