import { SvgIconProps } from "@mui/material";
import { useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

interface IconProps extends SvgIconProps {}

const useStyles = makeStyles()(() => ({
  a: {
    fill: "#1b2346",
    fontSize: 50,
    fontFamily: "Montserrat-SemiBold, Montserrat",
    fontWeight: 600,
  },
  b: {
    fill: "#83d147",
    fontFamily: "Montserrat-Bold, Montserrat",
    fontWeight: 700,
  },
}));
export const SehaCity: FC<IconProps> = ({ viewBox }) => {
  const { classes } = useStyles();
  const { i18n } = useTranslation();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='219' height='61' viewBox={viewBox ? viewBox : "0 0 219 61"}>
      <text className={classes.a} transform='translate(109 48)'>
        <tspan x='-108.6' y='0'>
          seha
        </tspan>
        <tspan className={classes.b} y='0' x={i18n?.language === "ar" ? "-19" : "0"}>
          city
        </tspan>
      </text>
    </svg>
  );
};
