import { useVendorBranchesQuery } from "gql/queries";
import { useGetTimeZoneQuery } from "../../../UserManagement/gql";
import { NetworkStatus } from "@apollo/client";

export const useVendorBranchesHooks = (id, isOpen) => {
  const { data: setting } = useGetTimeZoneQuery();
  const timeZone = setting?.siteSettings?.timeZone! || 3;
  const { data, loading, fetchMore, networkStatus } = useVendorBranchesQuery({
    variables: {
      id,
      first: 5,
    },
    skip: !isOpen,
  });

  const branchesVendor = data?.vendor?.branches?.edges?.map(branch => branch?.node);

  const pageInfo = data?.vendor?.branches?.pageInfo;

  const fetchMoreData = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 5,
          after: pageInfo?.endCursor,
        },
      });
    }
  };
  const isFetchingMoreData = NetworkStatus.fetchMore === networkStatus;
  return {
    loading,
    isFetchingMoreData,
    pageInfo,
    timeZone,
    branchesVendor,
    fetchMoreData,
  };
};
