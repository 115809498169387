import { TFunction } from "@toolkit/i18n";
import { Palette } from "@mui/material";

export const mapAppearanceToCardColor = (appearance: string, palette: Palette) => {
  if (appearance === "success") {
    return palette.success.main;
  } else if (appearance === "warning") {
    return palette.warning.main;
  } else if (appearance === "error") {
    return palette.error.main;
  }
};

export const mapAppearanceToSelectedIcon = (appearance: string) => {
  if (appearance === "success") {
    return "successAlert";
  } else if (appearance === "warning" || appearance === "error") {
    return "warningAlert";
  }
};

export const mapAppearanceToCardTitle = (appearance: string, t: TFunction) => {
  if (appearance === "success") {
    return t("Success");
  } else if (appearance === "warning") {
    return t("Warning");
  } else if (appearance === "error") {
    return t("Error");
  }
};
