/* eslint-disable max-statements */

import { getToolkitEnvVariable } from "./getToolkitEnvVariable";

export const getMediaLink = (file: string | null | undefined) => {
  const DOWNLOAD_SERVICE: string =
    getToolkitEnvVariable(
      "FILE_SERVICE_API_URL",
      process.env.REACT_APP_FILE_SERVICE_API_URL || process.env.NEXT_PUBLIC_FILE_SERVICE_API_URL
    ) + "/download/";

  if (!file) return "";
  if (typeof file !== "string") {
    if (Object.prototype.hasOwnProperty.call(file, "url") && file?.["url"]) {
      return `${DOWNLOAD_SERVICE}${file?.["url"]}`;
    }
    if (file?.["name"]) {
      return `${DOWNLOAD_SERVICE}${file?.["name"]}`;
    }
    return file;
  }
  if (file.startsWith("http")) return file;
  if (
    file.includes(getToolkitEnvVariable("BASE_MEDIA_URL", process.env.REACT_APP_BASE_MEDIA_URL || process.env.NEXT_PUBLIC_BASE_MEDIA_URL)!)
  )
    return file;
  return `${DOWNLOAD_SERVICE}${file}`;
};

export const getAssetsFromFileStorage = (name: string) =>
  getToolkitEnvVariable(
    "FILE_SERVICE_API_URL",
    process.env.REACT_APP_FILE_SERVICE_API_URL || process.env.NEXT_PUBLIC_FILE_SERVICE_API_URL
  ) +
  "/download/" +
  name;
