export var DecisionUpsertFormFormButtonTitle;
(function (DecisionUpsertFormFormButtonTitle) {
    DecisionUpsertFormFormButtonTitle["CREATE"] = "Create";
    DecisionUpsertFormFormButtonTitle["UPDATE"] = "Update";
})(DecisionUpsertFormFormButtonTitle || (DecisionUpsertFormFormButtonTitle = {}));
export var DecisionActionFieldListCode;
(function (DecisionActionFieldListCode) {
    DecisionActionFieldListCode["RiskTemplateCodes"] = "riskTemplateCodes";
    DecisionActionFieldListCode["ChronicTemplateCodes"] = "chronicTemplateCodes";
    DecisionActionFieldListCode["TaskDefinitionCode"] = "taskDefinitionCode";
})(DecisionActionFieldListCode || (DecisionActionFieldListCode = {}));
export var DecisionConditionFieldListCode;
(function (DecisionConditionFieldListCode) {
    DecisionConditionFieldListCode["City"] = "city";
    DecisionConditionFieldListCode["Division"] = "division";
})(DecisionConditionFieldListCode || (DecisionConditionFieldListCode = {}));
export var DecisionConditionFieldStringCode;
(function (DecisionConditionFieldStringCode) {
    DecisionConditionFieldStringCode["Gender"] = "gender";
})(DecisionConditionFieldStringCode || (DecisionConditionFieldStringCode = {}));
export var DecisionConditionFieldCategoryCode;
(function (DecisionConditionFieldCategoryCode) {
    DecisionConditionFieldCategoryCode["Demographics"] = "demographics";
    DecisionConditionFieldCategoryCode["HealthParameter"] = "HEALTH_PARAMETER";
})(DecisionConditionFieldCategoryCode || (DecisionConditionFieldCategoryCode = {}));
