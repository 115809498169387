import { formatMessageErrors } from "@toolkit/apollo";
import { Button, CustomDialog, Typography, useAddToast } from "@toolkit/ui";
import { useTerminateVendorSubscriptionMutation } from "gql/mutations";
import { FC } from "react";
import { useTerminateVendorStyles } from "./VendorList.styles";
import { TerminateVendorInterface } from "./VendorList.types";
import { useVendorsListHook } from "./VendorsComponentsList.hook";

const TerminateVendor: FC<TerminateVendorInterface> = props => {
  const { id, isActive } = props;
  const { classes } = useTerminateVendorStyles();
  const { succeeded, failed } = useAddToast();
  const { handleClose, handleOpen, isOpen, t, currentDir } = useVendorsListHook();
  const terminateVendorTranslation = t("Terminate Vendor Plan");
  const [terminateVendor] = useTerminateVendorSubscriptionMutation({
    onCompleted: data => {
      const errors = data?.terminateVendorSubscription?.vendorErrors;
      if (errors?.length === 0) {
        succeeded(t("Terminate Vendor Subscription Successfully"));
      } else {
        formatMessageErrors(errors);
      }
    },
    onError: () => {
      failed(t("Terminate Vendor Subscription Failed"));
    },
  });

  const handleTerminateVendor = () => {
    terminateVendor({
      variables: {
        vendor: id,
      },
    });
  };
  return (
    <>
      {isOpen && (
        <CustomDialog open={isOpen} onCloseModal={handleClose} title={terminateVendorTranslation} onConfirm={handleTerminateVendor}>
          <Typography variant={"h6"} gutterBottom dir={currentDir}>
            {t("Are you sure you want to Terminate Vendor Plan?")}
          </Typography>
        </CustomDialog>
      )}
      {isActive ? (
        <Button color={"warning"} size={"small"} onClick={handleOpen} className={classes.customButton}>
          {terminateVendorTranslation}
        </Button>
      ) : (
        <Button color={"primary"} variant='contained' disabled size={"small"} className={classes.customButton}>
          {terminateVendorTranslation}
        </Button>
      )}
    </>
  );
};

export default TerminateVendor;
