/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthPackageApproveMutationVariables = Types.Exact<{
  healthPackageApproveId: Types.Scalars['ID'];
  input: Types.HealthPackageApproveInput;
}>;


export type HealthPackageApproveMutation = { __typename?: 'Mutation', healthPackageApprove?: { __typename?: 'HealthPackageApprove', healthPackage?: { __typename?: 'HealthPackage', id: string, approvalStatus: Types.HealthPackageApprovalStatus } | null, healthPackageErrors: Array<{ __typename?: 'HealthPackageError', code: Types.HealthPackageErrorCode, field?: string | null, message?: string | null }> } | null };


export const HealthPackageApproveDocument = gql`
    mutation HealthPackageApprove($healthPackageApproveId: ID!, $input: HealthPackageApproveInput!) {
  healthPackageApprove(id: $healthPackageApproveId, input: $input) {
    healthPackage {
      id
      approvalStatus
    }
    healthPackageErrors {
      code
      field
      message
    }
  }
}
    `;
export type HealthPackageApproveMutationFn = Apollo.MutationFunction<HealthPackageApproveMutation, HealthPackageApproveMutationVariables>;

/**
 * __useHealthPackageApproveMutation__
 *
 * To run a mutation, you first call `useHealthPackageApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthPackageApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthPackageApproveMutation, { data, loading, error }] = useHealthPackageApproveMutation({
 *   variables: {
 *      healthPackageApproveId: // value for 'healthPackageApproveId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthPackageApproveMutation(baseOptions?: Apollo.MutationHookOptions<HealthPackageApproveMutation, HealthPackageApproveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthPackageApproveMutation, HealthPackageApproveMutationVariables>(HealthPackageApproveDocument, options);
      }
export type HealthPackageApproveMutationHookResult = ReturnType<typeof useHealthPackageApproveMutation>;
export type HealthPackageApproveMutationResult = Apollo.MutationResult<HealthPackageApproveMutation>;
export type HealthPackageApproveMutationOptions = Apollo.BaseMutationOptions<HealthPackageApproveMutation, HealthPackageApproveMutationVariables>;