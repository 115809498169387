import { makeStyles } from "tss-react/mui";

export const useAuditHistoryHeaderStyles = makeStyles()(theme => ({
  title: {
    fontSize: theme.mixins.fonts.fontSize.xl,
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
  referenceNumber: {
    color: theme.palette.primary.main,
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
