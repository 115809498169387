import { TargetGenderEnum } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const targetGenderOptionsMap = {
    [TargetGenderEnum.All]: {
        key: TargetGenderEnum.All,
        get label() {
            return i18n.t("All", { ns: "domains" });
        },
        value: TargetGenderEnum.All,
    },
    [TargetGenderEnum.Male]: {
        key: TargetGenderEnum.Male,
        get label() {
            return i18n.t("Male", { ns: "domains" });
        },
        value: TargetGenderEnum.Female,
    },
    [TargetGenderEnum.Female]: {
        key: TargetGenderEnum.Female,
        get label() {
            return i18n.t("Female", { ns: "domains" });
        },
        value: TargetGenderEnum.Female,
    },
};
export const targetGenderOptions = Object.values(targetGenderOptionsMap);
