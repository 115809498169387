import { i18n } from "./index";
import _ from "lodash";

export const getToolkitEnvVariable = (key: string, defaultValue?: string) => {
  const getEnvVariableFn = _.get(window, "getEnvVariable", (_key: string, defaultValue?: string) => defaultValue);

  return getEnvVariableFn(key, defaultValue);
};

export const isLanguageEnglish = () => {
  return i18n.language === "en";
};

export const pickLocalizedText = (englishText: string, arabicText: string) => {
  if (isLanguageEnglish()) {
    return englishText;
  }

  return arabicText || englishText;
};
