import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const SessionListDocument = gql `
    query SessionList($pagination: PaginationArg) {
  sessions(pagination: $pagination) {
    data {
      id
      attributes {
        title
      }
    }
    meta {
      pagination {
        page
        pageCount
        pageSize
        total
      }
    }
  }
}
    `;
/**
 * __useSessionListQuery__
 *
 * To run a query within a React component, call `useSessionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSessionListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(SessionListDocument, options);
}
export function useSessionListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(SessionListDocument, options);
}
