import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export const StyledButton = styled(Button)(() => ({
  minWidth: "fit-content",
  margin: "10px 5px",
}));

export const FormActionsWrapperStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
}));
