import { AutocompleteProps, IAutocompleteOption } from "../../types";
import { renderDefaultOption, renderDefaultTags } from "../../utils";
import { InfiniteScrollComponent } from "../InfiniteScroll";
import useAutocompleteStyles from "./Autocomplete.styles";
import { CircularProgress } from "@mui/material";
import MuiAutocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDebounce } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import React, { SyntheticEvent, useEffect, useState } from "react";

const Autocomplete = <T,>(props: AutocompleteProps) => {
  const {
    label,
    placeholder,
    options,
    error,
    helperText,
    fetchMoreData,
    hasMore,
    TextFieldProps,
    onSearchInput,
    blurOnSelect = true,
    renderOption = renderDefaultOption,
    renderTags = renderDefaultTags,
    ...rest
  } = props;

  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const { t } = useTranslation();
  const { classes } = useAutocompleteStyles();

  const debouncedSearchValue = useDebounce(searchValue, 350);

  const handleInputChange = (event: SyntheticEvent, value: string, reason: string) => {
    setInputValue(value);
    if (reason === "input") {
      setSearchValue(value);
    } else if ((reason === "clear" || reason === "reset") && !value) {
      setSearchValue("");
    }
  };

  const isOptionEqualToValue = (option: IAutocompleteOption<T>, value: IAutocompleteOption<T> | IAutocompleteOption<T>[]) => {
    if (Array.isArray(value)) {
      return value.some(item => item?.key === option?.key);
    } else {
      return option?.key === value?.key;
    }
  };

  useEffect(() => {
    onSearchInput?.(debouncedSearchValue);
  }, [debouncedSearchValue]);

  return (
    <MuiAutocomplete
      options={options ?? []}
      noOptionsText={t("No options")}
      loadingText={t("Loading...")}
      className={classes.autocomplete}
      classes={{
        paper: options?.length! > 5 ? classes.paper : `${classes.paper} ${classes.height}`,
        tag: classes.tag,
      }}
      inputValue={inputValue}
      getOptionLabel={option => option?.label}
      onInputChange={handleInputChange}
      blurOnSelect={blurOnSelect}
      ListboxComponent={listBoxProps => (
        <InfiniteScrollComponent {...listBoxProps} options={options} fetchMoreData={fetchMoreData!} hasMore={hasMore} />
      )}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={renderOption}
      renderTags={renderTags}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          placeholder={props?.multiple && props?.value?.length ? "" : placeholder}
          error={error}
          helperText={helperText}
          variant={"filled"}
          className={classes.textField}
          fullWidth
          hiddenLabel
          autoComplete='off'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {props?.loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          {...TextFieldProps}
        />
      )}
      {...rest}
    />
  );
};

export default Autocomplete;
