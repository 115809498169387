import { zodSchema } from "../../../../zod";
import { z } from "zod";
import { i18n } from "@toolkit/i18n";
import { createZodAutocompleteObject } from "@toolkit/ui";
import { ActionFieldType } from "@health/queries/types";
export const DecisionActionFieldsFormSchema = z
    .object({
    code: z.string(),
    display: z.string().nullable().optional(),
    type: z.nativeEnum(ActionFieldType),
    isRequired: z.boolean(),
    allowedValues: z.any().optional(),
    valueBoolean: z.boolean().nullable().optional(),
    valueNumber: z.number().nullable().optional(),
    valueString: z.string().or(createZodAutocompleteObject()).or(zodSchema.decisionActionAutocompleteOptional).nullable().optional(),
    valueList: zodSchema.decisionActionAutocompleteOptional,
})
    .superRefine((data, ctx) => {
    if (data.type === ActionFieldType.String && data.isRequired && !data.valueString) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t("Required", { ns: "domains" }),
            path: ["valueString"],
        });
    }
    if (data.type === ActionFieldType.Number && data.isRequired && (data.valueNumber === undefined || data.valueNumber === null)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t("Required", { ns: "domains" }),
            path: ["valueNumber"],
        });
    }
    if (data.type === ActionFieldType.List && data.isRequired && !data.valueList) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t("Required", { ns: "domains" }),
            path: ["valueList"],
        });
    }
});
