import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { useStepperStyle } from "./styles";
const StepperComponent: FunctionComponent<
  PropsWithChildren<{
    activeStepIndex: number;
    isStepOptional?: (step: number) => boolean;
    isStepSkipped?: (step: number) => boolean;
    steps: string[];
    stepProps?: { completed?: boolean };
    labelProps?: { optional?: React.ReactNode };
  }>
> = props => {
  const { activeStepIndex, isStepOptional, isStepSkipped, steps, children } = props;
  const { classes } = useStepperStyle();
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStepIndex} className={classes.stepper}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepOptional?.(index)) {
            labelProps.optional = <></>;
          }
          if (isStepSkipped?.(index)) {
            stepProps.completed = false;
          }

          return (
            <Step key={label} {...stepProps} sx={{ mb: 3 }}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStepIndex === steps.length ? <React.Fragment></React.Fragment> : <React.Fragment>{children}</React.Fragment>}
    </Box>
  );
};
export default StepperComponent;
