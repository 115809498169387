import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  root: {
    width: 15,
    height: 17,
  },
}));

export const CopyIcon: FC<SvgIconProps> = ({ width, height, fill, viewBox, ...props }) => {
  const { classes, theme } = useStyles();
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "16px"}
      height={height ? height : "18px"}
      viewBox={viewBox ? viewBox : "0 0 16.712 18.991"}
      fill={fill ? fill : theme.palette.primary.main}
      classes={{ root: classes.root }}
      {...props}
    >
      <path
        id='icons8-copy'
        d='M9.318,2A1.519,1.519,0,0,0,7.8,3.519V15.674a1.519,1.519,0,0,0,1.519,1.519h9.875a1.519,1.519,0,0,0,1.519-1.519V6.938A.759.759,0,0,0,20.49,6.4L16.312,2.223A.759.759,0,0,0,15.775,2Zm6.077,1.447,3.871,3.871H16.154a.76.76,0,0,1-.76-.76ZM5.519,5.8A1.519,1.519,0,0,0,4,7.318V19.472a1.519,1.519,0,0,0,1.519,1.519h9.875a1.519,1.519,0,0,0,1.519-1.519v-.76h-7.6a3.042,3.042,0,0,1-3.039-3.039V5.8Z'
        transform='translate(-4 -2)'
        fill={fill ? fill : theme.palette.primary.main}
      />
    </SvgIcon>
  );
};
