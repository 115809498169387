import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const decisionActionAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
    nameAr: z.string().nullable().optional(),
}));
export const DecisionActionAutocompleteSchemas = {
    decisionActionAutocomplete,
    decisionActionAutocompleteOptional: decisionActionAutocomplete.nullable().optional(),
};
