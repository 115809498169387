/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductVariantImageUnassignMutationVariables = Types.Exact<{
  imageId: Types.Scalars['ID'];
  variantId: Types.Scalars['ID'];
}>;


export type ProductVariantImageUnassignMutation = { __typename?: 'Mutation', productVariantImageUnassign?: { __typename?: 'ProductVariantImageUnassign', image?: { __typename?: 'ProductImage', url: string, id: string, alt: string } | null, productErrors: Array<{ __typename?: 'ProductError', code: Types.ProductErrorCode, field?: string | null, message?: string | null }> } | null };


export const ProductVariantImageUnassignDocument = gql`
    mutation productVariantImageUnassign($imageId: ID!, $variantId: ID!) {
  productVariantImageUnassign(imageId: $imageId, variantId: $variantId) {
    image {
      url
      id
      alt
    }
    productErrors {
      code
      field
      message
    }
  }
}
    `;
export type ProductVariantImageUnassignMutationFn = Apollo.MutationFunction<ProductVariantImageUnassignMutation, ProductVariantImageUnassignMutationVariables>;

/**
 * __useProductVariantImageUnassignMutation__
 *
 * To run a mutation, you first call `useProductVariantImageUnassignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductVariantImageUnassignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productVariantImageUnassignMutation, { data, loading, error }] = useProductVariantImageUnassignMutation({
 *   variables: {
 *      imageId: // value for 'imageId'
 *      variantId: // value for 'variantId'
 *   },
 * });
 */
export function useProductVariantImageUnassignMutation(baseOptions?: Apollo.MutationHookOptions<ProductVariantImageUnassignMutation, ProductVariantImageUnassignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductVariantImageUnassignMutation, ProductVariantImageUnassignMutationVariables>(ProductVariantImageUnassignDocument, options);
      }
export type ProductVariantImageUnassignMutationHookResult = ReturnType<typeof useProductVariantImageUnassignMutation>;
export type ProductVariantImageUnassignMutationResult = Apollo.MutationResult<ProductVariantImageUnassignMutation>;
export type ProductVariantImageUnassignMutationOptions = Apollo.BaseMutationOptions<ProductVariantImageUnassignMutation, ProductVariantImageUnassignMutationVariables>;