import { i18n } from "@toolkit/i18n";
export var GuidedCareTaskFilter;
(function (GuidedCareTaskFilter) {
    GuidedCareTaskFilter["All"] = "All";
    GuidedCareTaskFilter["Completed"] = "Completed";
    GuidedCareTaskFilter["Uncompleted"] = "Uncompleted";
})(GuidedCareTaskFilter || (GuidedCareTaskFilter = {}));
export const guidedCareTaskFilterOptionsMap = {
    [GuidedCareTaskFilter.All]: {
        key: GuidedCareTaskFilter.All,
        get label() {
            return i18n.t("All", { ns: "domains" });
        },
        value: GuidedCareTaskFilter.All,
    },
    [GuidedCareTaskFilter.Completed]: {
        key: GuidedCareTaskFilter.Completed,
        get label() {
            return i18n.t("Completed", { ns: "domains" });
        },
        value: GuidedCareTaskFilter.Completed,
    },
    [GuidedCareTaskFilter.Uncompleted]: {
        key: GuidedCareTaskFilter.Uncompleted,
        get label() {
            return i18n.t("Uncompleted", { ns: "domains" });
        },
        value: GuidedCareTaskFilter.Uncompleted,
    },
};
export const guidedCareTaskFilterOptions = Object.values(guidedCareTaskFilterOptionsMap);
