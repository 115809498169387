import { TextFieldProps } from "@mui/material/TextField";
import { produce } from "immer";
import _ from "lodash";
import React, { FC } from "react";
import { ControllerProps, useFormContext } from "react-hook-form";
import { useAutocompleteCustomHook } from "../../hooks";
import { AutocompleteComponentProps } from "../Autocomplete/types";
import { GQuery } from "../GridProvider";
import { AutocompleteController } from "./AutocompleteController";

// TODO Types
export const SmartAutocompleteController: FC<{
  useAutocompleteControllerQuery: GQuery<any>;
  ControllerProps: Partial<ControllerProps<any, any>>;
  TextFieldProps: Partial<TextFieldProps>;
  filters: Record<string, any>;
  queryVariables?: any;
  dataAccessor: string;
  multiple?: boolean;
  filterSearchKey?: string;
  getOptionLabel?: (option: any) => string;
  onChange?: (option: any) => void;
  isFilterSearchKeyAQueryVariable?: boolean;
}> = ({
  useAutocompleteControllerQuery,
  dataAccessor,
  filters,
  queryVariables = {},
  filterSearchKey,
  ControllerProps,
  onChange,
  getOptionLabel,
  multiple,
  TextFieldProps,
  isFilterSearchKeyAQueryVariable,
}) => {
  const { data, isLoading, onFetchMoreData, refetch } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery,
    input: {
      first: 10,
      after: null,
      before: null,
      last: null,
      ...queryVariables,
      filter: filters ? filters : null,
    },
    dataAccessor,
    skip: !useAutocompleteControllerQuery,
  });

  const { watch } = useFormContext();

  const rootObj = _.get(data, dataAccessor);

  const nodes = rootObj?.edges ? rootObj?.edges?.map((edge: { node: any }) => edge?.node)! : rootObj || [];

  const pageInfo = data?.[dataAccessor]?.pageInfo;

  const handleSearchInput = (search: string) => {
    const fieldValue = watch(ControllerProps?.name);
    if (search !== String(getOptionLabel?.(fieldValue) ?? fieldValue?.name)) {
      const refetchVariables = { filter: { ...filters } };
      refetch(
        produce(refetchVariables, draft => {
          if (!filterSearchKey) return;
          if (isFilterSearchKeyAQueryVariable) {
            _.set(draft, filterSearchKey, search);
          } else {
            draft.filter[filterSearchKey] = search;
          }
        })
      );
    }
  };

  const handleChange: AutocompleteComponentProps["onChange"] = (_, v) => onChange?.(v);

  return (
    <AutocompleteController
      ControllerProps={ControllerProps}
      multiple={multiple}
      loading={isLoading}
      fetchMoreData={onFetchMoreData}
      onSearchInput={handleSearchInput}
      hasMore={Boolean(pageInfo?.hasNextPage)}
      onChange={handleChange}
      getOptionLabel={option => getOptionLabel?.(option) ?? option?.name}
      options={nodes || []}
      TextFieldProps={TextFieldProps}
    />
  );
};

SmartAutocompleteController.defaultProps = {
  multiple: false,
  filterSearchKey: "name_Icontains",
  isFilterSearchKeyAQueryVariable: false,
  getOptionLabel: o => {
    console.warn("SmartAutocompleteController getOptionLabel  is not implemented. ");

    if (typeof o === "string") return o;
    if ("label" in o) return o.label;
    if ("display" in o) return o.display;
  },
  queryVariables: {},
  onChange: () => {
    console.log("SmartAutocompleteController onChange is not implemented. ");
  },
};
