import Card, { CardProps } from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { Loader } from "../ContentLoader";

const useFormCardStyles = makeStyles()(theme => ({
  card: {
    boxShadow: theme.mixins.shadows.none,
    padding: "15px",
  },
  header: {
    height: 45,
  },
  cardHeader: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

interface IFormCardProps extends CardProps {
  loading: boolean | undefined;
  children: React.ReactNode;
  doYouHaveData: boolean;
  title?: string;
  cardHeader?: React.ReactNode;
  height?: string;
}

export const FormCard: FC<IFormCardProps> = ({ children, loading, title, doYouHaveData, cardHeader, height, ...props }) => {
  const { classes, theme } = useFormCardStyles();

  return (
    <Card className={classes.card} {...props}>
      {cardHeader && <div className={classes.cardHeader}> {cardHeader}</div>}
      <Grid container spacing={1}>
        {title && (
          <Grid item xs={12} sx={{ height: height ? height : 45 }}>
            <Typography fontSize={theme.mixins.fonts.fontSize.md} fontFamily={theme.mixins.fonts.fontFamily.medium}>
              {title}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <div>{loading ? <Loader /> : doYouHaveData && <React.Fragment>{children}</React.Fragment>}</div>
        </Grid>
      </Grid>
    </Card>
  );
};
FormCard.defaultProps = {
  cardHeader: undefined,
  height: undefined,
  title: undefined,
};
