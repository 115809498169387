/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type ProductFragmentFragment = { __typename?: 'Product', isPublished: boolean, name: string, nameAr?: string | null, id: string, isAddon?: boolean | null, hasAddons?: boolean | null, benefits?: string | null, howToUse?: string | null, attributes: Array<{ __typename?: 'SelectedAttribute', attribute: { __typename?: 'Attribute', name?: string | null, id: string, values?: Array<{ __typename?: 'AttributeValue', name?: string | null, id: string } | null> | null } }>, variants?: Array<{ __typename?: 'ProductVariant', id: string, sku: string, trackInventory: boolean } | null> | null };

export const ProductFragmentFragmentDoc = gql`
    fragment ProductFragment on Product {
  isPublished
  name
  nameAr
  id
  isAddon
  hasAddons
  benefits
  howToUse
  attributes {
    attribute {
      name
      id
      values {
        name
        id
      }
    }
  }
  variants {
    id
    sku
    trackInventory
  }
}
    `;