import { formatMessageErrors } from "@toolkit/apollo";
import { formatUserNameFull } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, CustomToggleButton, Typography, useAddToast, useTheme } from "@toolkit/ui";
import { FC, useState } from "react";
import useToggle from "shared/hooks/useToggle";
import { useHealthProgramNetworkProviderProfessionalUpdateMutation } from "../../gql";

export const ActiveToggleSwitch: FC<{ row }> = ({ row }) => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const [check, setCheck] = useState<{ isCheck: boolean; id: string }[]>([]);
  const { open, handleToggle } = useToggle();

  const [healthProgramNetworkProviderProfessionalUpdate] = useHealthProgramNetworkProviderProfessionalUpdateMutation({
    onCompleted: data => {
      const errors = data?.healthProgramNetworkProviderProfessionalUpdate?.entityErrors;
      if (errors?.length === 0) {
        succeeded(t("Network Provider Professional updated successfully"));
      } else {
        failed(formatMessageErrors(errors));
      }
    },
    onError: () => {
      failed(t("Network Provider Professional Update Failed"));
    },
  });
  const handleChangeToggle = id => e => {
    healthProgramNetworkProviderProfessionalUpdate({
      variables: {
        id,
        input: {
          overridingVirtualCallEnabled: e.target.checked,
        },
      },
    }).then(({ data }) => {
      const errors = data?.healthProgramNetworkProviderProfessionalUpdate?.entityErrors;
      !errors?.length && setCheck([...(check || []), { id, isCheck: e.target.checked }]);
    });
  };

  const healthProgramNetworkProviderProfessionals = row?.healthProgramNetworkProviderProfessionals?.edges?.map(item => item?.node);
  const theme = useTheme();
  return (
    <CustomDialog
      maxWidth='sm'
      scrollType='body'
      open={open}
      hasClose
      onCloseModal={handleToggle}
      title={t("Health Program Network Provider Professionals")}
      footerComponent={<Button onClick={handleToggle}>{t("Close")}</Button>}
      button={
        <Button variant='outlined' size='small' onClick={handleToggle}>
          {t("Show")}
        </Button>
      }
    >
      {healthProgramNetworkProviderProfessionals?.length ? (
        healthProgramNetworkProviderProfessionals?.map((item, index) => (
          <Box key={item?.id} display='flex' alignItems='center' justifyContent='space-between'>
            <Typography fontSize={theme.mixins.fonts.fontSize.sm}>
              {formatUserNameFull(item?.professional?.user) || t("Professional")}
            </Typography>
            <CustomToggleButton
              checked={check?.[index]?.isCheck || item?.overridingVirtualCallEnabled || false}
              onChange={handleChangeToggle(item?.id)}
            />
          </Box>
        ))
      ) : (
        <Typography fontFamily={theme.mixins.fonts.fontFamily.medium}>{t("There is No Professionals")}</Typography>
      )}
    </CustomDialog>
  );
};
