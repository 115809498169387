import { makeStyles } from "../../../mui";

export const usePhoneNumberStyles = makeStyles<{ inputWidth: number | string }>()((theme, { inputWidth }) => ({
  focusedTextField: {
    "& .MuiFilledInput-root": {
      borderRadius: "10px 10px 0px 0px!important",
      borderColor: `${theme.palette.stale.main} !important`,
    },
  },
  selectFilled: {
    display: "none",
  },
  paper: {
    boxShadow: theme.mixins.shadows.none,
    border: "1px solid",
    borderColor: theme.palette.stale.main,
    borderTop: 0,
    height: "200px",
    position: "absolute",
    width: inputWidth,
    top: 0,
    left: 0,
    padding: 0,
    borderRadius: "0px 0px 10px 10px",
    overflow: "scroll",
    zIndex: 1000,
  },
  marginStart: {
    marginInlineStart: "8px",
  },
  select: {
    width: "max-content",
    ".MuiSelect-select": {
      padding: "8px",
      paddingRight: "24px !important",
    },
    svg: {
      right: 0,
    },
  },
  list: {
    width: inputWidth,
    padding: 0,
    "& li": {
      padding: "8px 16px",
      "&:hover": {
        backgroundColor: theme.palette.gray.light,
      },
    },
  },
}));
