import { getAutocompleteFilter } from "@toolkit/ui";
import { usePayersAutocompleteQuery } from "./gql";
export const getPayersAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "payers",
        query: usePayersAutocompleteQuery,
        labelBy: "name",
        arabicLabelBy: "nameAr",
        backendAccessor: "id",
    });
};
