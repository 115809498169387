import { H_SiteSettingsInput, SiteSettingsError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { useSiteSettingsUpdateMutation } from "gql/mutations";
import { settingsTitleBreadcrumb } from "pages/Settings/constants/settings-breadcrumbs";
import { useEffect, useState } from "react";
import { SiteSettingsDocument, useHealthProgramsSiteSettingsUpdateMutation, useSiteSettingsQuery } from "./gql";
import { minutesToSeconds, siteSetting } from "./utils";

export const useSettingsPage = () => {
  const { succeeded, failed } = useAddToast();
  const { setBreadCrumb } = useBreadCrumbs();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation("admin");
  const [error, setError] = useState<SiteSettingsError[]>([]);
  const [siteSettingView, setView] = useState(siteSetting.siteSettings);

  const { data, loading } = useSiteSettingsQuery();
  const siteSettings = data?.siteSettings;

  const [siteSettingsUpdateMutation] = useSiteSettingsUpdateMutation({
    onCompleted: response => {
      const siteSettingsErrors = response.siteSettingsUpdate?.siteSettingsErrors;
      if (siteSettingsErrors && siteSettingsErrors?.length > 0) {
        setError(siteSettingsErrors);
        failed(formatMessageErrors(siteSettingsErrors));
      } else {
        setError([]);
        succeeded(t("Site Settings Updated Successfully"));
      }
      setIsSubmitting(false);
    },
    onError: () => {
      failed(t("Site Settings  Updated Failed"));
      setIsSubmitting(false);
    },
    refetchQueries: [{ query: SiteSettingsDocument }],
  });

  const [healthProgramsSiteSettingsUpdate] = useHealthProgramsSiteSettingsUpdateMutation({
    onCompleted: response => {
      const healthProgramsSiteSettingsUpdate = response.healthProgramsSiteSettingsUpdate;
      if (healthProgramsSiteSettingsUpdate) {
        succeeded(t("Health Programs Site Settings Updated Successfully"));
      }
      setIsSubmitting(false);
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(message);
      setIsSubmitting(false);
    },
    refetchQueries: [{ query: SiteSettingsDocument }],
  });

  const handleUpdate = formData => {
    const request = {
      ...formData,
      vatPercentage: Number(formData.vatPercentage),
      orderBaseDeliveryFee: Number(formData.orderBaseDeliveryFee),
      androidVersion: Number(formData?.androidVersion),
      iosVersion: Number(formData?.iosVersion),
      marketplaceConsumedTimeoutAlert: Number(formData?.marketplaceConsumedTimeoutAlert),
      marketplaceConsumedTimeoutSeconds: Number(formData?.marketplaceConsumedTimeoutSeconds),
      marketplaceDeliveredTimeoutAlert: Number(formData?.marketplaceDeliveredTimeoutAlert),
      marketplaceDeliveredTimeoutSeconds: Number(formData?.marketplaceDeliveredTimeoutSeconds),
      marketplaceOrderPaymentTimeoutSeconds: Number(formData?.marketplaceOrderPaymentTimeoutSeconds),
      marketplaceOutForDeliveryTimeoutAlert: Number(formData?.marketplaceOutForDeliveryTimeoutAlert),
      marketplaceOutForDeliveryTimeoutSeconds: Number(formData?.marketplaceOutForDeliveryTimeoutSeconds),
      marketplaceReturnedTimeoutAlert: Number(formData?.marketplaceReturnedTimeoutAlert),
      marketplaceReturnedTimeoutSeconds: Number(formData?.marketplaceReturnedTimeoutSeconds),
      marketplaceVendorResponseTimeoutAlert: Number(formData?.marketplaceVendorResponseTimeoutAlert),
      marketplaceVendorResponseTimeoutSeconds: Number(formData?.marketplaceVendorResponseTimeoutSeconds),
      orderAcceptanceTimeout: Number(formData?.orderAcceptanceTimeout),
      orderDeliveryTimeout: Number(formData?.orderDeliveryTimeout),
      orderDispensingTimeout: Number(formData?.orderDispensingTimeout),
      orderDispensingTimeoutAlert: Number(formData?.orderDispensingTimeoutAlert),
      orderErxHubApprovalResubmitTimeout: Number(formData?.orderErxHubApprovalResubmitTimeout),
      orderErxHubApprovalTimeout: Number(formData?.orderErxHubApprovalTimeout),
      orderErxHubApprovalTimeoutAlert: Number(formData?.orderErxHubApprovalTimeoutAlert),
      prescriptionOrderPaymentTimeoutSeconds: Number(formData?.prescriptionOrderPaymentTimeoutSeconds),
      defaultWeightUnit: formData?.defaultWeightUnit?.value,
    };
    siteSettingsUpdateMutation({
      variables: {
        input: {
          ...request,
        },
      },
    });
  };
  const handleHealthProgramsSiteSettingsUpdate = input => {
    const request: H_SiteSettingsInput = {
      doctorVisitAcceptanceTimeoutSeconds: minutesToSeconds(Number(input.doctorVisitAcceptanceTimeout)),
      messengerCallMinimumDurationSeconds: minutesToSeconds(Number(input.messengerCallMinimumDuration)),
      appointmentReminderTimeSeconds: minutesToSeconds(Number(input.appointmentReminderTime)),
      healthProgramVisitWaitTimeBeforeCallSeconds: minutesToSeconds(Number(input?.healthProgramVisitWaitTimeBeforeCall)),
      vatPercentageForMedicalServices: Number(input?.vatPercentageForMedicalServices),
    };

    healthProgramsSiteSettingsUpdate({
      variables: {
        input: request,
      },
    });
  };

  const onSubmit = input => {
    setIsSubmitting(true);
    siteSettingView === siteSetting.siteSettings ? handleUpdate(input) : handleHealthProgramsSiteSettingsUpdate(input);
  };

  const handleChangeView = view => () => {
    setView(view);
  };

  useEffect(() => {
    setBreadCrumb({
      title: settingsTitleBreadcrumb(t),
    });
  }, []);

  return {
    error,
    loading,
    isSubmitting,
    siteSettings,
    siteSettingView,
    onSubmit,
    handleChangeView,
  };
};
