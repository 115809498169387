import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

export const useIsMobileView = () => {
  const matches = useMediaQuery("(max-width:800px)");
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    setIsMobileView(isMobile);
  }, [isMobile]);

  return isMobileView || matches;
};
