/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsurancePoliciesQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.InsurancePolicyFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type InsurancePoliciesQuery = { __typename?: 'Query', insurancePolicies: { __typename?: 'InsurancePolicyConnection', totalCount?: number | null, pageInfo: { __typename?: 'H_PageInfo', startCursor?: string | null, hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'InsurancePolicyCountableEdge', node: { __typename?: 'InsurancePolicy', id: string, isActive?: boolean | null, name?: string | null, modified?: any | null, created?: any | null, number?: string | null, network?: { __typename?: 'InsuranceNetwork', id: string, name?: string | null } | null, outpatientPolicy?: { __typename?: 'InsuranceOutpatientPolicy', cons?: string | null, created?: any | null, dental?: string | null, id: string, modified?: any | null, optical?: boolean | null, outpatient?: boolean | null, pharm?: string | null } | null } }> } };


export const InsurancePoliciesDocument = gql`
    query InsurancePolicies($after: String, $before: String, $filter: InsurancePolicyFilterInput, $first: Int, $last: Int) {
  insurancePolicies(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
  ) {
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    totalCount
    edges {
      node {
        id
        isActive
        name
        modified
        created
        number
        network {
          id
          name
        }
        outpatientPolicy {
          cons
          created
          dental
          id
          modified
          optical
          outpatient
          pharm
        }
      }
    }
  }
}
    `;

/**
 * __useInsurancePoliciesQuery__
 *
 * To run a query within a React component, call `useInsurancePoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsurancePoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsurancePoliciesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useInsurancePoliciesQuery(baseOptions?: Apollo.QueryHookOptions<InsurancePoliciesQuery, InsurancePoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsurancePoliciesQuery, InsurancePoliciesQueryVariables>(InsurancePoliciesDocument, options);
      }
export function useInsurancePoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsurancePoliciesQuery, InsurancePoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsurancePoliciesQuery, InsurancePoliciesQueryVariables>(InsurancePoliciesDocument, options);
        }
export type InsurancePoliciesQueryHookResult = ReturnType<typeof useInsurancePoliciesQuery>;
export type InsurancePoliciesLazyQueryHookResult = ReturnType<typeof useInsurancePoliciesLazyQuery>;
export type InsurancePoliciesQueryResult = Apollo.QueryResult<InsurancePoliciesQuery, InsurancePoliciesQueryVariables>;