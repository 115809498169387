/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type AttributeMutationFragmentFragment = { __typename?: 'Attribute', id: string, name?: string | null, values?: Array<{ __typename?: 'AttributeValue', name?: string | null, id: string, slug?: string | null } | null> | null };

export const AttributeMutationFragmentFragmentDoc = gql`
    fragment AttributeMutationFragment on Attribute {
  id
  name
  values {
    name
    id
    slug
  }
}
    `;