var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { KeywordSearch } from "@health/queries/types";
import React from "react";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { useSystemCodeAutocompleteQuery } from "./gql";
export const SystemCodeAutocomplete = props => {
    const { name, filter, skip, label, placeholder, isCodeHidden, context } = props, rest = __rest(props, ["name", "filter", "skip", "label", "placeholder", "isCodeHidden", "context"]);
    const { t } = useTranslation("domains");
    const { data, isLoading, hasMoreData, refetch, fetchMoreData } = useRelayStyleConnection({
        dataAccessor: "codeSystemConceptSearch",
        useQuery: useSystemCodeAutocompleteQuery,
        variables: Object.assign(Object.assign({}, filter), { keywordSearch: isCodeHidden ? KeywordSearch.Display : KeywordSearch.All }),
        skip: props.disabled || skip,
        context,
    });
    const options = mapToAutocompleteOptions(data, "code", item => (isCodeHidden ? item === null || item === void 0 ? void 0 : item.display : `${item === null || item === void 0 ? void 0 : item.code} - ${item.display}`));
    const onSearchInputChange = (value) => {
        refetch(Object.assign(Object.assign({}, filter), { keyword: value, first: 10 }));
        // to do : wael find better way to handle this case
    };
    return (React.createElement(FormAutocomplete, Object.assign({}, rest, { name: name, label: label || t("Code"), placeholder: placeholder || label || t("Code"), options: options, hasMore: hasMoreData, loading: isLoading, fetchMoreData: fetchMoreData, onSearchInput: onSearchInputChange })));
};
