import {
  formGirdBreakPoints,
  formGirdSpacing,
  getRequiredValidation,
  patternArabicTextWithSpecialCharAndNumbers,
  patternEmail,
} from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormPhoneInput, Grid, TextField, ToggleButtonController } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { IForm, IFormData } from "../types";
import { Tpo } from "./TPO";
import { useGetBackendErrorsForInputsValues } from "../hooks/setBackendsErrors";

export const GeneralInformation: FC<IForm> = ({ defaultValue, errors }) => {
  const { t } = useTranslation("admin");
  const {
    formState: { errors: reactFormErrors },
    setValue,
    register,
    control,
  } = useFormContext<IFormData>();

  useEffect(() => {
    setValue("isActive", defaultValue?.isActive ?? false);
    setValue("contactMobileNumber", defaultValue?.contactMobileNumber || "");
    setValue("contactPhoneNumber", defaultValue?.contactPhoneNumber || "");
  }, [defaultValue, setValue]);
  const { backendErrorStorage } = useGetBackendErrorsForInputsValues(errors, [
    "name",
    "nameAr",
    "contactName",
    "contactEmail",
    "contactMobileNumber",
    "contactPhoneNumber",
    "licenseNumber",
  ]);

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Name")}
          error={Boolean(reactFormErrors?.name?.message!)}
          helperText={t(reactFormErrors?.name?.message!)}
          defaultValue={defaultValue?.name}
          {...register("name", { required: getRequiredValidation(t, true), validate: value => backendErrorStorage["name"][value] })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Arabic Name")}
          error={Boolean(reactFormErrors?.nameAr?.message!)}
          helperText={t(reactFormErrors?.nameAr?.message!)}
          defaultValue={defaultValue?.nameAr}
          {...register("nameAr", {
            pattern: patternArabicTextWithSpecialCharAndNumbers,
            validate: value => backendErrorStorage["nameAr"][value],
          })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Contact Name")}
          error={Boolean(reactFormErrors?.contactName?.message!)}
          helperText={t(reactFormErrors?.contactName?.message!)}
          defaultValue={defaultValue?.contactName}
          {...register("contactName", {
            required: getRequiredValidation(t, true),
            validate: value => backendErrorStorage["contactName"][value],
          })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("Contact Email")}
          error={Boolean(reactFormErrors?.contactEmail?.message!)}
          helperText={t(reactFormErrors?.contactEmail?.message!)}
          defaultValue={defaultValue?.contactEmail}
          {...register("contactEmail", {
            required: getRequiredValidation(t, true),
            pattern: patternEmail(t),
            validate: value => backendErrorStorage["contactEmail"][value],
          })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormPhoneInput
          {...register("contactMobileNumber", {
            required: getRequiredValidation(t, false),
          })}
          label={t("Contact Mobile Number")}
          error={Boolean(reactFormErrors?.["contactMobileNumber"]?.message)}
          helperText={t(reactFormErrors?.["contactMobileNumber"]?.message as string)}
          fullWidth
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormPhoneInput
          {...register("contactPhoneNumber", {
            required: getRequiredValidation(t, false),
          })}
          label={t("Contact Phone Number")}
          error={Boolean(reactFormErrors?.["contactPhoneNumber"]?.message)}
          helperText={t(reactFormErrors?.["contactPhoneNumber"]?.message as string)}
          fullWidth
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          size='small'
          label={t("License Number")}
          error={Boolean(reactFormErrors?.licenseNumber?.message!)}
          helperText={t(reactFormErrors.licenseNumber?.message!)}
          defaultValue={defaultValue?.licenseNumber}
          {...register("licenseNumber", {
            required: getRequiredValidation(t, true),
            validate: value => backendErrorStorage["licenseNumber"][value],
          })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <Tpo errors={errors} defaultValue={defaultValue} />
      </Grid>
      <Grid container columnSpacing={formGirdSpacing} item alignItems='center'>
        <Grid item {...formGirdBreakPoints}>
          <ToggleButtonController
            {...register("isActive")}
            control={control}
            defaultValue={defaultValue?.isActive ?? false}
            label={t("Is Active")}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
