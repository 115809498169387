import { getEnvVariable } from "@health/env";
import { BulkProcessingStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, ExcelIcon, Typography, useAddToast, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useDownloadBulkFileLazyQuery } from "./gql";

export const DownloadBulkFile: FC<{ isButton: boolean; title: string; id: string; processingStatus: BulkProcessingStatus }> = ({
  isButton,
  title,
  id,
  processingStatus,
}) => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const [downloadFile, { loading: isDownloading }] = useDownloadBulkFileLazyQuery();
  const handleGetTemplate = () => {
    downloadFile({
      variables: {
        fileId: id,
      },
    })
      .then(e => {
        if (e.data?.downloadBulkFile) {
          window.open(getEnvVariable("SERVER_URL") + e.data?.downloadBulkFile, "_self");
          succeeded(t("File downloaded successfully"));
        } else {
          failed(t("Failed to download file"));
        }
      })
      .catch(() => t("Failed to download list"));
  };
  const theme = useTheme();
  return !isButton ? (
    <Typography
      fontSize={theme.mixins.fonts.fontSize.sm}
      sx={{ textDecoration: "underline", cursor: "pointer" }}
      onClick={handleGetTemplate}
    >
      {title}
    </Typography>
  ) : (
    <Button
      sx={{ paddingInline: 6, borderRadius: "10px", whiteSpace: "nowrap" }}
      startIcon={<ExcelIcon />}
      disabled={processingStatus == BulkProcessingStatus.Pending || processingStatus == BulkProcessingStatus.Cancelled || isDownloading}
      onClick={handleGetTemplate}
    >
      {title}
    </Button>
  );
};
