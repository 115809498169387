import { jsx as _jsx } from "react/jsx-runtime";
import { medicalFormCategoryOptionsMap, medicalFormTypeOptionsMap, questionTypeOptionsMap } from "@health/enum-options";
import { QuestionType } from "@health/queries/types";
import { AlignLeftIcon, CheckboxIcon, ExpandCircleDownRounded, ThumbsUpDown, createAutocompleteOption } from "@toolkit/ui";
import { uniqueId } from "lodash";
export const shouldQuestionHaveOptions = (questionType) => {
    return questionType && questionType !== QuestionType.String;
};
export const getOptionIcon = (questionType, primary) => {
    switch (questionType) {
        case QuestionType.Boolean:
            return _jsx(ThumbsUpDown, { sx: { color: primary } });
        case QuestionType.MultiChoice:
            return _jsx(CheckboxIcon, { fill: primary });
        case QuestionType.SingleChoice:
            return _jsx(ExpandCircleDownRounded, { fill: primary });
        case QuestionType.String:
            return _jsx(AlignLeftIcon, { fill: primary });
    }
};
export const convertMedicalFormToFormValues = (medicalForm) => {
    var _a, _b, _c, _d;
    return {
        active: medicalForm.active,
        name: medicalForm.name,
        nameAr: medicalForm.nameAr,
        code: medicalForm.code,
        category: medicalFormCategoryOptionsMap[medicalForm === null || medicalForm === void 0 ? void 0 : medicalForm.category],
        type: medicalFormTypeOptionsMap[medicalForm === null || medicalForm === void 0 ? void 0 : medicalForm.type],
        description: medicalForm.description,
        department: medicalForm.department
            ? createAutocompleteOption({
                id: (_a = medicalForm === null || medicalForm === void 0 ? void 0 : medicalForm.department) === null || _a === void 0 ? void 0 : _a.id,
                name: (_b = medicalForm === null || medicalForm === void 0 ? void 0 : medicalForm.department) === null || _b === void 0 ? void 0 : _b.name,
            }, "id", "name")
            : undefined,
        medicalFormClassifications: ((_c = medicalForm.medicalFormClassifications) === null || _c === void 0 ? void 0 : _c.map(item => ({
            name: item === null || item === void 0 ? void 0 : item.name,
            rangeFrom: item === null || item === void 0 ? void 0 : item.rangeFrom,
            rangeTo: item === null || item === void 0 ? void 0 : item.rangeTo,
        }))) || [],
        questions: (_d = medicalForm.questions) === null || _d === void 0 ? void 0 : _d.map(item => {
            var _a;
            return ({
                question: item.question,
                questionType: questionTypeOptionsMap[item === null || item === void 0 ? void 0 : item.questionType],
                required: !!item.required,
                options: ((_a = item.options) === null || _a === void 0 ? void 0 : _a.map(option => {
                    var _a;
                    const optionScore = (_a = item.questionOptionScores) === null || _a === void 0 ? void 0 : _a.find(score => option === (score === null || score === void 0 ? void 0 : score.option));
                    return {
                        id: optionScore === null || optionScore === void 0 ? void 0 : optionScore.id,
                        value: option,
                        score: optionScore === null || optionScore === void 0 ? void 0 : optionScore.score,
                    };
                })) || [],
            });
        }),
    };
};
export const createDefaultQuestionOptions = (questionType) => {
    if (questionType === QuestionType.Boolean) {
        return [
            { id: "yes", value: "Yes", score: null },
            { id: "no", value: "No", score: null },
        ];
    }
    else {
        return [{ id: uniqueId("field-"), value: "", score: null }];
    }
};
