/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PayerFragmentFragmentDoc } from '../../fragments/__generated__/Payer';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPayerQueryVariables = Types.Exact<{
  payerId: Types.Scalars['ID'];
}>;


export type GetPayerQuery = { __typename?: 'Query', payer?: { __typename?: 'Payer', id: string, created: any, name: string, nameAr?: string | null, contactName?: string | null, contactPhoneNumber?: string | null, contactMobileNumber?: string | null, contactEmail?: string | null, isActive: boolean, licenseNumber: string, logo?: string | null, backgroundImage?: string | null, tpo: Types.PayerTpo } | null };


export const GetPayerDocument = gql`
    query GetPayer($payerId: ID!) {
  payer(id: $payerId) {
    ...PayerFragment
  }
}
    ${PayerFragmentFragmentDoc}`;

/**
 * __useGetPayerQuery__
 *
 * To run a query within a React component, call `useGetPayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayerQuery({
 *   variables: {
 *      payerId: // value for 'payerId'
 *   },
 * });
 */
export function useGetPayerQuery(baseOptions: Apollo.QueryHookOptions<GetPayerQuery, GetPayerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayerQuery, GetPayerQueryVariables>(GetPayerDocument, options);
      }
export function useGetPayerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayerQuery, GetPayerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayerQuery, GetPayerQueryVariables>(GetPayerDocument, options);
        }
export type GetPayerQueryHookResult = ReturnType<typeof useGetPayerQuery>;
export type GetPayerLazyQueryHookResult = ReturnType<typeof useGetPayerLazyQuery>;
export type GetPayerQueryResult = Apollo.QueryResult<GetPayerQuery, GetPayerQueryVariables>;