import { makeStyles } from "tss-react/mui";

// todo: make it customizable for color and background color
export const useFormToggleButtonGroupStyle = makeStyles()(theme => ({
  toggleButtonGroup: {
    "& .Mui-selected": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,

      "&:hover": {
        backgroundColor: theme.palette.success.main,
      },
    },
    boxShadow: theme.mixins.shadows.sm,
    border: "1px solid",
    borderColor: theme.palette.success.dark,
    borderRadius: "10px",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.success.main,
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
}));
