import { CalendarToday } from "@mui/icons-material";
import DateAdapter from "@mui/lab/AdapterMoment";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { InputAdornment, useTheme } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { DatePickerProps } from "@mui/x-date-pickers";
import { useTranslation } from "@toolkit/i18n";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { Control, Controller, ControllerProps, useFormContext } from "react-hook-form";

export type CustomDatePickerProps = TextFieldProps & {
  label: string;
  control: Control<any>;
  name: string;
  placeholder: string;
  format?: string;
  helperText?: string;
  error?: string | boolean;
  controllerProps?: Omit<ControllerProps, "render" | "name">;
  datePickerProps?: Omit<DatePickerProps, "onChange" | "value" | "renderInput">;
  shouldHaveDefaultValidation?: boolean;
};

export const CustomDatePicker: FC<CustomDatePickerProps> = ({
  format,
  label,
  control,
  name,
  placeholder,
  helperText,
  error,
  controllerProps,
  shouldHaveDefaultValidation = true,
  ...props
}) => {
  const [inputError, setInputError] = useState("");
  const { setValue } = useFormContext();
  const { t } = useTranslation();
  const isError = !!inputError || error;
  const theme = useTheme();
  useEffect(() => {
    props.defaultValue && setValue(name, props.defaultValue);
  }, [props.defaultValue]);

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Controller
        name={name}
        control={controllerProps?.control || control}
        defaultValue={controllerProps?.defaultValue || props.defaultValue}
        rules={controllerProps?.rules}
        render={({ field: { onChange: handleOnChange, value } }) => {
          const handleSelectedDate = (date: unknown, keyboardInputValue: unknown) => {
            handleOnChange(moment(date as string)?.isValid() ? moment(date as string).format(format) : null);
            if (shouldHaveDefaultValidation && keyboardInputValue && !moment(keyboardInputValue, "DD/MM/yyyy").isValid()) {
              setInputError(t("Invalid date"));
            } else {
              setInputError("");
            }
          };
          return (
            <DatePicker
              label={label}
              value={value ? moment(value, format) : null}
              onChange={handleSelectedDate}
              allowSameDateSelection={false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <CalendarToday />
                    {/* // TODO - replace with icon from MUI */}
                  </InputAdornment>
                ),
              }}
              renderInput={params => (
                <TextField
                  fullWidth
                  label={label}
                  error={error || isError}
                  helperText={inputError || helperText}
                  placeholder={placeholder}
                  required={props.required}
                  {...params}
                  sx={{
                    "& .MuiFormHelperText-root": {
                      color: isError ? theme.palette.secondary.main : "primary",
                    },
                  }}
                  color={isError ? "error" : "primary"}
                />
              )}
              {...props.datePickerProps}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

CustomDatePicker.defaultProps = {
  format: "yyyy-MM-DD",
  helperText: undefined,
  error: undefined,
  datePickerProps: {},
  controllerProps: {},
};
