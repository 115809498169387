import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Badge, Box, Button, Dialog, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "@toolkit/i18n";
import React, { useMemo, useState } from "react";
import { CustomMuiDialogActions, CustomMuiDialogContent, CustomMuiDialogTitle } from "../CustomMuiDialog";
import { WarningConfirmationDialog } from "../Dialogs";
import { SortableList } from "./components/SortableList/SortableList.components";
import { TablePreferencesContext } from "./TablePreferencesContext";
import { StyledIconButton, useTablePreferencesStyles } from "./TablePreferencesDialog.style";
import { TablePreferencesDialogProps } from "./TablePreferencesDialog.types";
import { useModal } from "../../hooks";
import { getColumnsForBackend } from "../GridProvider/utils";

// eslint-disable-next-line @typescript-eslint/ban-types
export const TablePreferencesDialog = <T extends object = {}>({
  savedPreferences,
  initialColumnPreferences,
  onApplyPreferences: onSubmit,
  isLoading,
}: TablePreferencesDialogProps<T>): React.ReactElement => {
  const filteredSavedPreferences = useMemo(() => savedPreferences?.filter(item => !item?.hideFromPreferencesAndTable), [savedPreferences]);
  const filteredInitialColumnPreferences = useMemo(
    () => initialColumnPreferences?.filter(item => !item?.hideFromPreferencesAndTable),
    [initialColumnPreferences]
  );
  const { t } = useTranslation();
  const [error, setError] = useState<string>();
  const { handleClose: _handleClose, handleOpen: _handleOpen, open: isOpen } = useModal();
  const {
    handleClose: handleCloseConfirmResetDialog,
    handleOpen: handleOpenConfirmResetDialog,
    open: isConfirmResetDialogOpen,
  } = useModal();
  const [columns, setColumns] = useState(filteredSavedPreferences || filteredInitialColumnPreferences);
  const value = useMemo(() => ({ columns, setColumns }), [columns]);
  const handleOpen = () => {
    setColumns(filteredSavedPreferences || filteredInitialColumnPreferences);
    _handleOpen();
  };
  const handleClose = () => {
    setError("");
    _handleClose();
  };
  const handleResetPreferencesTable = () => {
    const preferenceTable = initialColumnPreferences?.map(item => ({ ...item, isHidden: false }));
    const displayedColumns = preferenceTable?.filter(item => !item?.hideFromPreferencesAndTable);
    setColumns(displayedColumns);
    onSubmit(
      preferenceTable?.map(item => {
        return { key: item.key, isHidden: item?.isHidden };
      })
    );
    handleCloseConfirmResetDialog();
  };

  const handleApplyChanges = () => {
    const isValid = columns?.find(item => !item?.isHidden);
    if (isValid) {
      setError("");
      onSubmit?.(getColumnsForBackend(columns, initialColumnPreferences));
      handleClose();
    } else {
      setError(t("You should at least select one column to display"));
    }
  };
  const { classes, theme } = useTablePreferencesStyles();
  const isAnyOfSystemColumnsChanged = initialColumnPreferences?.length !== savedPreferences?.length;
  const isThereSavedViewSettings = savedPreferences?.length > 0;
  const anyOfSystemColumnsChangedUpdateMessage = t("Please reset your view to see the latest updates in the system columns.");
  const theresNoViewSettings = t("Save your current view settings for future use.");
  const isThereWarning = (isAnyOfSystemColumnsChanged || !isThereSavedViewSettings) && !isLoading;
  const warningMessage = isThereSavedViewSettings ? anyOfSystemColumnsChangedUpdateMessage : theresNoViewSettings;
  return (
    <TablePreferencesContext.Provider value={value}>
      <StyledIconButton color='primary' size='medium' onClick={handleOpen} sx={{ position: "relative" }}>
        <MoreVertIcon />
        {isThereWarning && (
          <Tooltip title={warningMessage}>
            <Badge
              variant='dot'
              color='error'
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                "& .MuiBadge-badge": {
                  height: "15px",
                  width: "15px",
                  borderRadius: "50%",
                },
              }}
            />
          </Tooltip>
        )}
      </StyledIconButton>
      <Dialog
        maxWidth={"sm"}
        fullWidth
        open={isOpen}
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
        scroll={"paper"}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <CustomMuiDialogTitle onClose={handleClose} title={t("Columns")} />
        <CustomMuiDialogContent>
          <Box py={2}>{isThereWarning && <Typography color={theme.palette.secondary.main}>{warningMessage}</Typography>}</Box>
          {columns && <SortableList />}
          <Typography color={theme.palette.secondary.main}>{error}</Typography>
        </CustomMuiDialogContent>
        <CustomMuiDialogActions className={classes.footer}>
          <Box>
            <Button onClick={handleApplyChanges}>{t("Save")}</Button>
            <Button variant='outlined' onClick={handleClose}>
              {t("Cancel")}
            </Button>
          </Box>
          <Button variant='outlined' disabled={!savedPreferences} onClick={handleOpenConfirmResetDialog}>
            {t("Reset")}
          </Button>
        </CustomMuiDialogActions>
      </Dialog>
      {isConfirmResetDialogOpen && (
        <WarningConfirmationDialog
          maxWidth='sm'
          isOpen={isConfirmResetDialogOpen}
          cancelText={t("Cancel")}
          confirmText={t("Confirm")}
          bodyText={t("Are you sure you want to reset table preferences?")}
          onClose={handleCloseConfirmResetDialog}
          onConfirm={handleResetPreferencesTable}
        />
      )}
    </TablePreferencesContext.Provider>
  );
};
