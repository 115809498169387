import { jsx as _jsx } from "react/jsx-runtime";
import { getMediaLink } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Avatar } from "@toolkit/ui";
import { useMemo } from "react";
export const useProductsColumns = () => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "img",
                header: t("Image"),
                accessor: ({ variant }) => {
                    var _a, _b, _c;
                    return (_jsx(Avatar, { sx: { width: 50, height: 50, borderRadius: "0px" }, src: getMediaLink(((_c = (_b = (_a = variant === null || variant === void 0 ? void 0 : variant.product) === null || _a === void 0 ? void 0 : _a.images) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.url) || "") }));
                },
            },
            {
                key: "productName",
                header: t("Product Name"),
                accessor: "productName",
            },
            {
                key: "quantity",
                header: t("Quantity"),
                accessor: "quantity",
            },
        ];
    }, []);
};
