import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GuidedCareTeamMembersDocument = gql `
    query GuidedCareTeamMembers($id: ID!) {
  providerGuidedCareHealthProgramTeam(id: $id) {
    teamMembers {
      id
      guidedCareProgramTeamMember {
        position
      }
      user {
        fullName
      }
    }
  }
}
    `;
/**
 * __useGuidedCareTeamMembersQuery__
 *
 * To run a query within a React component, call `useGuidedCareTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareTeamMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGuidedCareTeamMembersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GuidedCareTeamMembersDocument, options);
}
export function useGuidedCareTeamMembersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GuidedCareTeamMembersDocument, options);
}
