import { H_EntityError, InsurancePolicyCreateInput } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  insurancePoliciesListBreadcrumb,
  insurancePoliciesNewBreadcrumb,
  insurancePoliciesTitleBreadcrumb,
} from "pages/InsurancePolicies/constants/insurance-policies-breadcrumbs";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InsurancePolicyCreateMutation, useInsurancePolicyCreateMutation } from "../gql";

export const useInsurancePoliciesNewPage = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { failed, succeeded } = useAddToast();
  const { setBreadCrumb } = useBreadCrumbs();

  const [errors, setErrors] = React.useState<H_EntityError[]>([]);
  const [insurancePolicyCreateMutation, { loading: isCreateLoading }] = useInsurancePolicyCreateMutation({
    onCompleted: (createdInsurancePolicy: InsurancePolicyCreateMutation) => {
      const mutationErrors = createdInsurancePolicy?.insurancePolicyCreate?.entityErrors;
      if (mutationErrors && mutationErrors.length > 0) {
        setErrors(mutationErrors);
        failed(t(formatMessageErrors(mutationErrors)));
      } else {
        succeeded(t("Insurance Policy created successfully"));
        navigate(-1);
      }
    },
    onError: () => {
      failed(t("Failed to create insurance policy"));
    },
  });
  const handleSubmit = (requestInput: InsurancePolicyCreateInput) => {
    const outpatientPolicyValues = Object.values(requestInput?.insuranceOutpatientPolicyInput ?? {});
    const insuranceOutpatientPolicyInput = outpatientPolicyValues.every(value => !value)
      ? undefined
      : requestInput?.insuranceOutpatientPolicyInput;
    insurancePolicyCreateMutation({
      variables: {
        input: {
          ...requestInput,
          insuranceOutpatientPolicyInput,
        },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: insurancePoliciesTitleBreadcrumb(t),
      values: [insurancePoliciesListBreadcrumb(t), insurancePoliciesNewBreadcrumb(t)],
    });
  }, []);

  return {
    errors,
    isCreateLoading,
    handleSubmit,
  };
};
