import { PaletteOptions, Theme } from "@mui/material/styles";
import { commonColors, gray, green, midnightBlue, orange, red, roseBudCherry, stale, yellow } from "./colors";

export const CustomThemePalette: (theme: Theme) => PaletteOptions = theme => ({
  mode: "light",
  divider: "#DFE8F2",

  background: {
    default: gray[50],
    paper: commonColors.White,
  },
  text: {
    primary: midnightBlue.main,
    secondary: roseBudCherry.main,
  },
  primary: {
    ...midnightBlue,
    light: midnightBlue[50],
    main: midnightBlue.main,
    dark: midnightBlue[1300],
  },
  secondary: {
    ...roseBudCherry,
    main: roseBudCherry.main,
    dark: roseBudCherry[1300],
  },
  success: {
    ...green,
    main: green.main,
  },
  warning: {
    ...orange,
    main: orange.main,
  },
  error: {
    ...red,
    light: red[200],
    main: red.main,
  },
  info: {
    ...stale,
    main: stale.main,
  },
  common: {
    black: commonColors.Black,
    white: commonColors.White,
    ...commonColors,
  },

  // ** Custom Colors **
  gray: theme.palette.augmentColor({
    color: {
      ...gray,
      main: gray.main,
      light: gray[200],
    },
    name: "gray",
  }),
  stale: theme.palette.augmentColor({
    color: {
      ...stale,
      main: stale.main,
    },
    name: "stale",
  }),
  yellow: theme.palette.augmentColor({
    color: {
      ...yellow,
      main: yellow.main,
    },
    name: "yellow",
  }),
});
