import { getMediaLink } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, CustomIcon, FormCard, Grid, IconButton, Typography, useIsMobileView } from "@toolkit/ui";
import React from "react";
import useProductVariantImages from "../hooks/ProductVariantImages.hook";
import { ProductVariantImagesInterface } from "./ProductVariantImages.type";
import { useGeneralInfoStyles } from "./styles";

export const ProductVariantImages = ({ LoadingVariant, product, productVariantCreate, idVariant }: ProductVariantImagesInterface) => {
  const { t } = useTranslation("admin");
  const { classes, theme } = useGeneralInfoStyles();
  const { data, imageCanAssign, handleVariantImageUnAssign, handleVariantImageAssign, handleClickOpen, isOpen, result } =
    useProductVariantImages({
      product,
      productVariantCreate,
      idVariant,
    });
  const isMobileView = useIsMobileView();
  return (
    <Grid item xs={12}>
      <FormCard title={t("Choose Photo")} loading={LoadingVariant} doYouHaveData={true}>
        <Typography variant='body1' color={theme.palette.secondary.main}>
          {t("Select a specific variant image from product images")}
        </Typography>
        <Button color={"success"} onClick={handleClickOpen}>
          {t("Choose Photo")}
        </Button>
        <div className={classes.images}>
          {data?.productVariant?.images?.map((image: any) => (
            <div key={image.id}>
              <div>
                <img
                  alt=''
                  src={getMediaLink(image?.url)}
                  key={image.id}
                  width={isMobileView ? "100%" : 348}
                  height={isMobileView ? "100%" : 202}
                  className={classes.imgMargin}
                />
              </div>
              <IconButton className={classes.IconButtonStyle} size={"small"} onClick={() => handleVariantImageUnAssign(image?.id)}>
                <CustomIcon icon={"delete"} width={20} height={20} viewBox='0 0 24 24' />
              </IconButton>
            </div>
          ))}
        </div>
        <CustomDialog
          maxWidth='sm'
          DialogContentProps={{
            sx: {
              display: "flex",
              flexDirection: "column",
              alignItems: isMobileView ? "center" : "flex-start",
              minHeight: "100%",
            },
          }}
          open={isOpen}
          title={t("Choose Image")}
          onCloseModal={handleClickOpen}
        >
          {result?.length ? (
            <>
              {imageCanAssign?.length ? (
                imageCanAssign?.map((image: any) => (
                  <Box key={image.id} onClick={() => handleVariantImageAssign(image?.id)}>
                    <img
                      alt={`${image?.name}`}
                      src={getMediaLink(image?.url)}
                      width={isMobileView ? "100%" : 348}
                      height={isMobileView ? "100%" : 175}
                    />
                  </Box>
                ))
              ) : (
                <span className={classes.chooseImage}> {t("All Images are assigned")}</span>
              )}
            </>
          ) : (
            <span className={classes.chooseImage}> {t("Please Add Product Image to be able to choose Image Variant")}</span>
          )}
        </CustomDialog>
      </FormCard>
    </Grid>
  );
};
