/* eslint-disable react/jsx-handler-names */
import { StandaloneSearchBox } from "@react-google-maps/api";
import { getCurrentLocation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress, IconButton, TextField as MuiTextField, MyLocationIcon, TextFieldProps, Typography } from "@toolkit/ui";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useLocationHooks } from "../utils";
import { useGoogleMapSearchWrapperStyles } from "./styles";
import { IGoogleMapSearchProps } from "./types";

const GoogleMapSearchComponent = (props: IGoogleMapSearchProps) => {
  const { onLocationChange, defaultValue } = props;
  const { t } = useTranslation();
  const { getAddressFromLatLng } = useLocationHooks();
  const { classes, theme } = useGoogleMapSearchWrapperStyles();
  const refs = useRef<google.maps.places.SearchBox>(null);
  const [places, setPlaces] = useState<google.maps.places.PlaceResult[]>([]);
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAddress(defaultValue || "");
  }, [defaultValue]);

  const handlePlacesChanged = () => {
    const newPlaces = refs.current?.getPlaces() || [];
    setPlaces(places);
    if (newPlaces.length) {
      getCityFromGoogleApi(newPlaces[0]);
      onLocationChange?.({
        coordinates: {
          lat: newPlaces[0]?.geometry?.location?.lat(),
          lng: newPlaces[0]?.geometry?.location?.lng(),
        },
        city: getCityFromGoogleApi(newPlaces[0]),
        streetAddress1: newPlaces[0]?.formatted_address,
        name: newPlaces[0]?.name,
      });
      setAddress(newPlaces[0]?.formatted_address!);
    }
  };

  const getCityFromGoogleApi = (result: google.maps.places.PlaceResult) => {
    let city;
    for (let i = 0; i < result.address_components?.length!; i++) {
      for (let b = 0; b < result.address_components?.[i]?.types?.length!; b++) {
        if (result.address_components?.[i]?.types?.[b] == "locality") {
          city = result.address_components?.[i];
          break;
        }
      }
    }
    return city?.long_name;
  };

  const handleGetMyLocation = async () => {
    try {
      setLoading(true);
      const location = await getCurrentLocation();
      await getAddressFromLatLng(location?.lat?.toString(), location?.lng?.toString()).then(response => {
        setLoading(false);
        onLocationChange?.(response);
      });
    } catch (error) {
      setLoading(false);
      alert(
        t("You did not allow location permissions, to enjoy this feature please go to settings and give your browser location permissions")
      );
    }
  };
  const handleSearchLocation: TextFieldProps["onChange"] = e => setAddress(e.target.value);

  return (
    <div className={classes.search}>
      <StandaloneSearchBox
        onLoad={instance => {
          _.set(refs, "current", instance);
        }}
        onPlacesChanged={handlePlacesChanged}
      >
        <Box sx={{ position: "relative", width: "100%" }}>
          <MuiTextField
            placeholder={t("Enter Area")}
            hiddenLabel
            fullWidth
            value={address}
            className={classes.text}
            onChange={handleSearchLocation}
          />
          <IconButton
            onClick={handleGetMyLocation}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 0,
              height: 50,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              borderRadius: "0 5px 5px 0",
              "&:hover": {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.primary.main,
              },
            }}
          >
            {loading ? <CircularProgress size={20} color='inherit' /> : <MyLocationIcon color='inherit' />}
            <Typography fontSize={theme.mixins.fonts.fontSize.xs} m='0 5px' color='inherit'>
              {t("Current Location")}
            </Typography>
          </IconButton>
        </Box>
      </StandaloneSearchBox>
      <Box sx={{ position: "absolute", zIndex: 4 }}>
        <ol>
          {places?.map(({ place_id, formatted_address, geometry }) => (
            <li key={place_id}>
              {formatted_address}
              {" at "}({geometry?.location?.lat()}, {geometry?.location?.lng()})
            </li>
          ))}
        </ol>
      </Box>
    </div>
  );
};
export default GoogleMapSearchComponent;
