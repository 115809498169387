/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type SiteSettingsFragmentFragment = { __typename?: 'SiteSettings', vatPercentage?: number | null, includeTaxesInPrices: boolean, orderBaseDeliveryFee?: number | null, defaultCurrency: string, doctorVisitAcceptanceTimeoutSeconds?: number | null, healthProgramVisitWaitTimeBeforeCallSeconds?: number | null, messengerCallMinimumDurationSeconds?: number | null, appointmentReminderTimeSeconds?: number | null, trackInventoryByDefault?: boolean | null, prescriptionOrderPaymentTimeoutSeconds?: number | null, orderErxHubApprovalTimeoutAlert?: number | null, orderErxHubApprovalTimeout?: number | null, orderErxHubApprovalResubmitTimeout?: number | null, orderDispensingTimeoutAlert?: number | null, orderDispensingTimeout?: number | null, orderDeliveryTimeout?: number | null, orderAcceptanceTimeout?: number | null, multiplePharmaciesDelivery?: boolean | null, marketplaceVendorResponseTimeoutSeconds?: number | null, marketplaceVendorResponseTimeoutAlert?: number | null, marketplaceReturnedTimeoutAlert?: number | null, marketplaceReturnedTimeoutSeconds?: number | null, marketplaceOutForDeliveryTimeoutSeconds?: number | null, marketplaceOutForDeliveryTimeoutAlert?: number | null, marketplaceOrderPaymentTimeoutSeconds?: number | null, marketplaceDeliveredTimeoutSeconds?: number | null, marketplaceDeliveredTimeoutAlert?: number | null, marketplaceConsumedTimeoutSeconds?: number | null, marketplaceConsumedTimeoutAlert?: number | null, iosVersion?: number | null, androidVersion?: number | null, chargeTaxesOnShipping: boolean, displayGrossPrices: boolean, defaultWeightUnit?: Types.WeightUnitsEnum | null, vatPercentageForMedicalServices?: number | null };

export const SiteSettingsFragmentFragmentDoc = gql`
    fragment siteSettingsFragment on SiteSettings {
  vatPercentage
  includeTaxesInPrices
  orderBaseDeliveryFee
  defaultCurrency
  doctorVisitAcceptanceTimeoutSeconds
  healthProgramVisitWaitTimeBeforeCallSeconds
  messengerCallMinimumDurationSeconds
  appointmentReminderTimeSeconds
  trackInventoryByDefault
  prescriptionOrderPaymentTimeoutSeconds
  orderErxHubApprovalTimeoutAlert
  orderErxHubApprovalTimeout
  orderErxHubApprovalResubmitTimeout
  orderDispensingTimeoutAlert
  orderDispensingTimeout
  orderDeliveryTimeout
  orderAcceptanceTimeout
  multiplePharmaciesDelivery
  marketplaceVendorResponseTimeoutSeconds
  marketplaceVendorResponseTimeoutAlert
  marketplaceReturnedTimeoutAlert
  marketplaceReturnedTimeoutSeconds
  marketplaceOutForDeliveryTimeoutSeconds
  marketplaceOutForDeliveryTimeoutAlert
  marketplaceOrderPaymentTimeoutSeconds
  marketplaceDeliveredTimeoutSeconds
  marketplaceDeliveredTimeoutAlert
  marketplaceConsumedTimeoutSeconds
  marketplaceConsumedTimeoutAlert
  iosVersion
  androidVersion
  chargeTaxesOnShipping
  displayGrossPrices
  defaultWeightUnit
  vatPercentageForMedicalServices
}
    `;