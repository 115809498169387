import { arEG } from "@mui/material/locale";
import { createTheme, Theme } from "@mui/material/styles";
import { CustomComponentsOverride } from "./components";
import { getAppFonts } from "./fonts";
import { CustomThemePalette } from "./palette";

const fontFamilies = [
  "Airbnb Cereal App",
  "Airbnb Cereal App Book",
  "Airbnb Cereal App Medium",
  "Airbnb Cereal App Light",
  "Airbnb Cereal App Bold",
  "Airbnb Cereal App Extra Bold",
  "Airbnb Cereal App Black",
  "Arab Kufi Regular",
  "Arab Kufi Medium",
  "Arab Kufi Bold",
].join(",");

let CustomTheme: Theme = createTheme({
  typography: {
    fontFamily: fontFamilies,
    allVariants: {
      fontFamily: fontFamilies,
    },
  },
});

CustomTheme = createTheme(
  CustomTheme,
  {
    palette: CustomThemePalette(CustomTheme),
    components: CustomComponentsOverride,
    mixins: {
      toolbar: {
        minHeight: 70,
      },
      header: {
        height: 70,
      },
      sidebar: {
        width: 250,
      },
      fonts: {
        fontSize: {
          xxs: 10,
          xs: 12,
          sm: 14,
          md: 16,
          lg: 18,
          xl: 20,
          xxl: 24,
        },
        fontFamily: {
          regular: getAppFonts("regular"),
          medium: getAppFonts("medium"),
          bold: getAppFonts("bold"),
        },
      },
      shadows: {
        none: "none",
        main: "0px 3px 6px #0000000D",
        xs: "0px 0px 6px #0000001A",
        sm: "0px 3px 10px #0000001A",
        md: "0px 0px 20px #0000001A",
      },
    },
    shadows: [
      "none",
      // Card BoxShadow
      "0px 0px 10px #00000029",
      // Default BoxShadow
      "0px 0px 20px #0000001A",
      ...CustomTheme.shadows,
    ],
  },
  arEG
);

export { CustomTheme };
