import { Chip, useTheme } from "@mui/material";
import { i18next } from "@toolkit/i18n";
import React from "react";
import { EnumLike, ZodNativeEnum, ZodObject, ZodRawShape, ZodString, z } from "zod";
import { IFilter, TruncateTypography } from "../components";
import { IAutocompleteEnumFilter, IAutocompleteLabelBy, IAutocompleteOption, IAutocompleteQueryFilter, IAutocompleteValue } from "../types";

export function createZodAutocompleteObject(): ZodObject<{ key: ZodString; label: ZodString; value: ZodString }>;

export function createZodAutocompleteObject<T extends EnumLike>(
  value: T
): ZodObject<{ key: ZodNativeEnum<T>; label: ZodString; value: ZodNativeEnum<T> }>;

export function createZodAutocompleteObject<T extends ZodRawShape>(
  value: ZodObject<T>
): ZodObject<{ key: ZodString; label: ZodString; value: ZodObject<T> }>;

export function createZodAutocompleteObject(value?: EnumLike | ZodObject<any>): any {
  if (!value) {
    return z.object(
      {
        key: z.string(),
        label: z.string(),
        value: z.string(),
      },
      {
        invalid_type_error: "Required",
      }
    );
  } else if (value instanceof ZodObject) {
    return z.object(
      {
        key: z.string(),
        label: z.string(),
        value,
      },
      {
        invalid_type_error: "Required",
      }
    );
  } else {
    return z.object(
      {
        key: z.nativeEnum(value),
        label: z.string(),
        value: z.nativeEnum(value),
      },
      {
        invalid_type_error: "Required",
      }
    );
  }
}

export function createZodAutocompleteObjectFromType<T>(v?: T) {
  //
  void v;
  const baseSchema = z.object(
    {
      key: z.string(),
      label: z.string(),
    },
    {
      invalid_type_error: "Required",
    }
  );
  return baseSchema.extend({
    value: z.any({}),
  }) as unknown as z.ZodType<z.infer<typeof baseSchema> & { value: T }>;
}

export const getLabel = <T,>(item: T, labelBy: IAutocompleteLabelBy<T>): string => {
  return (typeof labelBy === "function" ? labelBy(item) : item?.[labelBy]) as string;
};

export const getAutocompleteLabel = <T,>(item: T, englishLabelBy: IAutocompleteLabelBy<T>, arabicLabelBy?: IAutocompleteLabelBy<T>) => {
  const labelBy = i18next.language === "ar" && arabicLabelBy ? arabicLabelBy : englishLabelBy;
  return getLabel(item, labelBy) || getLabel(item, englishLabelBy) || "";
};

export const createAutocompleteOption = <T,>(
  item: T,
  keyBy: keyof T,
  englishLabelBy: IAutocompleteLabelBy<T>,
  arabicLabelBy?: IAutocompleteLabelBy<T>
): IAutocompleteOption<T> => {
  return {
    key: item[keyBy] as string,
    label: getAutocompleteLabel(item, englishLabelBy, arabicLabelBy),
    value: item,
  };
};

export const mapStringsToAutocompleteOptions = (items: string[], labelBy?: (item: string) => string): IAutocompleteOption<string>[] => {
  if (!items || !items?.length) return [];
  return items.map(item => ({ key: item.toUpperCase(), label: labelBy ? labelBy(item) : item, value: item }));
};

export const mapToAutocompleteOptions = <T extends object>(
  items: T[],
  keyBy: keyof T,
  englishLabelBy: IAutocompleteLabelBy<T>,
  arabicLabelBy?: IAutocompleteLabelBy<T>
): IAutocompleteOption<T>[] => {
  if (!items || !items?.length) return [];
  return items.map(item => createAutocompleteOption(item, keyBy, englishLabelBy, arabicLabelBy));
};

export const getAutocompleteFilter = <T,>(options: IAutocompleteQueryFilter<T>): IFilter => {
  const { labelBy, arabicLabelBy, backendAccessor, ...rest } = options;

  const getOptionLabel = (option: T): string => {
    return getAutocompleteLabel(option, labelBy!, arabicLabelBy);
  };

  const getValueForBackend = (option: T | T[]) => {
    return getAutocompleteFilterValueForBackend(option, backendAccessor, options?.multiple);
  };

  return {
    ...rest,
    type: "autocomplete" as const,
    getOptionLabel,
    getValueForBackend,
  };
};

const getAutocompleteFilterValueForBackend = <T,>(option: T | T[], dataAccessor: IAutocompleteLabelBy<T>, multiple?: boolean) => {
  if (multiple) {
    return Array.isArray(option) ? option.map(_option => getBackendAccessorValue(_option, dataAccessor)) : undefined;
  } else {
    return getBackendAccessorValue(option as T, dataAccessor);
  }
};

const getBackendAccessorValue = <T,>(option: T, dataAccessor: IAutocompleteLabelBy<T>) => {
  if (typeof dataAccessor === "function") {
    return dataAccessor(option);
  } else {
    return option?.[dataAccessor];
  }
};

export const getAutocompleteEnumFilter = <T,>(options: IAutocompleteEnumFilter<T>): IFilter => {
  return getAutocompleteFilter({
    ...options,
    labelBy: "label",
    backendAccessor: "value",
  });
};

export const renderDefaultTags = <T,>(value: IAutocompleteValue<T>[], getTagProps: any) => {
  return value.map((option, index) => <Chip key={option?.key} label={option?.label} {...getTagProps({ index })} />);
};

export const renderDefaultOption = (props: any, option: any) => {
  const theme = useTheme();
  return (
    <li {...props}>
      <TruncateTypography fontFamily={theme.mixins.fonts.fontFamily.medium} text={option?.label} />
    </li>
  );
};
