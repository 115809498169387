import { HealthPackage, HealthPackageApprovalStatusEnum, HealthPackageSortField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { startCase } from "lodash";
import { useGetVendorsQuery } from "pages/HealthPackages/gql";
import { useMemo } from "react";
import { ApproveHealthPackage } from "./ApproveHealthPackage";
import { PreviewHealthPackages } from "./PreviewHealthPackages";
import RejectHealthPackage from "./RejectHealthPackage";
import { ellipseName } from "utils";

export const useHealthPackageColumns = (): CustomTableColumnProps<HealthPackage>[] => {
  const { t, i18n } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ nameAr, name }) => ellipseName(i18n.language === "ar" ? nameAr || name : name, 20)!,
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: HealthPackageSortField.Name,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "vendor",
        header: t("Health Provider"),
        accessor: ({ vendor }) => ellipseName(i18n.language === "ar" ? vendor?.nameAr || vendor?.name : vendor?.name, 20),
        filter: {
          type: "autocomplete",
          query: useGetVendorsQuery,
          getOptionLabel: option => option?.name,
          getValueForBackend: options => options?.id,
          name: "vendorId",
          accessor: "vendors",
        },
      },
      {
        key: "status",
        header: t("Approval Status"),
        accessor: row => t(startCase(row?.approvalStatus!.toLowerCase())),
        filter: {
          type: "autocomplete",
          name: "approvalStatus",
          options: Object.keys(HealthPackageApprovalStatusEnum).map(key => ({
            value: HealthPackageApprovalStatusEnum[key],
            label: startCase(key),
          })),
          getOptionLabel: option => t(option?.label),
          getValueForBackend: option => option?.value,
        },
      },
      {
        key: "preview",
        header: t("Preview"),
        accessor: row => <PreviewHealthPackages row={row} />,
      },
      {
        key: "approve",
        header: t("Approve"),
        accessor: row => <ApproveHealthPackage row={row} />,
      },
      {
        key: "reject",
        header: t("Reject"),
        accessor: row => <RejectHealthPackage row={row} />,
      },
    ];
  }, []);
};
