import { CircularProgress, FormControlLabel, FormGroup } from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { ToggleButtonProps } from "./types";

const IOSSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(
  ({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,

    "& .MuiSwitch-switchBase": {
      padding: 3,
      margin: 0,
      height: "100%",
      transitionDuration: "300ms",
      "&.Mui-checked": {
        color: theme.palette.common.white,
        transform: "translateX(19px)",
        "& + .MuiSwitch-track": {
          opacity: 1,
          border: 0,
          "&:hover": {
            backgroundColor: theme.palette.success.light,
          },
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 1,
        },
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.mode === "light" ? theme.palette.gray.light : theme.palette.gray[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        // opacity: theme.palette.mode === "light" ? 0.5 : 0.3,
        backgroundColor: theme.palette.success.light,
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "& .MuiSwitch-input": {
      height: "100%",
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 16,
      height: 16,
      boxShadow: theme.mixins.shadows.xs,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.gray.light,
      opacity: 1,
      "&:hover": {
        backgroundColor: theme.palette.gray[600],
      },
    },
    "&:hover": {
      "& .MuiSwitch-track": {
        backgroundColor: theme.palette.gray[600],
      },
      "& .Mui-checked + .MuiSwitch-track": {
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

const CustomToggleButton: FC<ToggleButtonProps> = ({ color = "success", formGroupProps, formControlLabelProps, isLoading, ...props }) => {
  return (
    <FormGroup
      sx={{
        "& .MuiFormControlLabel-root": {
          margin: "auto",
        },
      }}
      {...formGroupProps}
    >
      <FormControlLabel
        label=''
        {...formControlLabelProps}
        control={isLoading ? <CircularProgress size={20} /> : <IOSSwitch sx={{ m: 1 }} color={color} {...props} />}
      />
    </FormGroup>
  );
};

export default CustomToggleButton;
