import { PayerError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { payersPaths } from "pages/Payers/Constants/payersPaths";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPayerQuery, usePayerUpdateMutation } from "../../gql";
import { IFormData } from "../types";

export const usePayerEdit = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const params = useParams();
  const { payerId } = params;

  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = useState<PayerError[] | undefined>([]);

  const { data, loading } = useGetPayerQuery({
    variables: {
      payerId: payerId!,
    },
  });
  const [payerUpdate, { loading: isSubmitting }] = usePayerUpdateMutation({
    onCompleted: mutationData => {
      const mutationErrors = mutationData?.payerUpdate?.payerErrors || [];
      if (mutationErrors?.length === 0) {
        succeeded(t("Payer Updated Successfully"));
        navigate(payersPaths.listPath.fullPath);
      } else {
        setErrors(mutationErrors as PayerError[]);
        formatMessageErrors(mutationErrors);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleSubmit = (input: IFormData) => {
    payerUpdate({
      variables: {
        payerUpdateId: payerId!,
        input: {
          ...input,
          tpo: input?.tpo?.value,
        },
      },
    });
  };

  const payer = data?.payer;

  return {
    errors,
    loading,
    payer,
    handleSubmit,
    isSubmitting,
  };
};
