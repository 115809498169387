import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../AdminManagedLists/gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GetMarketplaceOrdersDocument = gql `
    query getMarketplaceOrders($sortBy: OrderSortingInput, $filter: OrderFilterInput, $before: String, $after: String, $first: Int, $last: Int, $isAdmin: Boolean!) {
  orders(
    sortBy: $sortBy
    filter: $filter
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        type
        branch {
          id
          name
          nameAr
          vendor @include(if: $isAdmin) {
            id
            name
            nameAr
          }
        }
        created
        userEmail
        number
        nationalId
        user {
          nationalId
          fullName
          mobile
          email
          id
        }
        total {
          gross {
            amount
            currency
          }
        }
        status
        deliveryStatus
        lines {
          id
          quantity
          productName
          variantName
          variant {
            sku
            product {
              images {
                id
                url
              }
            }
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;
/**
 * __useGetMarketplaceOrdersQuery__
 *
 * To run a query within a React component, call `useGetMarketplaceOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketplaceOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketplaceOrdersQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useGetMarketplaceOrdersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetMarketplaceOrdersDocument, options);
}
export function useGetMarketplaceOrdersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetMarketplaceOrdersDocument, options);
}
