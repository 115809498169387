import { formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, TextField } from "@toolkit/ui";
import { useFormContext } from "react-hook-form";

const InventoryCard = ({ defaultProductData }: any) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation("admin");
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          placeholder={t("SKU")}
          label={t("SKU")}
          defaultValue={defaultProductData?.sku || defaultProductData?.variants[0]?.sku}
          error={Boolean(errors?.sku?.message)}
          helperText={t(errors?.sku?.message)}
          {...register("sku", {
            required: getRequiredValidation(t, true),
          })}
        />
      </Grid>
    </Grid>
  );
};
export default InventoryCard;
