/* eslint-disable max-lines */
import { Button, Divider, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "@toolkit/i18n";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useIsMobileView } from "../../hooks";
import { ModalCloseIcon } from "../../icons";
import { CircularProgress } from "../../mui";
import { useCustomDialogStyles } from "./CustomDialog.styles";
import { CustomDialogProps } from "./CustomDialog.types";

const CustomDialog: FC<CustomDialogProps> = props => {
  const {
    submitTitle = "Apply",
    headerStyles = {},
    resetTitle = "Reset",
    scrollType = "paper",
    maxWidth = "md",
    title = "",
    open,
    button,
    footerComponent = undefined,
    children,
    disableSubmit = false,
    isSubmitLoading = false,
    SubmitButtonProps = {},
    DialogContentProps = {},
    onConfirm,
    onReset,
    onCloseModal,
    titleColor,
  } = props;
  const isMobile = useIsMobileView();

  const { classes, theme } = useCustomDialogStyles();

  const handleClose = () => {
    onCloseModal && onCloseModal();
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const { handleSubmit, reset } = useForm<FormData>();

  const handleFormReset = () => {
    reset();
  };

  const { t } = useTranslation();
  return (
    <React.Fragment>
      {button}
      <Dialog
        maxWidth={maxWidth}
        fullWidth
        fullScreen={isMobile}
        title=''
        PaperProps={{
          style: { borderRadius: isMobile ? "unset" : "15px" },
        }}
        onClose={handleClose}
        scroll={scrollType}
        {...props}
      >
        <DialogTitle className={classes.header} title=''>
          <Typography
            sx={{
              fontSize: theme.mixins.fonts.fontSize.md,
              fontFamily: theme.mixins.fonts.fontFamily.medium,
              color: { titleColor },
              ...headerStyles,
            }}
          >
            {title}
          </Typography>
          <IconButton onClick={handleClose} sx={{ height: "30px", width: "30px" }}>
            <ModalCloseIcon />
          </IconButton>
        </DialogTitle>
        {scrollType === "paper" && <Divider />}
        <DialogContent
          title=''
          ref={descriptionElementRef}
          tabIndex={-1}
          {...DialogContentProps}
          sx={{ minHeight: "20vh", maxHeight: "40vh", ...(DialogContentProps?.sx || {}) }}
        >
          <form id='custom-dialog-form' onSubmit={handleSubmit(onConfirm!)}>
            {children}
          </form>
        </DialogContent>
        {(onConfirm || onReset || footerComponent) && (
          <DialogActions className={classes.actions} title=''>
            {onConfirm && (
              <Button type='submit' form='custom-dialog-form' disabled={disableSubmit || isSubmitLoading} {...(SubmitButtonProps || {})}>
                {isSubmitLoading && <CircularProgress size={30} sx={{ color: theme.palette.common.white, marginInlineEnd: 1 }} />}
                {t(submitTitle)}
              </Button>
            )}
            {onReset && (
              <Button variant='outlined' onClick={handleFormReset}>
                {t(resetTitle)}
              </Button>
            )}
            {footerComponent}
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default CustomDialog;
