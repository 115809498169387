import { Typography, useTheme } from "@mui/material";
import { i18n } from "@toolkit/i18n";
import MDEditor, { commands } from "@uiw/react-md-editor";
import React, { FC } from "react";

interface MDEditorProps {
  value?: string;
  onChange?: (v?: string) => void;
  readonly?: boolean;
  placeholder?: string;
  label?: string;
}
export const MDTextEditor: FC<MDEditorProps> = ({ value, onChange: handleChangeEditorValue, readonly, placeholder, label }) => {
  const theme = useTheme();
  return (
    <div className='container'>
      {label && <Typography sx={{ fontSize: theme.mixins.fonts.fontSize.md, padding: "10px 0" }}>{label}</Typography>}

      <MDEditor
        direction={i18n?.dir()}
        value={value}
        onChange={handleChangeEditorValue}
        preview={readonly ? "preview" : undefined}
        hideToolbar={readonly}
        aria-placeholder={placeholder}
        commands={[
          commands.bold,
          commands.italic,
          commands.strikethrough,
          commands.hr,
          commands.group([commands.title1, commands.title2, commands.title3, commands.title4, commands.title5, commands.title6], {
            name: "title",
            groupName: "title",
            buttonProps: { "aria-label": "Insert title" },
          }),
          commands.divider,
          commands.quote,
          commands.table,
          commands.divider,
          commands.checkedListCommand,
          commands.orderedListCommand,
          commands.unorderedListCommand,
        ]}
      />
    </div>
  );
};
MDTextEditor.defaultProps = {
  value: "",
  onChange: () => {},
  readonly: false,
  placeholder: "",
  label: "",
};
