/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PayerFragmentFragmentDoc } from '../../fragments/__generated__/Payer';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPayersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PayerFilterInput>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.PayerOrder>;
}>;


export type GetPayersQuery = { __typename?: 'Query', payers?: { __typename?: 'PayerCountableConnection', edges: Array<{ __typename?: 'PayerCountableEdge', node: { __typename?: 'Payer', id: string, created: any, name: string, nameAr?: string | null, contactName?: string | null, contactPhoneNumber?: string | null, contactMobileNumber?: string | null, contactEmail?: string | null, isActive: boolean, licenseNumber: string, logo?: string | null, backgroundImage?: string | null, tpo: Types.PayerTpo } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };


export const GetPayersDocument = gql`
    query GetPayers($filter: PayerFilterInput, $before: String, $after: String, $first: Int, $last: Int, $sortBy: PayerOrder) {
  payers(
    filter: $filter
    before: $before
    after: $after
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    edges {
      node {
        ...PayerFragment
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PayerFragmentFragmentDoc}
${PageInfoFragmentFragmentDoc}`;

/**
 * __useGetPayersQuery__
 *
 * To run a query within a React component, call `useGetPayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetPayersQuery(baseOptions?: Apollo.QueryHookOptions<GetPayersQuery, GetPayersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayersQuery, GetPayersQueryVariables>(GetPayersDocument, options);
      }
export function useGetPayersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayersQuery, GetPayersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayersQuery, GetPayersQueryVariables>(GetPayersDocument, options);
        }
export type GetPayersQueryHookResult = ReturnType<typeof useGetPayersQuery>;
export type GetPayersLazyQueryHookResult = ReturnType<typeof useGetPayersLazyQuery>;
export type GetPayersQueryResult = Apollo.QueryResult<GetPayersQuery, GetPayersQueryVariables>;