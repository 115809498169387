import { get } from "lodash";

type IAddress = null | {
  area?: string | null;
  city?: { name?: string | null } | null;
  postalCode?: string | null;
  streetAddress1?: string | null;
  streetAddress2?: string | null;
};
// TODO
export const getTranslationForObjProperty = <T extends Record<string, null | any | undefined>>(
  defaultValue: T,
  field: keyof typeof defaultValue,
  language: string
) => {
  // const selectedTranslation = defaultValue?.translations?.find(item => item?.language?.code === language); deprecated as translations field is no longer sent by backend!
  const defaultText = get(defaultValue, field, "");
  return language.toUpperCase() === "AR" ? get(defaultValue, `${field as string}Ar`, defaultText) : defaultText;
};

export const getTranslatedNameField = <T extends { name?: string | null; nameAr?: string | null }>(defaultValue: T, language: string) => {
  return language.toUpperCase() === "EN" ? defaultValue?.name || "" : defaultValue?.nameAr || defaultValue?.name || "";
};

// eslint-disable-next-line default-param-last
export const formatAddressFull = (address: IAddress | null = {} as IAddress, language: string) => {
  if (!address) return "";
  const area = getTranslationForObjProperty(address, "area", language);
  const streetAddress1 = getTranslationForObjProperty(address, "streetAddress1", language);
  const city = getTranslatedNameField(address?.city!, language);

  return [streetAddress1, area, city, address?.postalCode].filter(Boolean).join(", ");
};
