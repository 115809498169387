import { useCustomFormContext } from "@toolkit/core";
import { TextField, TextFieldProps } from "@mui/material";
import { get } from "lodash";
import React, { FC } from "react";
import { Controller } from "react-hook-form";

type FormTextFieldProps = Omit<TextFieldProps, "defaultValue" | "error" | "helperText"> & {
  name: string;
};

export const FormTextField: FC<FormTextFieldProps> = props => {
  const { name, variant = "filled", size = "small", fullWidth = true, disabled, ...rest } = props;

  const {
    control,
    isFormDisabled,
    formState: { errors },
  } = useCustomFormContext();

  const error: string = get(errors, name + ".message", "");

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...rest}
          {...field}
          size={size}
          fullWidth={fullWidth}
          variant={variant}
          error={!!error}
          helperText={error}
          disabled={isFormDisabled || disabled}
        />
      )}
    />
  );
};
