import { TFunction } from "@toolkit/i18n";
import { MedicationIcon } from "@toolkit/ui";
import { insurancePoliciesPaths, insurancePoliciesRoute } from "pages/InsurancePolicies/constants/insurance-policies-paths";
import { InsurancePoliciesEditPage } from "pages/InsurancePolicies/Edit";
import { InsurancePoliciesList } from "pages/InsurancePolicies/List";
import { InsurancePoliciesNewPage } from "pages/InsurancePolicies/New";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";

export const insurancePoliciesRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  return {
    id: "insurance-policies-routes",
    text: t("Insurance Policies", { ns: "admin" }),
    icon: <MedicationIcon />,
    route: insurancePoliciesRoute,
    subItems: [
      {
        id: "insurance-policies-list-route",
        text: t("Insurance Policies", { ns: "admin" }),
        route: insurancePoliciesPaths.listPath.route,
        fullPath: insurancePoliciesPaths.listPath.fullPath,
        element: <InsurancePoliciesList />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "insurance-policies-new-route",
        text: t("New Insurance Policy", { ns: "admin" }),
        route: insurancePoliciesPaths.newPath.route,
        fullPath: insurancePoliciesPaths.newPath.fullPath,
        hidden: true,
        element: <InsurancePoliciesNewPage />,
      },
      {
        id: "insurance-policies-edit-route",
        text: t("Edit Insurance Policy", { ns: "admin" }),
        route: insurancePoliciesPaths.editPath.route,
        fullPath: insurancePoliciesPaths.editPath.fullPath,
        hidden: true,
        element: <InsurancePoliciesEditPage />,
      },
    ],
  };
};
