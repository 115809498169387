import { useTheme } from "@mui/material";
import type { SvgIconProps } from "@mui/material/SvgIcon";
import SvgIcon from "@mui/material/SvgIcon";
import React, { FC } from "react";

export const Notification: FC<SvgIconProps> = props => {
  const { palette } = useTheme();

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='21.42' height='25.5' viewBox='0 0 21.42 25.5' {...props}>
      <path
        id='icons8-notification_2_'
        d='M14.71,0a2.038,2.038,0,0,0-1.9,2.789A6.338,6.338,0,0,0,8.08,9.18c0,3.994-.98,5.813-1.913,6.837a8.109,8.109,0,0,1-1.323,1.116,3.1,3.1,0,0,0-.542.478,1.18,1.18,0,0,0-.3.749,2.058,2.058,0,0,0,1.036,1.689,6.969,6.969,0,0,0,2.359.845,28.841,28.841,0,0,0,4.463.462A3.056,3.056,0,1,0,17.77,22.44a3.013,3.013,0,0,0-.207-1.084,28.841,28.841,0,0,0,4.463-.462,6.969,6.969,0,0,0,2.359-.845A2.058,2.058,0,0,0,25.42,18.36a1.18,1.18,0,0,0-.3-.749,3.1,3.1,0,0,0-.542-.478,8.109,8.109,0,0,1-1.323-1.116c-.932-1.024-1.913-2.843-1.913-6.837a6.347,6.347,0,0,0-4.733-6.391A2.038,2.038,0,0,0,14.71,0Zm0,1.02a1.02,1.02,0,1,1-1.02,1.02A1.012,1.012,0,0,1,14.71,1.02Zm1.2,2.646A5.239,5.239,0,0,1,20.32,9.18c0,4.166,1.06,6.305,2.168,7.522a9.075,9.075,0,0,0,1.482,1.259,2.907,2.907,0,0,1,.383.319c.062.07.048.064.048.08,0,.319-.147.552-.574.829a6.143,6.143,0,0,1-2.008.717,38.239,38.239,0,0,1-7.108.494A38.239,38.239,0,0,1,7.6,19.906a6.143,6.143,0,0,1-2.008-.717c-.426-.277-.574-.51-.574-.829,0-.016-.014-.01.048-.08a2.907,2.907,0,0,1,.383-.319A9.075,9.075,0,0,0,6.933,16.7C8.04,15.485,9.1,13.346,9.1,9.18a5.227,5.227,0,0,1,4.415-5.5,2,2,0,0,0,1.2.4A1.983,1.983,0,0,0,15.905,3.666ZM12.957,21.388c.572.012,1.153.032,1.753.032s1.181-.02,1.753-.032a2.07,2.07,0,0,1,.287,1.052,2.04,2.04,0,1,1-3.793-1.052Z'
        transform='translate(-4)'
        fill={props.fill ? props.fill : palette.primary.main}
      />
    </SvgIcon>
  );
};
