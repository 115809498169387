import { InsurancePolicy } from "@health/queries/types";
import { MAX_LENGTH_MESSAGE } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { z } from "zod";

export const useInsurancePolicyFormValidation = ({ t }: { t: TFunction }) => {
  const getDefaultFormValues = (insurancePolicy?: InsurancePolicy) => ({
    insuranceNetworkId: insurancePolicy?.network?.id ?? "",
    policyName: insurancePolicy?.name ?? "",
    policyNumber: insurancePolicy?.number ?? "",
    insuranceOutpatientPolicyInput: {
      cons: insurancePolicy?.outpatientPolicy?.cons,
      dental: insurancePolicy?.outpatientPolicy?.dental,
      optical: insurancePolicy?.outpatientPolicy?.optical,
      outpatient: insurancePolicy?.outpatientPolicy?.outpatient,
      pharm: insurancePolicy?.outpatientPolicy?.pharm,
    },
    isActive: insurancePolicy?.isActive ?? false,
  });

  const insurancePolicyFormSchema = z.object({
    insuranceNetworkId: z.string().nonempty(t("Required", { ns: "admin" })),
    policyName: z
      .string()
      .nonempty(t("Required", { ns: "admin" }))
      .max(255, t(MAX_LENGTH_MESSAGE, { value: 255 })),
    policyNumber: z
      .string()
      .nonempty(t("Required", { ns: "admin" }))
      .max(255, t(MAX_LENGTH_MESSAGE, { value: 255 })),
    insuranceOutpatientPolicyInput: z.object({
      cons: z.string().max(255, t(MAX_LENGTH_MESSAGE, { value: 255 })),
      dental: z.string().max(255, t(MAX_LENGTH_MESSAGE, { value: 255 })),
      optical: z.boolean().optional(),
      outpatient: z.boolean().optional(),
      pharm: z.string().max(255, t(MAX_LENGTH_MESSAGE, { value: 255 })),
    }),
    isActive: z.boolean(),
  });

  return {
    getDefaultFormValues,
    insurancePolicyFormSchema,
  };
};
