/* eslint-disable max-lines */
import { Product, ProductVariant } from "@health/queries/types";
import {
  formGirdBreakPoints,
  formGirdSpacing,
  getRequiredValidation,
  patternArabicTextWithSpecialCharAndNumbers,
  validateValueIsNotEmpty,
} from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, FormCard, Grid, TextField } from "@toolkit/ui";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ProductQuery } from "../../../../../gql";
import { useAttributesQuery } from "pages/ProductsManagement/attribute/gql";

export interface VariantInformationType {
  data: ProductQuery | undefined;
  variant?: ProductVariant | undefined;
  defaultProductData: Product | undefined;
  isEdit?: boolean;
}
interface VariantAttributeComponent {
  fieldName: string;
  defaultValue: any;
  values: any;
  attributeName: string;
}

const VariantInformation = ({ data, variant, defaultProductData, isEdit }: VariantInformationType) => {
  const { t } = useTranslation("admin");
  const {
    control,
    register,
    setValue,
    formState: { errors: error },
  } = useFormContext();

  const {
    data: attributesData,
    fetchMore,
    loading,
  } = useAttributesQuery({
    variables: {
      first: 10,
      filter: {
        productId: defaultProductData?.id,
      },
    },
    skip: !defaultProductData?.id,
  });
  const variantAttributes = attributesData?.attributes?.edges?.map((edge: any) => edge.node);
  const pageInfo = attributesData?.attributes?.pageInfo;
  const handleFetchMore = () => {
    pageInfo?.hasNextPage &&
      fetchMore({
        variables: {
          first: 10,
          after: pageInfo?.endCursor,
          filter: {
            productId: defaultProductData?.id,
          },
        },
      });
  };

  useEffect(() => {
    setValue("name", variant?.name);
    setValue("nameAr", variant?.nameAr);
  }, [setValue, variant]);

  const variantAttributesComponent: VariantAttributeComponent[] | undefined =
    variantAttributes &&
    (variantAttributes.map((attribute: any, index: any) => {
      const fieldName = "variant" + index;
      const values = (attribute?.attribute?.values || attribute.values).map((value: any) => ({
        ...value,
        attributeId: attribute?.attribute?.id || attribute?.id,
      }));
      const defaultAttributes = variant?.attributes;
      const selectedAttributes = defaultAttributes?.find((att: any) => att.attribute.id == attribute.id);
      const attributeName = selectedAttributes?.attribute?.name || attribute?.name;
      const defaultValue = selectedAttributes?.values?.map((value: any) => ({
        ...value,
        attributeId: attribute?.id,
      }));
      return {
        fieldName,
        defaultValue: !isEdit ? null : defaultValue?.[0],
        values,
        attributeName,
      };
    }) as VariantAttributeComponent[]);

  useEffect(() => {
    variantAttributesComponent?.forEach(({ fieldName, defaultValue }) => {
      if (defaultValue) {
        setValue(fieldName, defaultValue);
      }
    });
  }, [variantAttributesComponent, setValue]);

  const doYouHaveData = isEdit ? variant != undefined : true;

  return (
    <>
      <FormCard title={t("Variant")} doYouHaveData={doYouHaveData} loading={loading}>
        <Grid container spacing={formGirdSpacing}>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              placeholder={t("Name")}
              label={t("Name")}
              fullWidth
              {...register("name", {
                required: getRequiredValidation(t, true),
                validate: value => validateValueIsNotEmpty(value, t),
              })}
              error={Boolean(error.name)}
              helperText={t(error.name?.message)}
              defaultValue={variant?.name}
            />
          </Grid>
          <Grid item {...formGirdBreakPoints}>
            <TextField
              placeholder={t("Arabic Name")}
              label={t("Arabic Name")}
              fullWidth
              {...register("nameAr", {
                required: getRequiredValidation(t, true),
                validate: value => validateValueIsNotEmpty(value, t),
                pattern: patternArabicTextWithSpecialCharAndNumbers,
              })}
              error={Boolean(error.nameAr)}
              helperText={t(error.nameAr?.message)}
              defaultValue={variant?.nameAr}
            />
          </Grid>
          {variantAttributesComponent?.length === 0 && (
            <Grid item {...formGirdBreakPoints}>
              <div>{t("No Variant")}</div>
            </Grid>
          )}
          {variantAttributesComponent?.map(({ fieldName, values, defaultValue, attributeName }) => (
            <Grid item {...formGirdBreakPoints} key={fieldName}>
              <AutocompleteController
                ControllerProps={{
                  name: fieldName,
                  control: control,
                  defaultValue: !isEdit ? null : defaultValue?.[0],
                }}
                options={data ? values : values || []}
                TextFieldProps={{
                  placeholder: attributeName,
                  label: attributeName,
                  error: Boolean(error[fieldName]?.message),
                  helperText: error[fieldName]?.message,
                }}
                hasMore={pageInfo?.hasNextPage}
                fetchMoreData={handleFetchMore}
                getOptionLabel={option => option.name}
              />
            </Grid>
          ))}
        </Grid>
      </FormCard>
    </>
  );
};

export default VariantInformation;
VariantInformation.defaultProps = {
  isEdit: true,
  variant: undefined,
};
