import { useReactiveVar } from "@apollo/client";
import { AccountDropdownMenu, CustomerIcon, Layout, useBreadCrumbs, useTheme } from "@toolkit/ui";
import React, { Fragment } from "react";
import { useAuth } from "react-oidc-context";
import { Outlet, useNavigate } from "react-router-dom";
import { Notifications } from "shared/components";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { openVar } from "shared/reactiveVariables";
import { routeWithSelectedItems } from "./utils";

export function LayoutComponent({ routes }: { routes: any }) {
  const auth = useAuth();
  const navigate = useNavigate();
  const { values } = useBreadCrumbs();
  const { userInfo } = useOidcUserProfile();
  const theme = useTheme();

  const username = userInfo?.name! || userInfo?.email!;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleGoToHome = () => {
    navigate("/");
  };
  const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogoutCleanup = async () => {
    await auth.removeUser();
    await auth.revokeTokens();
    await auth.signoutRedirect({
      id_token_hint: auth.user?.id_token,
      post_logout_redirect_uri: window.location.origin,
    });
  };
  const handleLogout = async () => {
    setAnchorEl(null);
    handleLogoutCleanup();
    setTimeout(() => {
      window?.["sseControllerRef"]?.abort();
    }, 250);
  };
  const handleClickClose = () => {
    setAnchorEl(null);
  };
  const open = useReactiveVar(openVar);

  const handleToggleDrawer = () => {
    openVar(!openVar());
  };

  const drawerItems = routeWithSelectedItems(routes);

  return (
    <Fragment>
      {routes && (
        <Layout
          title={values?.title!}
          isOpen={Boolean(open)}
          drawerItems={drawerItems}
          onGoToHome={handleGoToHome}
          onToggleDrawer={handleToggleDrawer}
          rightItems={[
            {
              id: "notifications",
              icon: <Notifications />,
            },
            {
              id: "admin",
              icon: <CustomerIcon fill={theme.palette.primary.main} />,
              onClick: handleClickOpen,
              buttonColor: isOpen ? "info" : "primary",
            },
          ]}
        >
          <Outlet />
        </Layout>
      )}

      {isOpen && (
        <AccountDropdownMenu
          username={username}
          open={isOpen}
          onLogout={handleLogout}
          items={[]}
          anchorEl={anchorEl}
          onClose={handleClickClose}
        />
      )}
    </Fragment>
  );
}
