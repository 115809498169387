/* eslint-disable max-lines */
import { FetchPolicy, MutationHookOptions, MutationTuple, OperationVariables, QueryHookOptions, QueryResult } from "@apollo/client";
import { ReactNode } from "react";
import { CustomTableColumnProps, CustomTableProps } from "../Table/types";
import { ExtractCountableConnectionDataExtractor } from "./utils/countableConnection";

export { CustomTableColumnProps };
export type SortDirection = "DESC" | "ASC" | false;

export enum OrderDirection {
  Asc = "ASC",
  Desc = "DESC",
}
export declare type GridOrder = {
  /** Specifies the direction in which to sort products. */
  direction: OrderDirection;
  /** Sort drivers by the selected field. */
  field?: string;
};
export declare type VariableObject = {
  [key: string]: unknown;
};
export interface BaseGridVariables extends VariableObject {
  first?: number | null;
  after?: string | null;
  before?: string | null;
  last?: number | null;
  filter?: Record<string, any>;
  sortBy?: GridOrder | null;
}
export declare type Exact<
  T extends {
    [key: string]: unknown;
  }
> = {
  [K in keyof T]: T[K];
};

export interface GQuery<TData = any, TVariables extends OperationVariables = Record<string, any>> {
  (options: QueryHookOptions<TData, TVariables>): QueryResult<TData, TVariables>;
}

export type ITableActionProps<Z = any> = {
  isEditVisible?: boolean;
  isDeleteVisible?: boolean;
  onEditRow?: (data: Z) => void;
  isRowEditable?: boolean | ((data: Z) => boolean);
  isRowDeletable?: boolean | ((data: Z) => boolean);
  withoutDataMessage?: string;
};

export type ITablePreference<Z extends object> = {
  isColumnsLoading?: boolean;
  reOrderColumns?: CustomTableColumnProps<Z>[];
  onColumnsChanged?: (data: Pick<CustomTableColumnProps<Z>, "key" | "isHidden">[]) => void;
};

export type GetQueryVars<Q> = Q extends GQuery<any, infer TVariables> ? TVariables : never;
export type GetQueryTData<Q> = Q extends GQuery<infer TData, any> ? TData : never;
export type GetQueryResult<Query> = Query extends GQuery<infer TData, infer TVar> ? ReturnType<GQuery<TData, TVar>> : never;
export type GetQueryResultDataType<Query> = Query extends GQuery<infer TData, infer TVar>
  ? NonNullable<ReturnType<GQuery<TData, TVar>>["data"]>
  : never;

type MayBe<X> = null | X;

export type IConnection<T> = MayBe<{
  __typename?: string;
  edges?: MayBe<
    Array<
      MayBe<
        Partial<{
          __typename?: string;
          cursor?: MayBe<string>;
          node?: T;
        }>
      >
    >
  >;
  pageInfo?: MayBe<
    Partial<{
      __typename?: string;
      endCursor?: MayBe<string>;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: MayBe<string>;
    }>
  >;
  totalCount?: MayBe<number>;
}>;
export declare type IQGridConnectionNode<TData> = TData extends IConnection<infer R> ? R : never;

export declare type ConnectionDocumentNode<TData, cx extends keyof NonNullable<TData>> = NonNullable<TData> extends Partial<
  Record<cx, IConnection<infer R>>
>
  ? R
  : never;

export interface UseQGridStateProps<RowType extends object, TData = any, TVar extends OperationVariables = Record<string, any>> {
  fetchPolicy?: FetchPolicy;
  size?: number;
  skip?: boolean;
  input?: TVar;
  tableAction?: ITableActionProps<RowType>;
  columns: CustomTableColumnProps<RowType>[];
  tablePreference?: ITablePreference<RowType>;
  dataAccessor?:
    | keyof NonNullable<ReturnType<GQuery<TData, TVar>>["data"]>
    | ((x: ReturnType<GQuery<TData, TVar>>["data"]) => Array<RowType>);
  hasSavedInput?: boolean;
  context?: {
    headers: {
      Authorization: string;
    };
  };
  useCustomQuery: GQuery<TData, TVar>;
  onCompleted?: Parameters<GQuery<TData, TVar>>[0]["onCompleted"];
  onError?: Parameters<GQuery<TData, TVar>>[0]["onError"];
  extractCountableConnectionData?: ExtractCountableConnectionDataExtractor<ReturnType<GQuery<TData, TVar>>["data"], RowType>;
}

export interface GMutation<TData = any, TVariables = any> {
  (options: MutationHookOptions<TData, TVariables>): MutationTuple<TData, TVariables>;
}
export interface IGridProviderBase<Z extends object, TData = any, TVar extends OperationVariables = Record<string, any>>
  extends ITablePreference<Z> {
  fetchPolicy?: FetchPolicy;
  gridName: string;
  variables?: GetQueryVars<GQuery<TData, TVar>>;
  hasSavedInput?: boolean;
  filterInput?: Record<string, any>;
  pageSize?: number;
  tableAction?: ITableActionProps<Z>;
  deleteItemProps?: {
    name: string;
    entityTypeName: string;
    idPropName?: string;
    useDeleteMutation: GMutation;
    extraVariables?: { [key: string]: any };
  };
  context?: {
    headers: {
      Authorization: string;
    };
  };
  isCardView?: boolean;
  children?: ReactNode;
  skipCall?: boolean;
  hideFilterInput?: boolean;
  dataAccessor?: UseQGridStateProps<Z, TData, TVar>["dataAccessor"];
  columns: UseQGridStateProps<Z, TData, TVar>["columns"];
  query: UseQGridStateProps<Z, TData, TVar>["useCustomQuery"];
  onError?: UseQGridStateProps<Z, TData, TVar>["onError"];
  onCompleted?: UseQGridStateProps<Z, TData, TVar>["onCompleted"];
  extractCountableConnectionData?: UseQGridStateProps<Z, TData, TVar>["extractCountableConnectionData"];
}

export type GridContextProps<Z extends object = Record<any, any>> = {
  customTableProps?: CustomTableProps<Z>;
  staticFiltersProps?: {
    isOpen: boolean;
    activeFilters: Record<string, any>;
    onToggleDialog: () => void;
    onApplyFilters: (input: Record<string, any>, filters: Record<string, any>, requestedFilters: Record<string, any>) => void;
    onDismissFilters: () => void;
  };
  activeFiltersProps?: {
    filters: Record<string, any>;
    requestedFilters: Record<string, any>;
    activeFilters: Record<string, any>;
    onOpenFilters: () => void;
    hasTranslation?: boolean;
  };
  queryResponse?: any;
  refetch?: any;
  tablePreference?: ITablePreference<Z>;
  fields?: CustomTableColumnProps<Z>[];
  isLoading?: boolean;
};
