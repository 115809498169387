import { CircularProgress } from "@mui/material";
import { useTranslation } from "@toolkit/i18n";
import React, { FC, PropsWithChildren, useState } from "react";
import { PlusIcon } from "../../icons";
import { WarningConfirmationDialog } from "../Dialogs";
import { FormActionsWrapperStyled, StyledButton } from "./FormActions.styles";
import { FormActionsProps } from "./types";

export const FormActions: FC<PropsWithChildren<FormActionsProps>> = props => {
  const { t } = useTranslation();

  const {
    children,
    isLoading,
    loadingIndicators,
    hasSave,
    isSaveDisabled = false,
    hasEdit,
    hasCancel,
    hasFormButton,
    hasAddNewButton,
    formButtonTitle,
    TitleNewButton,
    isChanged,
    newButtonDisabled,
    onSave: handleSave,
    onEditItem: handleEditItem,
    onAddNewButton: handleAddNewButton,
    onNavigation: handleNavigation,
  } = props;
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    if (isChanged) {
      setOpen(true);
    } else {
      handleNavigation?.();
    }
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <FormActionsWrapperStyled>
      <div>
        {children}
        {hasEdit && (
          <StyledButton
            color='success'
            size='medium'
            type='submit'
            disabled={loadingIndicators?.edit}
            startIcon={loadingIndicators?.edit ? <CircularProgress size={12} /> : <PlusIcon />}
            onClick={handleEditItem}
          >
            {loadingIndicators?.edit ? t("Updating") + "..." : t("Update")}
          </StyledButton>
        )}

        {hasAddNewButton && (
          <StyledButton
            color='warning'
            size='medium'
            type='submit'
            startIcon={<PlusIcon />}
            onClick={handleAddNewButton}
            sx={{ minWidth: "max-content" }}
          >
            {TitleNewButton}
          </StyledButton>
        )}
        {!isLoading && hasFormButton && (
          <StyledButton color='success' size='medium' type='submit' disabled={newButtonDisabled}>
            {formButtonTitle}
          </StyledButton>
        )}
        {hasSave && (
          <StyledButton
            color='success'
            size='medium'
            type='submit'
            startIcon={loadingIndicators?.save ? <CircularProgress size={12} /> : <PlusIcon />}
            onClick={handleSave}
            disabled={isSaveDisabled}
            sx={{ minWidth: "max-content" }}
          >
            {loadingIndicators?.save ? t("Saving...") : t("Save")}
          </StyledButton>
        )}
      </div>
      {hasCancel && (
        <StyledButton variant='outlined' size='medium' onClick={handleCancel}>
          {t("Cancel")}
        </StyledButton>
      )}
      <WarningConfirmationDialog
        isOpen={open}
        confirmText={t("Ok")}
        bodyText={t("Are you sure you want to cancel editing fields?")}
        cancelText={t("Cancel")}
        onConfirm={handleNavigation}
        onClose={handleToggle}
      />
    </FormActionsWrapperStyled>
  );
};
