import { makeStyles } from "tss-react/mui";

export const useBaseDialogStyle = makeStyles()(theme => ({
  root: {
    ".MuiDialogActions-root": {
      justifyContent: "flex-start",
      padding: "15px",
      boxShadow: theme.mixins.shadows.md,
      borderRadius: "0px 0px 15px 15px",
    },
  },
  dialogTitle: {
    display: "flex",
    flex: 1,
    maxHeight: "50px",
    padding: "30px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  dialogTitleLabel: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    marginBottom: 5,
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
  dialogTitleCloseIcon: {
    height: 30,
    width: 30,
  },
  dialogContent: {
    paddingTop: 20,
  },
}));
