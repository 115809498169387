import { zodResolver } from "@hookform/resolvers/zod";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { InsurancePolicyInformation } from "./components/InsurancePolicyInformation";
import { InsurancePolicyFormProps } from "./types";
import { useInsurancePolicyFormValidation } from "./useInsurancePolicyFormValidation";

export const InsurancePolicyForm: FC<InsurancePolicyFormProps> = ({
  errors,
  isLoading,
  insurancePolicy,
  isEditMode,
  isSubmitting,
  onDone,
}) => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const { getDefaultFormValues, insurancePolicyFormSchema } = useInsurancePolicyFormValidation({ t });

  const methods = useForm({
    defaultValues: getDefaultFormValues(insurancePolicy),
    resolver: zodResolver(insurancePolicyFormSchema),
    mode: "onChange",
  });

  const { handleSubmit } = methods;
  const handleCancel = () => navigate(-1);

  const doFormHaveData = isEditMode ? insurancePolicy != undefined : true;

  useEffect(() => {
    if (!isLoading) {
      methods.reset(getDefaultFormValues(insurancePolicy));
    }
  }, [isLoading]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onDone)}>
        <PageWrapper
          actions={
            <FormActions
              isLoading={isLoading}
              hasCancel
              hasFormButton
              formButtonTitle={t("Save")}
              onNavigation={handleCancel}
              newButtonDisabled={isSubmitting}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard loading={isLoading} title={t("Insurance Policy Information")} doYouHaveData={doFormHaveData && !isLoading}>
                <InsurancePolicyInformation defaultValues={insurancePolicy} errors={errors} />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
