import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

export const MyLocationIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='20' height='20.01' viewBox='0 0 20 20.01' {...props}>
      <path
        id='icons8-my-location'
        d='M10.989.986a.714.714,0,0,0-.7.724v.751a8.579,8.579,0,0,0-7.822,7.821H1.714a.714.714,0,1,0,0,1.429h.751a8.578,8.578,0,0,0,7.821,7.821v.751a.714.714,0,1,0,1.429,0v-.751a8.578,8.578,0,0,0,7.821-7.821h.751a.714.714,0,1,0,0-1.429h-.749a8.579,8.579,0,0,0-7.822-7.821V1.71a.714.714,0,0,0-.725-.724Zm-.7,2.9v.679a.714.714,0,1,0,1.429,0V3.888a7.131,7.131,0,0,1,6.392,6.394h-.678a.714.714,0,1,0,0,1.429h.679A7.133,7.133,0,0,1,11.714,18.1v-.679a.714.714,0,1,0-1.429,0V18.1A7.133,7.133,0,0,1,3.892,11.71h.679a.714.714,0,1,0,0-1.429H3.893A7.131,7.131,0,0,1,10.286,3.888ZM11,8.139A2.857,2.857,0,1,0,13.857,11,2.857,2.857,0,0,0,11,8.139Z'
        transform='translate(-1 -0.986)'
      />
    </SvgIcon>
  );
};
