import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const BusinessRules: FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='21.123' height='16.054' viewBox='0 0 21.123 16.054' {...props}>
      <path
        id='icons8-flow'
        d='M1.267,6A1.274,1.274,0,0,0,0,7.267V9.8A1.274,1.274,0,0,0,1.267,11.07h3.38A1.274,1.274,0,0,0,5.914,9.8V8.957H8.449V9.8A1.274,1.274,0,0,0,9.717,11.07H13.1A1.274,1.274,0,0,0,14.364,9.8V8.957h2.112a1.268,1.268,0,0,1,1.267,1.267v1.267H16.476a1.274,1.274,0,0,0-1.267,1.267v2.535a1.274,1.274,0,0,0,1.267,1.267h1.267v1.267A1.268,1.268,0,0,1,16.476,19.1H14.364v-.845A1.274,1.274,0,0,0,13.1,16.984H9.717a1.274,1.274,0,0,0-1.267,1.267V19.1H5.914v-.845a1.274,1.274,0,0,0-1.267-1.267H1.267A1.274,1.274,0,0,0,0,18.251v2.535a1.274,1.274,0,0,0,1.267,1.267h3.38a1.274,1.274,0,0,0,1.267-1.267v-.845H8.449v.845a1.274,1.274,0,0,0,1.267,1.267H13.1a1.274,1.274,0,0,0,1.267-1.267v-.845h2.112a2.121,2.121,0,0,0,2.112-2.112V16.562h1.267a1.274,1.274,0,0,0,1.267-1.267V12.759a1.274,1.274,0,0,0-1.267-1.267H18.588V10.225a2.121,2.121,0,0,0-2.112-2.112H14.364V7.267A1.274,1.274,0,0,0,13.1,6H9.717A1.274,1.274,0,0,0,8.449,7.267v.845H5.914V7.267A1.274,1.274,0,0,0,4.647,6Zm0,.845h3.38a.421.421,0,0,1,.422.422V9.8a.421.421,0,0,1-.422.422H1.267A.421.421,0,0,1,.845,9.8V7.267A.421.421,0,0,1,1.267,6.845Zm8.449,0H13.1a.421.421,0,0,1,.422.422V9.8a.421.421,0,0,1-.422.422H9.717A.421.421,0,0,1,9.294,9.8V7.267A.421.421,0,0,1,9.717,6.845Zm6.759,5.492h3.38a.421.421,0,0,1,.422.422v2.535a.421.421,0,0,1-.422.422h-3.38a.421.421,0,0,1-.422-.422V12.759A.421.421,0,0,1,16.476,12.337ZM1.267,17.829h3.38a.421.421,0,0,1,.422.422v2.535a.421.421,0,0,1-.422.422H1.267a.421.421,0,0,1-.422-.422V18.251A.421.421,0,0,1,1.267,17.829Zm8.449,0H13.1a.421.421,0,0,1,.422.422v2.535a.421.421,0,0,1-.422.422H9.717a.421.421,0,0,1-.422-.422V18.251A.421.421,0,0,1,9.717,17.829Z'
        transform='translate(0 -6)'
        fill={props?.fill ? props?.fill : theme.palette.primary.main}
      />
    </SvgIcon>
  );
};
