import { useLoadScript } from "@react-google-maps/api";
import { getToolkitEnvVariable } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { ChangeEvent, useMemo, useState } from "react";
import { useLocationHooks } from "../utils";
import { ILocationPickerProps } from "./types";

export const useLocationPickerHook = (props: ILocationPickerProps) => {
  const { getAddressFromLatLng } = useLocationHooks();
  const { t, i18n } = useTranslation();
  const { location, defaultCenter, hasLocationLngLat, onLocationChange } = props;
  const center = useMemo(() => (location?.lat && location?.lng ? location : defaultCenter), [defaultCenter]);

  const [isChecked, setIsChecked] = useState(false);

  const handlePickLocation = async (event: google.maps.MapMouseEvent) => {
    const locationAddress = await getAddressFromLatLng(event?.latLng?.lat()?.toString()!, event?.latLng?.lng()?.toString()!);
    onLocationChange?.({ ...locationAddress, coordinates: { lat: event?.latLng?.lat()!, lng: event?.latLng?.lng()! } });
  };

  const handleIsChecked = () => {
    setIsChecked(state => !state);
  };

  const handleLocationChanged = (type: "lng" | "lat") => async (event: ChangeEvent<HTMLInputElement>) => {
    const pickedLocation = { lng: center?.lng!, lat: center?.lat!, [type]: Number(event.target.value)! };
    const locationAddress = await getAddressFromLatLng(pickedLocation?.lat?.toString()!, pickedLocation?.lng?.toString()!);
    onLocationChange?.({ ...locationAddress, coordinates: pickedLocation });
  };
  const { isLoaded } = useLoadScript({
    id: "script-loader",
    version: "quarterly",
    googleMapsApiKey: getToolkitEnvVariable(
      "GOOGLE_API_KEY",
      process.env.REACT_APP_GOOGLE_API_KEY || process.env.NEXT_PUBLIC_GOOGLE_API_KEY!
    )!,
    libraries: ["places"],
    language: i18n.language,
  });
  return {
    ...props,
    isLoaded,
    hasLocationLngLat,
    center,
    isChecked,
    t,
    handlePickLocation,
    handleIsChecked,
    handleLocationChanged,
    handleSearchLocation: (newLocation: {
      coordinates: {
        lat: number;
        lng: number;
      };
    }) => onLocationChange?.(newLocation),
  };
};
