import { FormControlLabel, Radio, RadioGroup, TextField, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTranslation } from "@toolkit/i18n";
import React from "react";
import { useFormContext } from "react-hook-form";
import { CustomDatePicker, CustomDateTimePickerField } from "../FormInputs";
import { SmartAutocompleteFilter } from "../GridProvider";
import { AutoCompleteFilter } from "./AutoCompleteFilter";
import { IFields } from "./Filter.types";

const FilterItems = <X extends object>({ field }: IFields<X>) => {
  const { register, control, watch } = useFormContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const getFieldUiComponent = (filter: NonNullable<NonNullable<IFields<X>["field"]>["filter"]>) => {
    const value = watch(filter?.name!);

    const displayName = typeof field?.header === "string" ? field?.header! : filter?.name;
    if (!filter?.type) throw new Error("Filter type is not defined");
    switch (filter?.type) {
      case "autocomplete":
        return <AutoCompleteFilter field={field!} filter={filter as SmartAutocompleteFilter} />;
      case "string":
        return (
          <TextField
            placeholder={displayName}
            label={displayName}
            fullWidth
            defaultValue={filter?.initialValue}
            {...register(filter?.name)}
            rows={filter?.rows}
            multiline={!!filter?.rows}
          />
        );
      case "number":
        return (
          <TextField
            type='number'
            placeholder={displayName}
            label={displayName}
            defaultValue={filter?.initialValue}
            fullWidth
            inputProps={{
              min: filter?.min,
              max: filter?.max,
            }}
            {...register(filter?.name, {
              setValueAs: v => {
                return !v ? null : parseInt(v);
              },
            })}
          />
        );
      case "date":
        return (
          <CustomDatePicker
            control={control}
            defaultValue={filter?.initialValue}
            name={filter?.name}
            label={displayName}
            placeholder={displayName}
          />
        );
      case "radioGroup":
        return (
          <>
            <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontFamily={theme.mixins.fonts.fontFamily.medium}>
              {t(displayName)}
            </Typography>
            <RadioGroup
              aria-labelledby='radio-label'
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
              defaultValue={filter?.initialValue}
              value={value}
            >
              {filter?.options?.map(option => (
                <FormControlLabel
                  key={option.label}
                  value={option.value}
                  control={<Radio value={option.value} {...register(filter?.name)} />}
                  label={t(option.label)}
                />
              ))}
            </RadioGroup>
          </>
        );
      case "dateTime":
        return (
          <CustomDateTimePickerField
            control={control}
            defaultValue={filter?.initialValue}
            name={filter?.name}
            label={displayName}
            placeholder={displayName}
          />
        );
      default:
        return <Typography> {t("Component type not defined")}</Typography>;
    }
  };

  return !field ? null : (
    <>
      {Array.isArray(field?.filter) ? (
        <Grid flex={1} item xs={12} container spacing={2}>
          {field.filter?.map((item, index) => (
            <Grid item flex={1} key={index}>
              {getFieldUiComponent(item)}
            </Grid>
          ))}
        </Grid>
      ) : (
        getFieldUiComponent(field.filter!)
      )}
    </>
  );
};

export default FilterItems;
