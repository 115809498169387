import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, CustomDialog, CustomInfiniteScroll, Divider, ExpandMoreIcon, StyledButton, Typography, VersionsIcon, useTheme, } from "@toolkit/ui";
import { VersionListHeader } from "./ListHeader";
import { useVersionsListHooks } from "./VersionList.hooks";
import { VersionLoader } from "./VersionLoader";
import { VersionRow } from "./VersionRow";
export const VersionsList = () => {
    const { versionParamId, versionData, latestApprovedVersionNumber, isVersionsOpened, isPublishing, versionDataLoading, isFetchingMoreVersions, hasNextPageVersions, isRefetchingVersions, t, handleDownloadFileFromVersion, handleOpenVersionClick, handleOnFetchMoreVersions, handleChangeActiveVersion, handleToggleVersions, } = useVersionsListHooks();
    const theme = useTheme();
    return (_jsx(CustomDialog, Object.assign({ maxWidth: 'lg', title: t("Versions"), open: isVersionsOpened, onCloseModal: handleToggleVersions, button: _jsx(StyledButton, Object.assign({ variant: 'outlined', color: 'primary', size: 'medium', startIcon: _jsx(VersionsIcon, { color: 'inherit' }), onClick: handleToggleVersions, sx: {
                border: "1px solid" + theme.palette.primary.main,
            } }, { children: t("Versions") })) }, { children: versionDataLoading ? (_jsx(VersionLoader, {})) : (_jsx(CustomInfiniteScroll, Object.assign({ dataLength: (versionData === null || versionData === void 0 ? void 0 : versionData.length) || 0, isFetchingMore: isFetchingMoreVersions, onRefresh: handleOnFetchMoreVersions }, { children: !isRefetchingVersions && (versionData === null || versionData === void 0 ? void 0 : versionData.length) ? (_jsxs(_Fragment, { children: [_jsx(VersionListHeader, {}), _jsx(Divider, { sx: { marginBottom: "16px", borderBottomWidth: "medium", borderColor: theme.palette.primary.main } }), versionData.map(version => (_jsx(VersionRow, { versionParamId: versionParamId, isLoading: isPublishing, version: version, onVersionOpenClick: handleOpenVersionClick, onDownload: handleDownloadFileFromVersion, onVersionActiveChange: handleChangeActiveVersion, latestApprovedVersionNumber: latestApprovedVersionNumber }, version === null || version === void 0 ? void 0 : version.id))), hasNextPageVersions && (_jsx(Box, Object.assign({ display: 'flex', justifyContent: 'center' }, { children: _jsxs(Button, Object.assign({ disabled: !hasNextPageVersions, variant: 'outlined', onClick: handleOnFetchMoreVersions }, { children: [t("More versions"), _jsx(ExpandMoreIcon, {})] })) })))] })) : (_jsx(Typography, Object.assign({ variant: 'h6', color: theme.palette.primary.main }, { children: t("No versions has been added or created for this List ") }))) }))) })));
};
