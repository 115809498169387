/* eslint-disable react/jsx-handler-names */
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useTranslation } from "@toolkit/i18n";
import React from "react";
import { CloudErrorIcon } from "../../icons";

export const ErrorPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' height='100vh'>
      <CloudErrorIcon sx={{ height: "190px", width: "190px", fill: theme.palette.primary.main }} />
      <Typography fontSize='40px' fontFamily={theme.mixins.fonts.fontFamily.regular} justifyContent='center' textAlign='center'>
        {t("Something Wrong Happened")}
      </Typography>
      <Button
        onClick={() => window.location.reload()}
        sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.common.white, margin: "20px 0" }}
      >
        {t("Reload")}
      </Button>
    </Box>
  );
};
