import { Delete, ZoomIn } from "@mui/icons-material";
import React, { FC } from "react";
import { styles } from "./styles";
import { ImageDisplayProps } from "./types";
import ZoomUploadedImage from "./ZoomUploadedImage";

export const ImageDisplay: FC<ImageDisplayProps> = ({ uploadedImage, canDeleteImage, onDelete: handleDelete }) => {
  const { classes } = styles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.containerImg}>
        <img className={classes.img} src={uploadedImage} alt='ImageUpload' />
        <div className={classes.icons}>
          {canDeleteImage && <Delete width={30} height={30} color='error' onClick={handleDelete} />}
          <ZoomIn width={70} height={70} onClick={handleClickOpen} />
        </div>
      </div>
      {open && <ZoomUploadedImage open={open} onClose={handleClose} uploadedImage={uploadedImage} />}
    </>
  );
};
