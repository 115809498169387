/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { i18next, useTranslation } from "@toolkit/i18n";
import React from "react";
import { ArrowButton, PrevButton } from "../../../icons/icons";
import { useTableFooterStyles } from "../styles/useTableFooterStyles";
import { CustomTableFooterProps } from "./types";

const noop = () => undefined;

const CustomTableFooter: React.FC<CustomTableFooterProps> = props => {
  const {
    // totalCount,
    // pageSize,
    footerHeight = 60,
    pageIndex = 0,
    hasNextPage = false,
    hasPreviousPage = false,
    // onPageSizeChange = noop,
    onGotoPage = noop,
    onGoToNext = noop,
    onGoToPrevious = noop,
  } = props;
  const { t } = useTranslation();

  const { classes } = useTableFooterStyles();

  const handleGoToNextPage = () => {
    onGotoPage(pageIndex + 1);
    onGoToNext();
  };
  const handleGoToPreviousPage = () => {
    onGotoPage(pageIndex - 1);
    onGoToPrevious();
  };
  const direction = i18next.dir(i18next.language);
  const previousArrowIcon =
    direction === "ltr" ? (
      <PrevButton color='inherit' className={classes.buttonIcons} />
    ) : (
      <ArrowButton color='inherit' className={classes.buttonIcons} />
    );
  const nextArrowIcon =
    direction === "ltr" ? (
      <ArrowButton color='inherit' className={classes.buttonIcons} />
    ) : (
      <PrevButton color='inherit' className={classes.buttonIcons} />
    );
  return (
    <Grid container direction='row' justifyContent='center' alignItems='center' className={classes.root}>
      <Grid item>
        <Button
          variant={"outlined"}
          disabled={!hasPreviousPage}
          className={classes.prevIconButton}
          onClick={handleGoToPreviousPage}
          startIcon={previousArrowIcon}
          sx={{
            "& .MuiButton-startIcon": {
              marginRight: "4px",
            },
          }}
        >
          {t("Previous")}
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant={"contained"}
          color='primary'
          disabled={!hasNextPage}
          className={classes.nextIconButton}
          onClick={handleGoToNextPage}
          endIcon={nextArrowIcon}
          sx={{
            "& .MuiButton-endIcon": {
              marginLeft: "4px",
            },
          }}
        >
          {t("Next")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CustomTableFooter;
