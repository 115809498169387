import { InsurancePolicy } from "@health/queries/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomToggleButton, useAddToast } from "@toolkit/ui";
import { FC, useEffect, useState } from "react";
import { InsurancePolicyUpdateMutation, useInsurancePolicyUpdateMutation } from "../../gql";

const ActiveToggleSwitch: FC<{ row: InsurancePolicy }> = ({ row }) => {
  const { t } = useTranslation();
  const { succeeded, failed } = useAddToast();
  const [check, setCheck] = useState(row?.isActive);

  const [insurancePolicyUpdateMutation, { loading }] = useInsurancePolicyUpdateMutation({
    onCompleted: (data: InsurancePolicyUpdateMutation) => {
      const mutationErrors = data?.insurancePolicyUpdate?.entityErrors;
      if (mutationErrors && mutationErrors?.length > 0) {
        failed(t(formatMessageErrors(mutationErrors)));
      } else {
        succeeded(t("Insurance policy status updated successfully"));
      }
    },
  });
  useEffect(() => {
    setCheck(row.isActive);
  }, [row.isActive]);

  const handleToggleChange = (_, isActive) => {
    insurancePolicyUpdateMutation({
      variables: {
        insurancePolicyUpdateId: row?.id as string,
        input: {
          insuranceNetworkId: row?.network?.id,
          insuranceOutpatientPolicyInput: {
            cons: row.outpatientPolicy?.cons,
            dental: row.outpatientPolicy?.dental,
            optical: row.outpatientPolicy?.optical,
            outpatient: row.outpatientPolicy?.outpatient,
            pharm: row.outpatientPolicy?.pharm,
          },
          policyName: row.name,
          isActive,
        },
      },
    });
  };

  return (
    <Box sx={{ display: "flex", alignContent: "flex-start" }}>
      <CustomToggleButton disabled={loading} value={check} defaultChecked={Boolean(check)} onChange={handleToggleChange} />
    </Box>
  );
};

export default ActiveToggleSwitch;
