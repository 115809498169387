import { createZodAutocompleteObject } from "@toolkit/ui";
import { z } from "zod";
const guidedCareTeamMemberAutocomplete = createZodAutocompleteObject(z.object({
    id: z.string(),
    name: z.string(),
}));
export const GuidedCareTeamMemberAutocompleteSchemas = {
    guidedCareTeamMemberAutocomplete,
    guidedCareTeamMemberAutocompleteOptional: guidedCareTeamMemberAutocomplete.nullable().optional(),
};
