/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable react/jsx-handler-names */
import { formGirdSpacing } from "@toolkit/core";
import { Button, CircularProgress, Grid, PlusIcon, WarningConfirmationDialog } from "@toolkit/ui";
import React from "react";
import { FormProvider } from "react-hook-form";
import FormFooterWrapper from "shared/components/FormFooterWrapper";
import { CloneDialog } from "./components/CloneDialog";
import { PublishDialog } from "./components/PublishDialog";
import QuestionCard from "./components/QuestionCard";
import SurveyInformation from "./components/SurveyInformation";
import UnPublishDialog from "./components/UnPublishDialog";
import { useSurveyQuestionsTab } from "./useSurveyQuestionsTab";

export const SurveyQuestionsTab: React.FC = () => {
  const {
    survey,
    isLoadingSurvey,
    methods,
    questions,
    classes,
    isSaveButtonVisible,
    isSaveDisabled,
    isPublishModalOpen,
    isUnPublishDialogOpen,
    isPublishDisabled,
    isSurveyCancelable,
    canUnPublishSurvey,
    isCreateLoading,
    isUpdateLoading,
    isPublishLoading,
    isUnPublishLoading,
    isCancelModalOpen,
    isCloneLoading,
    isCloneModalOpen,
    canManageSurvey,
    isEditedForm,
    handleAddOption,
    handleRemoveQuestion,
    handleRemoveOption,
    handleAddQuestion,
    handlePublishModalOpen,
    handleSaveSurvey,
    handlePublish,
    handleUnPublish,
    handleToggleUnPublishModalOpen,
    handleClosePublishModal,
    handleResetFields,
    t,
    handleCloseCancelModal,
    handleToggleCancelModalOpen,
    handleCancel,
    handleCancelSurvey,
    handleToggleCloneModalOpen,
    handleCloneSurvey,
    isDisabled,
  } = useSurveyQuestionsTab();

  return (
    <>
      <FormProvider {...methods}>
        <Grid container spacing={formGirdSpacing}>
          <Grid item xs={12} marginX={3} marginTop={5}>
            <SurveyInformation isLoading={isLoadingSurvey} />
          </Grid>
          {!!questions.length && (
            <Grid item xs={12} marginX={3}>
              {questions.map((question, qIndex) => (
                <Grid item key={question.id} marginBottom='15px'>
                  <QuestionCard
                    questionIndex={qIndex}
                    onRemoveQuestion={() => handleRemoveQuestion(qIndex)}
                    onAddOption={handleAddOption(qIndex)}
                    onRemoveOption={index => handleRemoveOption(qIndex, index)}
                    onCopyOption={handleAddOption(qIndex)}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          {!isLoadingSurvey && canManageSurvey ? (
            <Grid item mb={12} marginX={3}>
              <Button startIcon={<PlusIcon />} className={classes.addQuestionButton} onClick={handleAddQuestion}>
                {t("Add Question")}
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <FormFooterWrapper>
          <Grid container justifyContent='space-between' alignItems='center' padding={2}>
            <Grid item>
              {isSaveButtonVisible && (
                <Button
                  variant='contained'
                  color={"primary"}
                  disabled={isSaveDisabled}
                  startIcon={(isCreateLoading || isUpdateLoading) && <CircularProgress size={15} />}
                  onClick={handleSaveSurvey}
                >
                  {t("Save")}
                </Button>
              )}
              {survey?.id && canManageSurvey && (
                <>
                  <Button
                    disabled={isPublishDisabled || isPublishLoading}
                    startIcon={isPublishLoading && <CircularProgress size={15} />}
                    onClick={handlePublishModalOpen}
                  >
                    {t("Publish")}
                  </Button>
                  <Button
                    disabled={isCloneLoading || !isSaveDisabled || isEditedForm}
                    startIcon={isCloneLoading && <CircularProgress size={15} />}
                    onClick={handleToggleCloneModalOpen}
                  >
                    {t("Clone Survey")}
                  </Button>
                  <Button
                    variant='outlined'
                    color='error'
                    disabled={isUnPublishLoading || !canUnPublishSurvey}
                    startIcon={isUnPublishLoading && <CircularProgress size={15} />}
                    onClick={handleToggleUnPublishModalOpen}
                  >
                    {t("UnPublish")}
                  </Button>
                  {isSurveyCancelable && (
                    <Button
                      variant='outlined'
                      color='error'
                      disabled={isDisabled}
                      onClick={survey?.id ? handleToggleCancelModalOpen : handleCloseCancelModal}
                    >
                      {t("Cancel Survey")}
                    </Button>
                  )}
                </>
              )}
            </Grid>
            <Grid item>
              {canManageSurvey && (
                <Button variant='outlined' disabled={isDisabled} onClick={handleResetFields}>
                  {t("Reset Fields")}
                </Button>
              )}
              <Button variant='outlined' onClick={handleCancel}>
                {t("Cancel")}
              </Button>
            </Grid>
          </Grid>
        </FormFooterWrapper>
      </FormProvider>
      <PublishDialog isLoading={isPublishLoading} isOpen={isPublishModalOpen} onPublish={handlePublish} onClose={handleClosePublishModal} />
      <UnPublishDialog
        isOpen={isUnPublishDialogOpen}
        onClose={handleToggleUnPublishModalOpen}
        onUnPublish={handleUnPublish}
        isUnPublishLoading={isUnPublishLoading}
      />
      <WarningConfirmationDialog
        isOpen={isCancelModalOpen}
        titleText={t("Cancel Survey Confirmation")}
        bodyText={t("Are you sure you want to cancel this survey?")}
        confirmText={t("Delete")}
        cancelText={t("Cancel")}
        onClose={handleToggleCancelModalOpen}
        onConfirm={handleCancelSurvey}
      />
      <CloneDialog isLoading={isCloneLoading} isOpen={isCloneModalOpen} onClone={handleCloneSurvey} onClose={handleToggleCloneModalOpen} />
    </>
  );
};

export default SurveyQuestionsTab;
