/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-statements */
import { Tooltip, Typography } from "@mui/material";
import { useFileUpload } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { DragAndDropImageIcon } from "../../icons";
import { getImage } from "../FormInputs/utils";
import { ImageDisplay } from "./ImageDisplay";
import { styles } from "./styles";
import { IImageUploadProps } from "./types";

// ToDo refactor this component it supposed to not work at all!
const ImageUpload: FC<IImageUploadProps> = ({
  handleImageUploaded,
  image,
  label,
  id,
  ratioImage = {
    width: 150,
    height: 210,
  },
  errorImage,
  helperText,
  validationRation = false,
  canDeleteImage = false,
  onDelete = () => {},
  hasMultipleImages = false,
  token,
}): React.ReactElement => {
  const { t } = useTranslation();
  const { classes, theme } = styles();
  const [isDragging, setIsDragging] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<string | string[]>();
  const [isFormatError, setFormatError] = useState(false);
  const [error, setError] = useState(false);
  const { fetchUploadFile } = useFileUpload({ token });
  useEffect(() => {
    setUploadedImage(image);
  }, [image]);

  const handleDelete = () => {
    setUploadedImage(undefined);
    onDelete(null);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    handleFileUpload(e);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(!isDragging);
  };
  const handleFileUpload = (e: ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
    const validImagesType = ["image/jpeg", "image/png", "image/svg+xml", "image/apng", "image/bmp", "image/gif"];

    const files = "dataTransfer" in e ? e.dataTransfer?.files! : e.target.files!;
    const file = files?.[0]!;
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = function () {
      const ratio = img.width / img.height;
      const isValidRatio = ratio >= ratioImage.width / ratioImage.height || validationRation;
      setError(!isValidRatio);
    };

    const isFileTypeValid = validImagesType.includes(file.type);

    if (isFileTypeValid) {
      fetchUploadFile({
        files,
        onComplete: fileName => {
          const newImage = hasMultipleImages ? [...(uploadedImage || []), getImage(fileName)] : getImage(fileName);
          handleImageUploaded(newImage || fileName, e as any);
        },
      });
      setFormatError(false);
      // ToDo this should be wrong ! or at least wrong typing
      setUploadedImage(file as any);
    } else {
      setFormatError(true);
    }
  };

  const isItEmpty = hasMultipleImages ? uploadedImage?.length! === 0 : !uploadedImage;
  return (
    <>
      {label && <Typography mb={"8px"}>{label}</Typography>}
      <div onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave} className={classes.root}>
        <div className={errorImage ? classes.errorsContainer : classes.container}>
          <input
            accept='image/*'
            className={classes.input}
            id={`contained-button-file${id}`}
            name={`contained-button-file${id}`}
            multiple
            type='file'
            onChange={handleFileUpload}
          />
          <span className={classes.button}></span>
          <label htmlFor={`contained-button-file${id}`} className={classes.label}>
            <DragAndDropImageIcon
              width={40}
              height={40}
              color={isFormatError ? "error" : "secondary"}
              className={`${classes.imageIcon} `}
            />
            <Tooltip
              title={t("Expected Image Size 150x210 ", {
                width: ratioImage.width,
                height: ratioImage.height,
              })}
            >
              <Typography variant='body1' align={"center"} dir={"ltr"} color={theme.palette.stale.main}>
                {t("Drag and Drop , or")} <br />
                <span className={classes.span}>{t("browse")} </span>
                {t("your Thumbnails")}
                {error && (
                  <Typography variant='subtitle1' color='error'>
                    {t("The size of uploaded image is small, please try to upload another image")}
                  </Typography>
                )}
                {isFormatError && (
                  <Typography variant='subtitle1' color='error' align='center'>
                    {t("Unsupported file type")}
                  </Typography>
                )}
              </Typography>
            </Tooltip>
          </label>
        </div>
        {helperText && (
          <Typography variant='caption' color='error' align='center' className={classes.imageIconError}>
            {helperText}
          </Typography>
        )}

        {!hasMultipleImages && !isItEmpty && (
          <ImageDisplay canDeleteImage={canDeleteImage} uploadedImage={uploadedImage as string} onDelete={handleDelete} />
        )}
        <div className={classes.images}>
          {hasMultipleImages &&
            (uploadedImage as string[])?.map((item, index) => (
              <div key={item + index}>
                <ImageDisplay canDeleteImage={canDeleteImage} uploadedImage={item} onDelete={handleDelete} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ImageUpload;
