import { OrderCheckoutTypesEnum } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const orderCheckoutTypeOptionsMap = {
    [OrderCheckoutTypesEnum.HealthPackage]: {
        key: OrderCheckoutTypesEnum.HealthPackage,
        get label() {
            return i18n.t("Health Package", { ns: "domains" });
        },
        value: OrderCheckoutTypesEnum.HealthPackage,
    },
    [OrderCheckoutTypesEnum.Product]: {
        key: OrderCheckoutTypesEnum.Product,
        get label() {
            return i18n.t("Product", { ns: "domains" });
        },
        value: OrderCheckoutTypesEnum.Product,
    },
};
export const orderCheckoutTypeOptions = Object.values(orderCheckoutTypeOptionsMap);
