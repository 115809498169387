/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../../gql/fragments/__generated__/PageInfo';
import { ProductFragmentFragmentDoc } from './productFragment';
import { CategoryFragmentFragmentDoc } from '../../../../categories/gql/queries/__generated__/categoryFragment';
import { ProductTypeFragmentFragmentDoc } from '../../../../productTypes/gql/queries/__generated__/productTypesFragment';
import { ProductBrandFragmentFragmentDoc } from '../../../../productBrand/gql/queries/__generated__/productBrandFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminProductsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.ProductFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.ProductOrder>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type AdminProductsQuery = { __typename?: 'Query', products?: { __typename?: 'ProductCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'ProductCountableEdge', node: { __typename?: 'Product', mainImageUrl?: string | null, createdAt: any, isPublished: boolean, name: string, nameAr?: string | null, id: string, isAddon?: boolean | null, hasAddons?: boolean | null, benefits?: string | null, howToUse?: string | null, attributes: Array<{ __typename?: 'SelectedAttribute', values: Array<{ __typename?: 'AttributeValue', name?: string | null } | null>, attribute: { __typename?: 'Attribute', name?: string | null, id: string, values?: Array<{ __typename?: 'AttributeValue', name?: string | null, id: string } | null> | null } }>, category?: { __typename?: 'Category', id: string, name: string, level: number, description: string, slug: string, parent?: { __typename?: 'Category', id: string } | null, children?: { __typename?: 'CategoryCountableConnection', totalCount?: number | null } | null, backgroundImage?: { __typename?: 'Image', alt?: string | null, url?: string | null } | null } | null, images?: Array<{ __typename?: 'ProductImage', url: string, id: string, alt: string } | null> | null, productType: { __typename?: 'ProductType', id: string, name: string, slug: string, productAttributes?: Array<{ __typename?: 'Attribute', name?: string | null, id: string } | null> | null, variantAttributes?: Array<{ __typename?: 'Attribute', name?: string | null, id: string } | null> | null }, brand?: { __typename?: 'ProductBrand', name: string, id: string } | null, variants?: Array<{ __typename?: 'ProductVariant', id: string, sku: string, trackInventory: boolean } | null> | null } }> } | null };


export const AdminProductsDocument = gql`
    query adminProducts($first: Int, $filter: ProductFilterInput, $after: String, $sortBy: ProductOrder, $before: String, $last: Int) {
  products(
    first: $first
    filter: $filter
    after: $after
    sortBy: $sortBy
    before: $before
    last: $last
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    totalCount
    edges {
      node {
        ...ProductFragment
        attributes {
          values {
            name
          }
        }
        category {
          ...CategoryFragment
        }
        images {
          url
          id
          alt
        }
        mainImageUrl
        productType {
          ...ProductTypeFragment
        }
        brand {
          ...ProductBrandFragment
        }
        createdAt
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}
${ProductFragmentFragmentDoc}
${CategoryFragmentFragmentDoc}
${ProductTypeFragmentFragmentDoc}
${ProductBrandFragmentFragmentDoc}`;

/**
 * __useAdminProductsQuery__
 *
 * To run a query within a React component, call `useAdminProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProductsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useAdminProductsQuery(baseOptions?: Apollo.QueryHookOptions<AdminProductsQuery, AdminProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminProductsQuery, AdminProductsQueryVariables>(AdminProductsDocument, options);
      }
export function useAdminProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminProductsQuery, AdminProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminProductsQuery, AdminProductsQueryVariables>(AdminProductsDocument, options);
        }
export type AdminProductsQueryHookResult = ReturnType<typeof useAdminProductsQuery>;
export type AdminProductsLazyQueryHookResult = ReturnType<typeof useAdminProductsLazyQuery>;
export type AdminProductsQueryResult = Apollo.QueryResult<AdminProductsQuery, AdminProductsQueryVariables>;