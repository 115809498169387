/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorInvoiceCreateMutationVariables = Types.Exact<{
  input: Types.VendorInvoiceInput;
}>;


export type VendorInvoiceCreateMutation = { __typename?: 'Mutation', vendorInvoiceCreate?: { __typename?: 'VendorInvoiceCreate', invoiceErrors: Array<{ __typename?: 'InvoiceError', field?: string | null, message?: string | null, code: Types.InvoiceErrorCode }>, vendorInvoice?: { __typename?: 'VendorInvoice', isPaid: boolean, number: string, id: string, totalServiceCommission?: number | null, numberOfBranches?: number | null, netAmountAfterVat?: number | null } | null } | null };


export const VendorInvoiceCreateDocument = gql`
    mutation vendorInvoiceCreate($input: VendorInvoiceInput!) {
  vendorInvoiceCreate(input: $input) {
    invoiceErrors {
      field
      message
      code
    }
    vendorInvoice {
      isPaid
      number
      id
      totalServiceCommission
      number
      numberOfBranches
      netAmountAfterVat
    }
  }
}
    `;
export type VendorInvoiceCreateMutationFn = Apollo.MutationFunction<VendorInvoiceCreateMutation, VendorInvoiceCreateMutationVariables>;

/**
 * __useVendorInvoiceCreateMutation__
 *
 * To run a mutation, you first call `useVendorInvoiceCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorInvoiceCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorInvoiceCreateMutation, { data, loading, error }] = useVendorInvoiceCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVendorInvoiceCreateMutation(baseOptions?: Apollo.MutationHookOptions<VendorInvoiceCreateMutation, VendorInvoiceCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorInvoiceCreateMutation, VendorInvoiceCreateMutationVariables>(VendorInvoiceCreateDocument, options);
      }
export type VendorInvoiceCreateMutationHookResult = ReturnType<typeof useVendorInvoiceCreateMutation>;
export type VendorInvoiceCreateMutationResult = Apollo.MutationResult<VendorInvoiceCreateMutation>;
export type VendorInvoiceCreateMutationOptions = Apollo.BaseMutationOptions<VendorInvoiceCreateMutation, VendorInvoiceCreateMutationVariables>;