import { makeStyles } from "tss-react/mui";

export const useSmallAlertStyle = makeStyles()(theme => ({
  mainBox: {
    "&:first-child": {
      marginTop: 0,
    },
    marginTop: 10,
    width: 352,
    padding: "22px 10px",
    boxShadow: theme.mixins.shadows.sm,
    paddingTop: "13px",
    paddingInlineStart: "11px",
    display: "flex",
    borderRadius: "5px",
    backgroundColor: theme.palette.common.white,
  },
  description: {
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "2",
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontFamily: theme.mixins.fonts.fontFamily.regular,
    maxWidth: 230,
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
    "&:hover": {
      overflow: "visible",
      whiteSpace: "inherit",
      height: "auto",
      "-webkit-line-clamp": "inherit",
    },
  },
  poleBox: { width: 5, borderRadius: 20 },
  titleTextBox: {
    width: 230,
  },
  xIconBox: {
    position: "relative",
    marginInlineEnd: "10px",
  },

  titleText: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
  iconTextContainer: {
    marginInlineStart: "13px",
    marginTop: "15px",
  },
  iconContainer: {
    marginInlineStart: "20px",
    marginTop: "16px",
  },
  xIcon: {
    height: 28,
    "&:hover": {
      cursor: "pointer",
      background: theme.palette.gray.light,
      borderRadius: 5,
    },
  },
}));
