import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Dialog, ExcelIcon, Typography, UploadCloudIcon, useTheme } from "@toolkit/ui";
import { useRef, useState } from "react";
import { FileDownload } from "../FileDownload/FileDownload";
import { FileUpload } from "../FileUpload/FileUpload";
export const FileImportDialog = props => {
    const { isOpen, label, title, subTitle, onToggle, templateCode, onSuccessUpload } = props;
    const [file, setFile] = useState(null);
    const inputRef = useRef(null);
    const { t } = useTranslation("domains");
    const theme = useTheme();
    const handleFileChange = (event) => {
        var _a;
        const fileObj = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (!fileObj) {
            return;
        }
        setFile(fileObj);
    };
    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };
    const handleDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const handleDrop = (event) => {
        var _a;
        event.preventDefault();
        event.stopPropagation();
        const _file = (_a = event.dataTransfer.files) === null || _a === void 0 ? void 0 : _a[0];
        if (_file) {
            setFile(_file);
        }
    };
    const handleResetUploadFile = () => {
        setFile(null);
    };
    const handleSuccessUpload = () => {
        onSuccessUpload();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ sx: { margin: "10px", paddingInline: 5, borderRadius: "10px" }, startIcon: _jsx(ExcelIcon, {}), onClick: onToggle }, { children: label || t("Import.xlx") })), _jsxs(Dialog, Object.assign({ open: isOpen, onClose: onToggle }, { children: [_jsxs(Box, Object.assign({ sx: { textAlign: "center", width: 450, padding: 2 } }, { children: [_jsx(Typography, Object.assign({ fontSize: 24, fontFamily: theme.mixins.fonts.fontFamily.medium }, { children: title })), _jsx(Typography, Object.assign({ fontSize: 16, fontFamily: theme.mixins.fonts.fontFamily.regular, sx: { color: theme.palette.gray.main }, gutterBottom: true }, { children: subTitle })), _jsxs(Box, Object.assign({ sx: { border: "1px dashed", borderRadius: 2, padding: 2 }, onDragEnter: handleDragEnter, onDragLeave: handleDragLeave, onDragOver: handleDragOver, onDrop: handleDrop }, { children: [!file ? (_jsxs(_Fragment, { children: [_jsx(UploadCloudIcon, {}), _jsx(Typography, Object.assign({ fontSize: 16, fontFamily: theme.mixins.fonts.fontFamily.regular, sx: { color: theme.palette.gray.main } }, { children: t("Drag & Drop Your Files Here") }))] })) : (_jsxs(_Fragment, { children: [_jsx(ExcelIcon, { fill: theme.palette.success.main, sx: {
                                                    width: 60,
                                                    height: 60,
                                                } }), _jsx(Typography, Object.assign({ fontSize: 16, fontFamily: theme.mixins.fonts.fontFamily.regular, sx: { color: theme.palette.gray.main } }, { children: file === null || file === void 0 ? void 0 : file.name }))] })), _jsx(Typography, Object.assign({ fontSize: 16, fontFamily: theme.mixins.fonts.fontFamily.medium }, { children: t("OR") })), _jsx(Button, Object.assign({ sx: { margin: "10px" }, onClick: handleClick }, { children: t("Browse Files") })), _jsx(FileDownload, { label: t("Download Template"), templateCode: templateCode, isButton: false })] }))] })), _jsxs(Box, Object.assign({ sx: { boxShadow: theme.mixins.shadows.xs } }, { children: [_jsx("input", { style: { display: "none" }, ref: inputRef, type: "file", onChange: handleFileChange }), _jsx(FileUpload, { file: file, templateCode: templateCode, onToggle: onToggle, onResetUploadFile: handleResetUploadFile, onSuccessUpload: handleSuccessUpload }), _jsx(Button, Object.assign({ sx: { margin: "10px", border: "1px solid" }, variant: "outlined", onClick: onToggle }, { children: t("Close") }))] }))] }))] }));
};
