import { makeStyles } from "tss-react/mui";

export const useShowDialogStyle = makeStyles()(theme => ({
  div: {
    display: "flex",
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    justifyContent: "space-between",
    padding: 12,
    alignItems: "center",
  },
  header: {
    marginTop: "10px",
    marginBottom: "-10px",
  },
  dialog: {
    backgroundColor: "transparent",
  },
  icon: {
    background: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.common.white,
    },
  },
  button: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
  cancel: {
    backgroundColor: theme.palette.gray[300],
    padding: "8px",
    "&:hover": {
      backgroundColor: theme.palette.gray[300],
    },
  },
}));
